/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {withRouter} from 'react-router';
import {FormWithFooter, ErrorPage, dialogs, Footer} from 'react-template';
import {MsgDefaults} from 'js-utils';
import inputValidator from 'validations/AccountManagementValidationSchema';
import AppStore from 'stores/AppStore';
import  * as UserProfileActions from 'actions/UserProfileActions';
import {NotificationManager} from 'react-notifications';
import userDefaults from 'config/userDefaults';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import ExternalPageAlert from '../Login/ExternalPageAlert';
import 'styles/userProfile/resetPassword.less';
import FooterBuildProps from 'components/layout/FooterBuildProps';
import {Col, Row } from 'react-bootstrap';

@withRouter
export default class ResetPassword extends React.Component {
  constructor(props){
    super(props);

    this.userData = AppStore.getUserData();

    this.state = {
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
      error: false,
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.inputValidation = this.inputValidation.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  resetPassword(){
    const resetPasswordData = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.password,
    };

    UserProfileActions.resetPassword(resetPasswordData).then(response => {
      if(response == false){
        this.setState({
          error: true,
        });
      }else{
        UserProfileActions.logout().catch(() => {
          window.location.href = window.location.origin + window.location.pathname;
        }
        );
      }
    })
      .catch((error) => {
        if(error) {
          NotificationManager.error(MsgDefaults.getErrorResetMsg(userDefaults.PASSWORD), MsgDefaults.getResetTitle(userDefaults.PASSWORD), 10000);
        }
      });
  }

  onSubmit(){
    this.setState({error: false}, () => {
      dialogs.confirm({
        cb: () => {
          this.resetPassword();
        },
        message: 'Are you sure you want to reset your password ? You will be logged out once your password is reset successfully.',
      });
    });
  }

  inputValidation(key, value, state){
    if(key == 'confirmPassword'){
      return AccountManagementUtils.validateConfirmPassword(value, state?.password || this.state.password);
    }
    
    return inputValidator(key, value);
  }

  onBack() {  
    this.props.router.goBack();
  }
  
  render(){
    if(this.userData.ldap){
      return (
        <ErrorPage params={{error:403}}/>
      );
    }

    const variableSizes = {
      labelSize: 12,
      labelSizeSm: 12,
      inputSize: 12,
      inputSizeSm: 12,
      size: 12,
    }

    
    return (
      <div className="reset-password-wrapper">
        <div id="page-content-wrapper" role="main">
          <Row>
            <Col xs={12} md={8} lg={5} className='block-center'>
              <div className='resetPasswordFormWrapper'>
                <Row>
                  <Col xs={12}>
                    <h5 className="welcome-text break-word">
                    Reset Password
                    </h5>
                  </Col>
                  <Col xs={12}>
                    {this.state.error ? <ExternalPageAlert isError title='Error While Resetting Password' 
                      message='Current password does not match your old password'/> : null}
  
                    <FormWithFooter
                      inputs={AccountManagementUtils.getPasswordsFormInputs(this.state, true, this.setState.bind(this), null, true, variableSizes)}
                      validationFunction={this.inputValidation}
                      expandFullWidth
                      noFooterBorder
                      onChange={(result) => this.setState({...result})}
                      onCancel={this.onBack}
                      onSubmit={this.onSubmit}
                      submitButtonText='Reset Password'
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Footer textColor="white"
          footerProps={
            <FooterBuildProps footerProps={AppStore.getFooterProps()}/>
          }
        />
      </div>
    );
  }
}