/* Copyright 2019 Greyskies. All Rights Reserved. */
import React from 'react';
import LoginAPI from 'api/LoginAPI';
import { ImageConversionUtils} from 'js-utils';
import {Col, Image, Row } from 'react-bootstrap';
import { Footer, Loader } from 'react-template';
import FooterBuildProps from 'components/layout/FooterBuildProps';
import AppStore from 'stores/AppStore';
import 'styles/login/externalPageContainer.less';

export default class ExternalPageContainer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loginTitle: '',
    };
  }

  componentDidMount() {
    this.setState({loading: true}, ()=> {
      LoginAPI.getLoginTemplateData().then(value => {
        document.title = value.loginTitle;
        document.getElementsByTagName('html')[0].setAttribute('data-layout-theme', 
          value.layoutTheme);
  
        this.setState({
          loginLogo: ImageConversionUtils.pngToBase64URL(value.loginLogo),
          background: ImageConversionUtils.pngToBase64URL(value.background),
          loginTitle: value.loginTitle,
          layoutTheme: value.layoutTheme,
          loading: false,
        });
      });
    });
  }

  render() {
    if(this.state.loading){
      return <Loader />;
    }

    return (
      <div className={`externalPageContainer ev-auto-externalPageContainer ${this.props.pageClassName ? this.props.pageClassName : ''}`}>
        <Row className='externalPageRow'>
          <Col sm={12} md={8} className='loginImgCol'>
            <div className='loginImgWrapper ev-auto-loginImgWrapper'>
              <img src={this.state.background} className='loginImg ev-auto-loginImg animated fadeHalfTo1'/>
            </div>
          </Col>
          <Col sm={12} md={4} className='contentCol'>
            <div className='loginContentWrapper'>
              {!this.props.hideLogo ? 
                <div className='sysLogoWrapper ev-auto-sysLogoWrapper'>
                  <Image className="sysLogo ev-auto-sysLogo" src={this.state.loginLogo}/>
                </div> : null}
                <div className='welcomeTextWrapper ev-auto-welcomeTextWrapper'>
                {!this.props.title ? <p className='welcomeToText'>Welcome to </p> : null }
                  <h1 className="pageTitle ev-auto-pageTitle">
                    {this.props.title || this.state.loginTitle}
                  </h1>
                  {this.props.subtitle ?
                  <h6 className="loginSubTitle ev-auto-loginSubTitle ">
                      {this.props.subtitle}
                    </h6> : null}
                </div>
                <div className='loginFormWrapper'>
                  {this.props.children}
                </div>
            </div>
          </Col>
        </Row>
        <Footer textColor="white"
						footerProps={
							<FooterBuildProps footerProps={AppStore.getFooterProps()}/>
						}
				/>
      </div>
    );
  }
}
