/* Copyright 2019 Greyskies. All Rights Reserved. */
import * as Utils from './Utils';

export const getErrorDeleteMsg = (deletedType, deletedName) =>
  `Error while deleting ${deletedType} ${deletedName}`;
export const getDeletePromptMsg = (deletedType, deletedName) =>
  `Are you sure you want to delete the ${deletedType} ${deletedName}?`;
export const getDeleteConfirmMsg = (deletedType) =>
  `Are you sure you want to delete this ${deletedType}?`;
export const getSuccessDeleteMsg = (deletedType, deletedName) =>
  `${deletedType} ${deletedName} is deleted successfully`;
export const getUnlockPromptMsg = (unlockedType, unlockedName) =>
  `Are you sure you want to unlock the ${unlockedType} ${unlockedName}?`;
export const getSuccessUnlockMsg = (unlockedType, unlockedName) =>
  `${unlockedType} ${unlockedName} is unlocked successfully`;
export const getUnlockTitle = (unlockType) =>
  `Unlock ${unlockType ?  unlockType : ''}`;
export const getProcessingMessage = (name) =>
  `Processing ${name}`;
export const getDeleteTitle = (deletedType) =>
  `Delete ${deletedType ?  deletedType : ''}`;
export const getAddNewTitle = (newType) =>
  `New ${newType}`;
export const getNewTitle = (newType) =>
  `New ${newType}`;
export const getInviteTitle = (invitedType) =>
  `Invite ${invitedType}s`;
export const getResetTitle = (newType) =>
  `Reset ${newType}`;
export const getAddTitle = (newType) =>
  `Add ${newType ?  newType : ''}`;
export const getEditTitle = (editType) =>
  `Edit ${editType}`;
export const getShowHideTitle = (editType, shown) =>
  `${shown ? 'Hide' : 'Show'} ${editType}`;
export const getSuccessSaveMsg = (savedType, isCreate, plural) =>
  `${savedType} ${plural ? 'are' : 'is'}  ${isCreate ? 'added' : 'edited'} successfully`;
export const getSuccessInviteMsg = (savedType, plural) =>
  `${savedType}${plural ? 's are' : ' is'} invited successfully`;
export const getSuccessRenviteMsg = (savedType) =>
  `${savedType} reinvited successfully`;
export const getErrorSaveMsg = (savedType, isCreate) =>
  `Error while ${isCreate ? 'adding' : 'editing'} ${savedType}`;
export const getErrorInviteMsg = (invitedType) => 
  `Error while inviting ${invitedType}s`;
export const getGenerateTitle = (newType) =>
  `Generate New ${newType}`;
export const getErrorLoadMsg = (loadedType) =>
  `Error while loading ${loadedType}`;
export const getKafkaErrorSaveMsg = (savedType, isCreate) =>
  `${savedType} is ${isCreate ? 'added' : 'edited'} successfully but failed to publish. Please save it again later.`;
export const getSaveTitle = (savedType, isCreate) => {
  if(isCreate){
    return getAddNewTitle(savedType);
  }

  return getEditTitle(savedType);
};
export const getPanelTitle = (savedType, isCreate) => {
  if(isCreate){
    return getNewTitle(savedType);
  }

  return getEditTitle(savedType);
};
export const getAddEditTitle = (savedType, isCreate) => {
  if(isCreate){
    return getAddTitle(savedType);
  }
  
  return getEditTitle(savedType);
};
export const getCreateButtonText = (newType) =>
  `Create ${newType}`;
export const getUpdateButtonText = (editType) =>
  `Update ${editType}`;
export const getAppendButtonText = (newType) =>
  `Append ${newType}`;
export const getSaveButtonText = (savedType, isCreate) => {
  if(isCreate){
    return getCreateButtonText(savedType);
  }

  return getUpdateButtonText(savedType);
};

export const getSaveButton = () => {
  return 'Save';
};

export const getDeleteWarningMsg = (toBeDeletedItem) =>
  `Deleting this folder will move all its ${toBeDeletedItem} to the default folder`;

export function getSelectMsg(selectType) {
  return  `Please Select ${selectType}`;
}

export const getEnterMsg = (selectType) =>
  `Enter ${selectType}`;

export const getCloneTitle = (cloneType) =>
  `Clone ${cloneType}`;

export const getSuccessCloneMsg = (cloneType) =>
  `${cloneType} is cloned successfully`;

export const getErrorCloneMsg = (cloneType) =>
  `Error while cloning ${cloneType}`;

export const getCloneAllTitle = (cloneType) =>
  `Clone Selected ${cloneType}s`;

export const getSuccessCloneAllMsg = (cloneType) =>
  `${cloneType}s are cloned successfully`;

export const getCopyTitle = (type) =>
  `Copy ${type ? type : ''}`;

export const getCopiedMsg = (type) => 
  `${type} copied.`;

export const getFireEntityTitle  = (entityName) =>
  `Fire ${entityName}`;

export const getRunEntityTitle  = (entityName) => {
  return Utils.isEmpty(entityName) ?  'Run' : `Run ${entityName}`;
};

export const getFireEntitySuccessMsg = (type, entityName) =>
  `${type} ${entityName} is fired successfully`;

export const getFireEntityErrorMsg = (type, entityName) =>
  `Error while firing ${type} ${entityName}`;

export const getRunEntitySuccessMsg = (type, entityName) => {
  if(Utils.isEmpty(entityName) && Utils.isEmpty(type)){
    return 'Run has been successfull';
  } 
  const entityNameUsed = Utils.isEmpty(entityName) ? '' : entityName;
  const typeUsed = Utils.isEmpty(type) ? '' : type;

  return  `${typeUsed} ${entityNameUsed} is run successfully`;
  ;
};

export const getRunEntityErrorMsg = (type, entityName) => {
  if(Utils.isEmpty(entityName) && Utils.isEmpty(type)){
    return 'Error while running';
  } 
  const entityNameUsed = Utils.isEmpty(entityName) ? '' : entityName;
  const typeUsed = Utils.isEmpty(type) ? '' : type;

  return  `Error while running ${typeUsed} ${entityNameUsed}`;
  ;
};

export const getSuccessTestMessage = ( entityName) =>
  `${entityName} successfully tested`;

export const getErrorTestMessage = (entityName) =>
  `${entityName} testing failed`;

export const getManagePageTitle = (entityName) =>
  `Manage ${entityName}`;

export const getInsertMsg = (InsertType) =>
  `Please Insert ${InsertType}`;

export const getOrderUpTitle = (newType) =>
  `Order Up ${newType}`;

export const getOrderDownTitle = (newType) =>
  `Order Down ${newType}`;

export const startExportingFile = (fileType) =>
  `Exporting of ${fileType} started.`;

export const finishExportingFile = (fileType) =>
  `Exporting of ${fileType} has finished successfully`;

export const finishImportingEntities = (entityType) =>
  `Importing of ${entityType} has finished successfully`;

export const errorInExportingFile = (fileType) =>
  `Exporting of ${fileType} failed.`;

export const SOMTHING_WRONG_MESSAGE = 'Something went wrong';

export const getConfirmMessage = (message) =>
  `Are you sure you want to ${message}?`;

export const getProcessFailedMessage = () =>
  'Failed to process file';

export const getImportingFailedParseFileMessage = (entity) =>
  `Error while importing new ${entity}`;

export const getImportingNewEntitesTitle = (entityType) =>
  `Import New ${entityType}`;

export const getExportAllSelectedTitle = (entityType) =>
  `Export All Selected ${entityType}`;

export const getSuccessFailCount = (success, fail, action) => 
  `${success} Successfully ${action},${fail} Failed`;

export const getExportImportStatusTitle = () =>
  'Export import status';

export const getNotValidMessage = (entityType) =>
  `This ${entityType} is not valid`;

export const getAllowedCharsMessage = (label, specialChars) =>
  `${label} can include letters, numbers and special characters ${specialChars}`;

export const getExportTitle = (exportType) =>
  `Export ${exportType}`;

export const getDownloadMsg = (type) =>
  `Download ${type}`;

export const getAssignMsg = (type) =>
  `Assign ${type}`;
  
export const getViewMsg = (type) =>
  `View ${type}`;  
  
export const getInfoTitle = (infoType) =>
  `${infoType} Info`;

export const TEMPLATE_PREFIX = 'Template ';

export const getTemplateTitle = (title, isTemplate, isCreate) => { 
  title = isTemplate ? TEMPLATE_PREFIX + title : title;
  
  return isCreate ? getNewTitle(title) : getEditTitle(title);
};

export const getDeletingInProgressMsg  = (deletedType, deletedName) =>
  `Deleting ${deletedType} ${deletedName} in progress, please refresh within a few minutes`;

export const getAttachedEntitiesConfirmTitle = (type) =>
  `This ${type} is attached to the following: `;

export const getStatusConfirmationTitle = (newStatus) =>
  `${newStatus} Confirmation`;
  
export const getDisableOnEditMessage = (type, status) =>
  `Editing this ${status.toLowerCase()} ${type.toLowerCase()} will directly disable it.`;
  
export const getActionConfirmationMessage = (newStatus, isBulk, type, warning) =>
  `${warning ? warning : ''}Are you sure you want to ${newStatus.toLowerCase()} ${isBulk ? 'selected' : 'this'} ${type.toLowerCase()}(s) ?`;
  
export const getSuccessStatusUpdateMsg = (newStatus, type) =>
  `Selected ${type} are ${newStatus}d successfully`;

export const getErrorStatusUpdateMsg = (newStatus, type) =>
  `Error while ${newStatus} selected ${type}`;

export const getSuccessUpdatedMsg = (type) =>
  `${type} is updated successfully`;

export const getSuccessTransferAndDeleteMsg = (transferredType, deletedType) =>
  `${transferredType} is transferred and ${deletedType} is deleted successfully`;

export const getErrorTransferAndDeleteMsg = (deletedType) =>
  `Some entities are owned by this ${deletedType}, cannot be deleted`;

export const getTransferAndDeleteTitle = (transferredType, deletedType) =>
  `Transfer ${transferredType} & Delete ${deletedType}`;
  
export const getSuccessRefreshMsg = (type, entity) =>
  `Selected ${type} ${entity} are refreshed successfully`;

export const getErrorRefreshMsg = (type, entity) =>
  `Error while refreshing selected ${type} ${entity}`;

export const getSuccessTitle = () =>
  'Success';

export const getErrorTitle = () =>
  'Error';
  
export const getSuccessUpdateMsg = (type) =>
  `Your ${type} has been updated successfully`;

export const getErrorUpdateMsg = (type) =>
  `Error while updating ${type}`;

export const getSuccessResetMsg = (resetType) =>
  `Your ${resetType} has been reset successfully`;

export const getErrorResetMsg = (resetType) =>
  `Error while resetting ${resetType}`;

export const addSIfPlural = (title, isMultiple) => 
  isMultiple ? `${title}s` : title;
  
export const getResetConfirmationMsg = (message) => {
  return `Are you sure you want to reset the ${message}?<br/>If you click yes the ${message} will be reset, this action can't be undone`;
};

export const delayActionMessage = 'This action will take time, so please keep refreshing to get the new status.';
