/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils, AggregationUtils, FieldTypes,
  CompareUtils, ResolutionUtils, Utils as JSUtils} from 'js-utils';
import * as DataSelectionCommonUtils from '../DataSelectionCommonUtils';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import * as FFAGDefaults from '../ffagDefaults';
import * as FilterUtils from 'utils/filterUtils';
import * as CommonValidationSchema from 'utils/CommonValidationSchema';
import {fromJS} from 'immutable';

const collectionAggregationSchema = CommonValidationSchema.getNumericInputValidationSchema({
        min: 1,
        label: FFAGDefaults.LABELS.INTERVAL,
        required: true,
    });
const fieldValidation = Joi.object()
    .keys({
        aggregationType: Joi.string()
            .required()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.AGGREGATION_TYPE)),
        selectedField: Joi.object().required()
          .error((errors) => {
            const error = errors[0];

            if(error.code === 'object.field_whole_object_not_allowed'){
              return ValidationUtils.ERR_MSGS.notAllowed('This field');
            } else if(error.code === 'object.field_object_subfield_not_allowed'){
              return ValidationUtils.ERR_MSGS.notAllowed('This field');
            } else if (error.code === 'object.field_object_key_required') {
              return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.OBJECT_KEY);
            }else if (error.code === 'object.field_multiValued_not_allowed') {
              return ValidationUtils.ERR_MSGS.notAllowed('This field');
            }else if (error.code === 'object.non_numeric_field_multiValued_not_allowed') {
              return ValidationUtils.ERR_MSGS.notAllowed('This field');
            }

            return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SELECTED_FIELD);
          })
          .when('wholeObjectAndNotAllowed', [{
            is: true,
            then: Joi.object()
            .custom((fields, helpers) => {
              return helpers.error('object.field_whole_object_not_allowed');
            }, 'Check that field is not the whole object'),
          }])
          .when('objectSubFieldsAndNotAllowed', [{
            is: true,
            then: Joi.object()
            .custom((fields, helpers) => {
              return helpers.error('object.field_object_subfield_not_allowed');
            }, 'Check that field is not object subfield'),
          }])
          .when('objectKeyRequired', [{
            is: true,
            then: Joi.object()
            .custom((fields, helpers) => {
              return helpers.error('object.field_object_key_required');
            }, 'Check that field object key selected'),
          }]).when('multiValueFieldNotAllowed', [{
            is: true,
            then: Joi.object()
            .custom((fields, helpers) => {
              return helpers.error('object.field_multiValued_not_allowed');
            }, 'Check that field is multiValued'),
          }]).when('nonNumericMultiValueFieldNotAllowed', [{
            is: true,
            then: Joi.object()
            .custom((fields, helpers) => {
              return helpers.error('object.non_numeric_field_multiValued_not_allowed');
            }, 'Check that nonNumeric field is multiValued'),
          }]),
        weightedAvgField: Joi.object()
          .error((errors) => {
            const error = errors[0];

            if(error.code === 'object.weighted_field_object_subfield_not_allowed'){
              return ValidationUtils.ERR_MSGS.notAllowed('This field');
            } else if (error.code === 'object.weighted_field_object_key_required') {
              return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.WEIGHTED_AVG_OBJECT_KEY);
            }

            return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.WEIGHTED_AVG_FIELD);
          })
          .when('aggregationType', [{
              is: AggregationUtils.WEIGHTED_AVG,
              then: Joi.object().required(),
              otherwise: Joi.object().optional().allow(null),
          }])
          .when('weightedObjectSubFieldsAndNotAllowed', [{
            is: true,
            then: Joi.object()
            .custom((weightedFields, helpers) => {
              return helpers.error('object.weighted_field_object_subfield_not_allowed');
            }, 'Check that wighted average field is not object subfield'),
          }])
          .when('weightedObjectKeyRequired', [{
            is: true,
            then: Joi.object()
            .custom((weightedFields, helpers) => {
              return helpers.error('object.weighted_field_object_key_required');
            }, 'Check that wighted average field object key selected'),
          }]),
    }).unknown();

const groupbyValidation = Joi.object()
    .keys({
        selectedField: Joi.object()
            .required()
            .error((errors) => {
              const error = errors[0];

              if(error.code === 'object.groupby_whole_object_not_allowed'){
                return ValidationUtils.ERR_MSGS.notAllowed('This field');
              } else if(error.code === 'object.groupby_object_subfields_not_allowed'){
                return ValidationUtils.ERR_MSGS.notAllowed('This field');
              } else if (error.code === 'object.groupby_object_key_required'){
                return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.OBJECT_KEY);
              }else if (error.code === 'object.field_multiValued_not_allowed') {
                return ValidationUtils.ERR_MSGS.notAllowed('This field');
              }

              return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SELECTED_FIELD);
            })
            .when('wholeObjectAndNotAllowed', [{
              is: true,
              then: Joi.object()
              .custom((groupByFields, helpers) => {
                return helpers.error('object.groupby_whole_object_not_allowed');
              }, 'Check that groupby is not the whole object'),
            }])
            .when('objectSubFieldsAndNotAllowed', [{
              is: true,
              then: Joi.object()
              .custom((groupByFields, helpers) => {
                return helpers.error('object.groupby_object_subfields_not_allowed');
              }, 'Check that groupby is not object subfield'),
            }])
            .when('objectKeyRequired', [{
              is: true,
              then: Joi.object()
              .custom((groupByFields, helpers) => {
                return helpers.error('object.groupby_object_key_required');
              }, 'Check that groupby object key selected'),
            }])
            .when('multiValueFieldNotAllowed', [{
              is: true,
              then: Joi.object()
              .custom((fields, helpers) => {
                return helpers.error('object.field_multiValued_not_allowed');
              }, 'Check that field is multiValued'),
            }]),
        resolutionUnit: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.UNIT))
            .when('selectedField.fieldName',
            [{
                is: FieldTypes.DATE_FIELD_NAME,
                then: Joi.string().required(),
                otherwise: Joi.string().optional().allow(null, ''),
            }]),
        resolution: Joi.number()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.INTERVAL))
            .when('selectedField.fieldName',
            [{
                is: FieldTypes.DATE_FIELD_NAME,
                then: Joi.when(
                    'resolutionUnit', {
                      is: ResolutionUtils.AUTO_RESOLUTION,
                      then: Joi.number().optional().allow(null, ''),
                      otherwise: collectionAggregationSchema,
                    }
                  ),
                otherwise: Joi.number().optional().allow(null, ''),
            }]),
    }).unknown();

  const filterVariableValidation = Joi.object()
    .when('filterValueType', {
        is: FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
        then: Joi.object()
        .label(FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.name)
        .error(ValidationUtils.getErrMsgString)
        .keys({
          name: Joi.string()
          .label(FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.name)
          .trim()
          .regex(/^[A-Za-z0-9 ]+$/)
          .message(ValidationUtils.ERR_MSGS.getSpecialCharsErrorMsg(FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.name))
          .min(3)
          .max(ValidationUtils.VARCHAR_LENGTH)
          .required(),
      }).unknown(),
        otherwise:Joi.object().optional().allow('', null),
    });
const routingValueValidation = Joi.object()
    .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.VALUE))
    .keys({
        routingValue: Joi.string().trim().required(),
    }).unknown();

export const routingSelectionValidation = Joi.object()
.keys({
    filterValueType: Joi.string().optional(),
    selection: Joi.object()
    .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SELECTION))
    .when('filterValueType', {
      is:  FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
      then: Joi.object().optional().allow(null),
      otherwise: Joi.object().required()
    }),
    variable: filterVariableValidation,
}).unknown();

const filtersValidation = Joi.object()
    .keys({
        selectedField: Joi.object()
            .error((errors) => {
              const error = errors[0];

              if(error.code === 'object.filter_object_key_required'){
                return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.OBJECT_KEY);
              }

              return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SELECTED_FIELD);
            })
            .when('isPostAggregationFilter', [{
              is: false,
              then: Joi.object().required(),
              otherwise: Joi.object().optional().allow(null),
            }])
            .when('isObjectFilter', [{
              is: true,
              then: Joi.object()
              .custom((filters, helpers) => {
                return helpers.error('object.filter_object_key_required');
              }, 'Check that filter object key selected'),
            }]),
        field: Joi.object()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.FIELD))
            .when('isPostAggregationFilter', [{
              is: true,
              then: Joi.object().required(),
              otherwise: Joi.object().optional().allow(null),
            }]),
        operation: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.OPERATION))
            .when('selectedField.fieldName', { 
              is: FieldTypes.DATE_FIELD_NAME,
              then: Joi.string().optional().allow(null, ''),
              otherwise: Joi.string().required(),
            })
            .concat(Joi.string()
                .when('selectedField.ref',
                [{
                  is: false,
                  then: Joi.string().required(),
                }, {
                  is: true,
                  then: Joi.string().optional().allow(null, ''),
                }])
            ),
        filterValueType: Joi.string().optional(),
        compareValue: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.VALUE))
        .when('selectedField.fieldName', { 
          is: FieldTypes.DATE_FIELD_NAME,
          then: Joi.string().optional().allow(''),
          otherwise: Joi.string().required(),
        })
        .concat(Joi.string()
            .when('selectedField.ref',
            [{
              is: false,
              then: Joi.string().required(),
            }, {
              is: true,
              then: Joi.string().optional().allow(''),
            }])
        .concat(Joi.string()
            .when('filterValueType',
            [{
              is: FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value,
              then: Joi.string().required(),
            }, {
              is: FilterUtils.FILTER_TYPE_OPTIONS.PLACEHOLDER.value,
              then: Joi.string().optional().allow(''),
            }, {
              is: FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
              then: Joi.string().optional().allow(''),
            }])
        .concat(Joi.string()
            .when('operation', {
              is: Joi.valid(CompareUtils.IN, CompareUtils.NOT_IN),
              then: Joi.string().optional().allow(''),
              otherwise: Joi.string().required(),
            })
            )),
        ),
        inOperationFilter: Joi.array()
            .label(`${FFAGDefaults.LABELS.LIST_SELECTION} values`)
            .when('operation', {
              is: Joi.valid(CompareUtils.IN, CompareUtils.NOT_IN),
              then: Joi.when('filterValueType', {
                is:  FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value,
                then: Joi.array().required()
              .min(1)
              .max(Joi.ref('esQueryMaxTerms'))
              .error((errors) => {
                if(errors[0].code === 'any.required'){
                  return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.LIST_SELECTION);             
                }

                return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.LIST_SELECTION)(errors);
              }),
              }),
            }),
        timeRanges: Joi.array()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.DATE_SELECTION))
            .when('selectedField.fieldName', {
              is: FieldTypes.DATE_FIELD_NAME,
              then: Joi.array().min(1).required(),
            }),
        selection: Joi.object()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SELECTION))
            .when('selectedField.ref',
            [{
                is: true,
                then: Joi.object()
                .when('filterValueType', {
                  is: FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
                  then: Joi.object().optional().allow( null), 
                  otherwise: Joi.object().required(),
              }),
            },{
                is: false,
                then: Joi.object().optional().allow(null),
            }]),
        templatesPlaceholder: Joi.object()
            .error(ValidationUtils.ERR_MSGS.required(FilterUtils.PLACEHOLDER))
            .when('selectedField.ref',
            [{
                is: false,
                then: Joi.object()
                .when('filterValueType', {
                  is: FilterUtils.FILTER_TYPE_OPTIONS.PLACEHOLDER.value,
                  then: Joi.object().required() , 
                  otherwise: Joi.object().optional().allow( null),
              }),
            },{
                is: true,
                then: Joi.object().optional().allow(null),
            }]),
        variable: filterVariableValidation,
        lastFilter:  Joi.bool().allow(null),
        relationToNext: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.RELATION_TO_NEXT_FILTER))
            .when('lastFilter',
            [{
                is: true,
                then: Joi.string().optional().allow(null),
                otherwise: Joi.string().required(),
            }]),
        isPostAggregationFilter: Joi.bool(),
    }).unknown();

const mandatoryGroupByFieldsCustomValidator = (groupByFields, helpers) => {
  const mandatoryFields = helpers.state.ancestors[0].mandatoryGroupByFields;
  const fieldNames = groupByFields.map(groupByField => {
    return groupByField.selectedField.fieldName;
  });
  const missingMandatoryFields = mandatoryFields.filter(mandatoryField=>{
    return !fieldNames.includes(mandatoryField);
  });

  if(_.isEmpty(missingMandatoryFields)){
    return groupByFields;
  }

  return helpers.error('object.mandatoryFieldsMissing');
};

const groupFiltersByVariables = (filters) => 
  filters.reduce((groupedFilters, obj) => {
    const isVariableFilter = FilterUtils.isVariableFilter(obj.filterValueType);

    if(isVariableFilter){
      const variableName = obj.variable.name;

      if(!JSUtils.isEmpty(variableName)){
        groupedFilters[variableName] = (groupedFilters[variableName] || []).concat(obj);
      }
    }

    return groupedFilters;
  }, {});

const checkRefFiltersVariableValidity = (variableFilters) => {
  const refTypeId = variableFilters[0].selectedField.refType.id;

  const sameLevelFilters = variableFilters.filter((filter) => filter.selectedField.refType.id != refTypeId).length == 0;

  return sameLevelFilters;
};

const checkAtLeastOneVariableFilter = (groupedFilters) => {
  if(!JSUtils.isEmptyObject(groupedFilters)){
    return true;
  }

  return false;
};

const checkInFiltersVariableValidity = (variableFilters) => {
  const inFilterCount = variableFilters.reduce((count, filter) => {
    return CompareUtils.LIST_OPERATORS.includes(filter.operation) ? count + 1 : count;
  }, 0);

  if(inFilterCount > 0 && inFilterCount != variableFilters.length){
    return false;
  }

  return true;
};

const checkRefAndNonRefFiltersVariablesValidity = (groupedFilters) => {
  for(const variableFilters of Object.values(groupedFilters)){
    const refFilterCount = variableFilters.reduce((count, filter) => {
      return filter.selectedField.ref ? count + 1 : count;
    }, 0);

    if(refFilterCount > 0){
      if(refFilterCount != variableFilters.length){
        return 'object.invalidRefAndNonRefFilterVariable';
      }
      if(!checkRefFiltersVariableValidity(variableFilters)){
        return 'object.differentLevelsRefFilters';
      }
    } else {
      if(!checkInFiltersVariableValidity(variableFilters)){
        return 'object.invalidInFilterVariable';
      }
    }
  }

  return;
};

const checkFiltersWithVariablesValidity = (filters, helpers) => {
  const allFilters = fromJS(filters).toJS();
  const routingFilter = helpers.state.ancestors[0].routingFilter;

  if(!JSUtils.isEmpty(routingFilter)){
    allFilters.push(routingFilter);
  }

  const groupedFilters = groupFiltersByVariables(allFilters);

  if(!checkAtLeastOneVariableFilter(groupedFilters)){
    return helpers.error('object.bulkMlMustContainAtLeastOneVariableFilter');
  }

  const validationResult = checkRefAndNonRefFiltersVariablesValidity(groupedFilters);

  if(!JSUtils.isEmpty(validationResult)){
    return helpers.error(validationResult);
  }

  return filters;
};

const mandatoryGroupByFieldTypeCustomValidator = (groupByFields, helpers) => {
  const mandatoryFieldType = helpers.state.ancestors[0].mandatoryFieldType;

  const counts = {};
  const countsMultiValue = {};

  groupByFields.forEach((groupByField) => {
    const fieldType = groupByField.selectedField.attributeType && groupByField.selectedField.attributeType.dataType;

    counts[fieldType] = counts[fieldType] ? (counts[fieldType] + 1) : 1;

    if(groupByField.selectedField.multiValued){
      countsMultiValue[fieldType] = countsMultiValue[fieldType] ? (countsMultiValue[fieldType] + 1) : 1;
    }
  });

  if(!counts[mandatoryFieldType.type] || counts[mandatoryFieldType.type] < mandatoryFieldType.min){
    return helpers.error('object.min.mandatoryFieldType');
  }else if(counts[mandatoryFieldType.type] > mandatoryFieldType.max){
    return helpers.error('object.max.mandatoryFieldType');
  }else if(countsMultiValue[mandatoryFieldType.type] > 0 && !mandatoryFieldType.canHaveMultiValueField){
    return helpers.error('object.field_multiValued_not_allowed');
  }

  return groupByFields;
};

const mandatoryGroupByWithAtLeastOneNonSpecificField = (groupByFields, helpers) => {
  const optional = helpers.state.ancestors[0].notOnlySpecificField;

  let hasError = false;
  if(groupByFields.length < optional.min) {
    hasError = true;
  } else if(groupByFields.length === optional.min) {
    groupByFields.forEach((groupByField) => {
      if(groupByField.selectedField.fieldName === optional.type) {
        hasError = true;
      }
    });
  }
  
  return hasError ? helpers.error('object.atLeastOneNonSpecificField') : groupByFields;
};

const uniqueFieldType = (fields, helpers) => {
  const firstFieldType = AggregationUtils.getAggregationType(fields[0].aggregationType);
  let isUnique = true;
  
  fields.forEach(field => {
    if(AggregationUtils.getAggregationType(field.aggregationType) !== firstFieldType){
      isUnique = isUnique && false;
    }
  });

  if(isUnique){
    return fields;
  }

  return helpers.error('object.nonUniqueFieldType');
};

const checkAggregationTypeMaxExistence = (fields, helpers) => {
  const maxFieldTypes = helpers.state.ancestors[0].maxFieldTypes;
  const existingFiledTypes = {};
  let result = null;
  const definedMaxFieldTypes = Object.keys(maxFieldTypes);

  if(maxFieldTypes && definedMaxFieldTypes.length > 0){
    fields.forEach(field => {
      const aggregationType 
        = AggregationUtils.getAggregationType(field.aggregationType);
  
      existingFiledTypes[aggregationType] 
        = (existingFiledTypes[aggregationType] || 0) + 1;
    });

    definedMaxFieldTypes.forEach(type => {
      if(existingFiledTypes[type] > maxFieldTypes[type]){
        if(maxFieldTypes[type] == 0){
          result = helpers.error('object.AggregationTypeNotAllowed',[{type}]);
        }else{
          result = helpers.error('object.maxFieldTypeExceeded', [{type, max: maxFieldTypes[type]}]);
        }
      }
    });
  }

  return result ? result :  fields;
};

const checkAggregationTypeMinExistence = (fields, helpers) => {
  if(fields.length === 1 && fields[0].aggregationType == null){
    return fields
  }
  const minFieldTypes = helpers.state.ancestors[0].minFieldTypes;
  const existingFiledTypes = {};
  let result = null;
  const definedMinFieldTypes = Object.keys(minFieldTypes);

  if(minFieldTypes && definedMinFieldTypes.length > 0){
    fields.forEach(field => {
      const aggregationType 
        = AggregationUtils.getAggregationType(field.aggregationType);
  
      existingFiledTypes[aggregationType] 
        = (existingFiledTypes[aggregationType] || 0) + 1;
    });

    definedMinFieldTypes.forEach(type => {
      const existingCount = existingFiledTypes[type] || 0;

      if(existingCount < minFieldTypes[type]){
        result = helpers.error('object.minFieldTypeExceeded',[{type}]);
      }
    });
  }

  return result ? result :  fields;
};

const checkOnlyCount = (fields, helpers) => {
  const aggregations = (fields).map(field =>  field.aggregationType);
  const hasCount = aggregations.includes(AggregationUtils.COUNT);

  if(hasCount && aggregations.length > 1){
    return helpers.error('object.mixingCountRefused'); 
  }

  return fields;
};

const checkCountExist = (fields, helpers) => {
  const aggregations = (fields).map(field =>  field.aggregationType);
  const hasCount = aggregations.includes(AggregationUtils.COUNT);

  if(hasCount){
    return helpers.error('object.countNotAllowed'); 
  }

  return fields;
};

const checkGroupByWithNonNumericAggregation = (groupByFields, helpers) => {
  const hasNonNumericInFields = helpers.state.ancestors[0].hasNonNumericInFields;
  if(hasNonNumericInFields && groupByFields.length > 0){
    return helpers.error('object.groupByNotAllowed');
  }
  return groupByFields;
};

const checkUniqueAttributeTypeAggregations = (fields, helpers) => {
  const fieldsTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor(fields);
  if(fieldsTypeDescriptor.isMixedAttributeDataType){
    return helpers.error('object.nonUniqueAttributeType');
  }
  return fields;
};

const checkDuplicateFields = (a, b) => {
  let duplicate =  a.aggregationType == b.aggregationType
  && a.selectedField && b.selectedField
  && a.selectedField.elasticsearchName == b.selectedField.elasticsearchName
  && a.percentileValue == b.percentileValue;

  if(a.weightedAvgField && b.weightedAvgField){
    duplicate = duplicate && a.weightedAvgField.elasticsearchName == b.weightedAvgField.elasticsearchName;
  }
  return duplicate;
};
const checkKpiMultipleAggregate = (fields, helpers) => {
  const selectedFields = (fields).map(field => field.selectedField.elasticsearchName);
  const isDuplicate = selectedFields.some((item, index) => {
    return selectedFields.indexOf(item) !== index;
  });
  if(isDuplicate){
    return helpers.error('object.multipleAggregateKPI');
  }
  return fields;
};

const checkIsMultipleOfRes = (value, helpers) => {
  const selectedDate = DataSelectionCommonUtils.getDateInGrouping(value);
  const selectedFGAResolution = helpers.state.ancestors[0].selectedFGAResolution;

  if(selectedFGAResolution.resolutionUnit == ResolutionUtils.AUTO_RESOLUTION){
    if(selectedDate.resolutionUnit !== ResolutionUtils.AUTO_RESOLUTION){
      return helpers.error('number.mustSelectAuto')
    }else{
      return value;
    }
  }

  const FGAResolutionEpoch = ResolutionUtils.getEpoch(selectedFGAResolution.resolution,
    selectedFGAResolution.resolutionUnit);
  const selectedDateResolution = ResolutionUtils.getEpoch(selectedDate.resolution,
    selectedDate.resolutionUnit);
  const isSelectedDateResolutionMultipleOfFGAResolution = JSUtils.isIntegerValue(selectedDateResolution/FGAResolutionEpoch);
 
  return isSelectedDateResolutionMultipleOfFGAResolution ? value : helpers.error('number.isMultiple');
};



const checkGroupByBoundsByFieldsType = (groupByFields, helpers) => {
  const allBounds = helpers.state.ancestors[0].checkBoundsByFieldsTypes;
  const fields = helpers.state.ancestors[0].fields || [];
  const existingFiledTypes = {};

  fields.forEach(field => {
    const aggregationType 
      = AggregationUtils.getAggregationType(field.aggregationType);

    existingFiledTypes[aggregationType] 
      = (existingFiledTypes[aggregationType] || 0) + 1;
  });

  let hasMaxError = false;
  let hasMinError = false;

  Object.keys(allBounds).some(boundKey => { 
    if(allBounds[boundKey].max && existingFiledTypes[boundKey]
      && groupByFields.length > allBounds[boundKey].max){
      hasMaxError = allBounds[boundKey].max; 
    }else if(allBounds[boundKey].min 
        && ( !existingFiledTypes[boundKey] || groupByFields.length > allBounds[boundKey].min)){
      hasMinError = allBounds[boundKey].min ;
    }
  });
  
  if(hasMaxError){
    return helpers.error('array.max', {limit: hasMaxError }); 
  }else if(hasMinError){
    return helpers.error('array.min', {limit: hasMinError }); 
  }

  return groupByFields;
};

const dataSelectionFgaWithResolution =  Joi.object().keys({
  selectedFGAResolution : Joi.object(),
  name: Joi.string()
  .label('FGA')
  .required()
  .error(err => err),
  groupByFields: Joi.array()
  .label(FFAGDefaults.LABELS.GROUPING)
  .custom(checkIsMultipleOfRes, 'Checking that Selected date must a be multiple of Resolution.')
})
.unknown()
.label('FGA')
.error((errors) =>{
    if (errors[0].code ==='string.empty'){
      return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.FFAG);
    }
  return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.FFAG)(errors);
});
    

const SCHEMA = {
    name: Joi.string()
        .trim()
        .min(3)
        .max(50)
        .required()
        .label(FFAGDefaults.LABELS.NAME)
        .error(ValidationUtils.getErrMsgString),
    sourceType: Joi.object().required()
                .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.SOURCE_TYPE)),
    distinctSrcAndDestMappingFields: Joi.object({
      sourceMappingField: Joi.object(),
      destinationMappingField: Joi.object().disallow(Joi.ref('sourceMappingField'))
        .error(ValidationUtils.ERR_MSGS.distinct(`${FFAGDefaults.LABELS.MAPPING_FIELD}s`)),
    }),
    routingValue: routingValueValidation,
    routingFilter: routingSelectionValidation,
    field: fieldValidation,
    fields: Joi.object({
      maxFields: Joi.number().optional().allow(null),
      minFields: Joi.number().required(),
      checkUniqueFieldType: Joi.boolean(),
      validate: Joi.bool(),
      fields: Joi.array()
        .unique((a, b) => checkDuplicateFields(a, b))
        .label(FFAGDefaults.LABELS.FIELD)
        .error((errors) => {
          const error = errors[0];
          if(error.code === 'object.countNotAllowed'){
            return new Error('Count is not allowed');
          }else if(error.code === 'object.nonUniqueFieldType'){
            return new Error('Mixing types is not allowed');
          }else if(error.code === 'object.mixingCountRefused'){
            return new Error('Mixing count with other types is not allowed');
          }else if(error.code === 'object.maxFieldTypeExceeded'){
            return new Error(`Maximum allowed ${
              FieldTypes.FieldTypeText[error.local[0].type]} fields of ${error.local[0].max} exceeded`);
          }else if(error.code == 'object.multipleAggregateKPI'){
            return new Error('Multiple aggregates for the same field is not allowed');
          }else if (error.code === 'object.nonUniqueAttributeType'){
            return new Error('Mixing attribute types is not allowed');
          }else if (error.code === 'object.AggregationTypeNotAllowed'){
            return new Error(`Using ${FieldTypes.FieldTypeText[error.local[0].type]} aggregation fields is not allowed`);
          }else if (error.code === 'object.minFieldTypeExceeded'){
            return new Error(`Latest aggregation must be combined with at least 1 additional aggregation type`);
          }

          return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.FIELD)(errors);
        })
        .min(Joi.ref('minFields'))
        .required()
        .when('maxFields', [
            { is: Joi.exist(),
            then: Joi.array()
            .label(FFAGDefaults.LABELS.FIELD)
            .max(Joi.ref('maxFields'))
            .required(),
        }])
        .when('preventKpiMultipleAggregate', [{
          is: true,
          then: Joi.array()
          .custom(checkKpiMultipleAggregate, 'Check that aggregations don\'t not include multiple aggregate for same field'),
        }])
        .when('preventCount', [{
          is: true,
          then: Joi.array()
          .custom(checkCountExist, 'Check that aggregation types does not include count'),
        }])
        .when('preventCountMixing', [{
          is: true,
          then: Joi.array()
          .custom(checkOnlyCount, 'Check that count is not mixed with any other fields'),
        }])
        .when('checkUniqueFieldType', [{
          is: true,
          then: Joi.array()
          .custom(uniqueFieldType, 'Check that fields types are unique'),
        }])
        .when('maxFieldTypes', [{
          is: Joi.object().required(),
          then: Joi.array()
          .custom(checkAggregationTypeMaxExistence, 'Check that fields types are within limits'),
        }])
        .when('minFieldTypes', [{
          is: Joi.object().required(),
          then: Joi.array()
          .custom(checkAggregationTypeMinExistence, 'Check that fields types are within limits'),
        }])
        .when('preventAttributeTypeMixing', [{
          is: true,
          then: Joi.array()
          .custom(checkUniqueAttributeTypeAggregations, 'Check that fields have the same attribute type'),
        }])
        .when('validate', [
            { is: true,
            then: Joi.array()
            .items(fieldValidation),
        }]),
    }).unknown(),
    filtersVariables: Joi.object({
      filters: Joi.array()
      .custom(checkFiltersWithVariablesValidity, 'checkFiltersWithVariablesValidity')
      .label(FFAGDefaults.LABELS.FILTER)
      .error(ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.FILTER))
      .error((errors) =>{
        const error = errors[0];

        if(error.code === 'object.bulkMlMustContainAtLeastOneVariableFilter'){
          return new Error('Bulk ML FFAG must contain at least 1 filter variable');
        } else if(error.code === 'object.invalidInFilterVariable'){
          return new Error('IN-Filter and another filter share the same variable');
        } else if(error.code === 'object.invalidRefAndNonRefFilterVariable'){
          return new Error('Ref and non-Ref filters share the same variable');
        } else if(error.code === 'object.differentLevelsRefFilters'){
          return new Error('Different levels Ref filters share the same variable');
        }

        return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.FILTER)(errors);
      }),
      routingFilter: Joi.object().allow(null),
    }),
    filters: Joi.object().keys({
        filters: Joi.array()
            .label(FFAGDefaults.LABELS.FILTER)
            .error(ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.FILTER))
            .when('validate', [
                { is: true,
                then: Joi.array()
                .items(filtersValidation),
            }])
            .when('postAggregationFilterEnabled', [
              {is: true,
              then: Joi.array().min(1)
              }
            ]),
        validate: Joi.bool(),
        postAggregationFilterEnabled: Joi.bool(),
    }),
    filter: filtersValidation,
    groupByFields: Joi.object().keys({
        minGruopBy: Joi.number().optional().allow(null),
        groupByFields: Joi.array()
            .label(FFAGDefaults.LABELS.GROUPING)
            .error((errors) =>{
                const error = errors[0];

                if(error.code === 'object.mandatoryFieldsMissing'){
                    return new Error(DataSelectionUtils.getMissingMandatoryGroupByFieldsErrorMessage(error.state.ancestors[0].mandatoryGroupByFields));
                } else if(error.code === 'object.min.mandatoryFieldType'){
                  return ValidationUtils.ERR_MSGS.arrayMinLength(
                    error.state.ancestors[0].mandatoryFieldType.type, 
                    error.state.ancestors[0].mandatoryFieldType.min);
                } else if(error.code === 'object.max.mandatoryFieldType'){
                  return ValidationUtils.ERR_MSGS.arrayMaxLength(
                    error.state.ancestors[0].mandatoryFieldType.type, 
                    error.state.ancestors[0].mandatoryFieldType.max);              
                }else if (error.code === 'object.groupByNotAllowed'){
                  return ValidationUtils.ERR_MSGS.notAllowed('Grouping by');
                } else if(error.code === 'object.atLeastOneNonSpecificField') {
                  return ValidationUtils.ERR_MSGS.arrayMinLength(
                    'Non-' + error.state.ancestors[0].notOnlySpecificField.type, 
                    error.state.ancestors[0].notOnlySpecificField.min);
                }else if(errors[0].code === 'number.isMultiple'){
                  return new Error('Date must be a multiple of Resolution');
                } else if(errors[0].code ==='number.mustSelectAuto'){
                  return new Error('Auto Resolution only is accepted');
                }
                else if (error.code === 'object.field_multiValued_not_allowed') {
                  return ValidationUtils.ERR_MSGS.notAllowed('This field');
                }

              return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.GROUPING)(errors);
            })
            .min(Joi.ref('minGruopBy'))
            .when('mandatoryGroupByFields', [
                {
                    is: Joi.array().min(1).required(),
                    then: Joi.array().custom(mandatoryGroupByFieldsCustomValidator, 'Checking mandatory group by fields.'),
                }
            ])
            .when('mandatoryFieldType', [
              {
                is: Joi.exist(),
                then: Joi.array().custom(mandatoryGroupByFieldTypeCustomValidator, 'Checking mandatory group by field type.'),
              },
            ])
            .when('notOnlySpecificField', [
              {
                is: Joi.exist(),
                then: Joi.array().custom(mandatoryGroupByWithAtLeastOneNonSpecificField, 'Checking at least one non-date field is selected.'),
              },
            ])
            .when('validate', [
                { is: true,
                then: Joi.array()
                .items(groupbyValidation),
            }])
            .when('preventGroupByOnNonNumeric', [
              {
                is: true,
                then: Joi.array().custom(checkGroupByWithNonNumericAggregation, 'Check that group by is allowed within aggregation fields.'),
              },
            ])
            .when('checkDateIsMultipleOfResolution',[
              {
                is: true,
                then: Joi.array().custom(checkIsMultipleOfRes, 'Checking that Selected date must a be multiple of Resolution.'),
              }
            ])
            .when('checkBoundsByFieldsTypes', [
              {
                is: Joi.exist(),
                then: Joi.array().custom(checkGroupByBoundsByFieldsType, 'Check that group by is allowed within aggregation fields.'),
              },
            ]),
        validate: Joi.bool(),
    })
    .unknown(),
    columns: Joi.object().keys({
        minGruopBy: Joi.number().optional().allow(null),
        groupByFields: Joi.array()
            .label('Column')
            .error(ValidationUtils.getErrMsgArray('Column'))
            .min(Joi.ref('minGruopBy'))
            .when('validate', [
                { is: true,
                then: Joi.array()
                .items(groupbyValidation),
            }]),
        validate: Joi.bool(),
    })
    .unknown(),
    topologyNodes: Joi.object().keys({
      minGruopBy: Joi.number().optional().allow(null),
      groupByFields: Joi.array()
          .label('Node')
          .error(ValidationUtils.getErrMsgArray('Node'))
          .min(Joi.ref('minGruopBy'))
          .when('validate', [
              { is: true,
              then: Joi.array()
              .items(groupbyValidation),
          }]),
      validate: Joi.bool(),
  })
  .unknown(),
    groupByField: groupbyValidation,
    collectionAggregation: collectionAggregationSchema,
    advancedSection:Joi.object()
    .keys({collectionAggregation: collectionAggregationSchema}).unknown()
    .error(ValidationUtils.ERR_MSGS.invalidComponent(FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.COLLECTION_AGGREGATION)),
    dataSelectionFgaWithResolution,
    };

export default ValidationUtils.validateSchema(SCHEMA);
