/* Copyright 2019 Greyskies. All Rights Reserved. */

import { fromJS } from 'immutable';
import {AggregationUtils, FieldTypes, ResolutionUtils} from 'js-utils';

const AGGREGATE_FIELD_NAME = 'Aggregate';

export const NUMERIC_AGGREGATIONS = [
  {
    name: 'Average',
    value: 'AVERAGE',
  },
  {
    name: 'Sum',
    value: 'SUM',
  },
  {
    name: 'Nth Percentile',
    value: 'NTH_PERCENTILE',
  },
  {
    name: 'Max',
    value: 'MAX',
  },
  {
    name: 'Min',
    value: 'MIN',
  },
  {
    name: 'Unique Count',
    value: 'UNIQUE_COUNT',
  },
  {
    name: 'Weighted Average',
    value: 'WEIGHTED_AVG'
  }
];

export const NONNUMERIC_AGGREGATIONS = [
  {
    name: 'Latest',
    value: 'LATEST',
  },
  {
    name: 'Unique Count',
    value: 'UNIQUE_COUNT',
  },
];

export const LABELS = {
  GROUPING: 'Group',
  ADVANCED: 'Advanced',
}

export const SEASONALITY_VALUES = {
  SEASONAL: 'SEASONAL',
  NON_SEASONAL: 'NON_SEASONAL',
};

export const DEFAULT_TOPN_SETTINGS = {
  nValue: 10,
  nDirection: 'DESC',
};

export function getMissingMandatoryGroupByFieldsErrorMessage(mandatoryFields){
  let s = '';
  let toBe = 'is';
  let fieldsInMessage = '';
  const newMandatoryFields = fromJS(mandatoryFields).toJS();
  const last = newMandatoryFields.pop();
  if(newMandatoryFields.length >= 1){
      s = 's';
      toBe = 'are';
      fieldsInMessage = newMandatoryFields.join(', ') + ' and ' + last;
  } else{
      fieldsInMessage = last;
  }
  return `Field${s} ${fieldsInMessage} ${toBe} required in Grouping`;
}

export function getObligatoryNERefFilterIndex(recordSourceFields){
  return fromJS(recordSourceFields).toJS()
    .findIndex(field => FieldTypes.isNetworkElementField(field) && field.ref );
}

export function getObligatoryRoutingFilter(sourceField, isDataSourceRecordForAttributes){
  if(sourceField){
    return {
      operation: null,
      compareValue: '',
      relationToNext:null,
      selection: null,
      disableDelete: isDataSourceRecordForAttributes,
      disableChange: true,
      routingValues: [],
      routingFilterForAttributes: isDataSourceRecordForAttributes,
      selectedField: sourceField,
    };
  }

  return null;
}

export function getResolutionObj(unit, interval){
  return {
    resolutionUnit: unit, resolution: interval,
  }
}

export function updateDateResolution(groupByField, showCustomInterval,
  customUnitsValues, customUnits){
    if(FieldTypes.isDateField(groupByField.selectedField)){
      let resObj;
      if(showCustomInterval){
        const obj = customUnitsValues[customUnits[0]];

        resObj = getResolutionObj(obj.unit, obj.interval);
      }else{
        resObj = getResolutionObj(ResolutionUtils.AUTO_RESOLUTION);
      }
      Object.assign(groupByField, resObj);
    }
}

export function getDefaultsForGroupBy(sourceFields, groupByFieldsOptions, isRecordsForAttributes, 
  showCustomInterval, customUnitsValues, customUnits){
  const groupByFields = [];
  const defaultFields = isRecordsForAttributes ? groupByFieldsOptions.defaultFields.recordsForAttributes 
    : groupByFieldsOptions.defaultFields.records;
  
  if(defaultFields && sourceFields.length > 0){
    let groupByCount = 0;

    sourceFields.forEach(selectedField => {
      if(selectedField.groupable && defaultFields.includes(selectedField.fieldName) ){
        const groupByField = {
          key: groupByCount,
          selectedField,
        };
        
        updateDateResolution(groupByField, showCustomInterval,
          customUnitsValues, customUnits);

        groupByFields.push(groupByField);
        groupByCount++;
      }
    });
  }

  return groupByFields;
}

export function filterNumericFFAGFields(ffagFields){
  return ffagFields.filter(field => {
    return AggregationUtils.isNumeric(field && field.selectedField && field.selectedField.type) 
      && !FieldTypes.isDateField(field.selectedField);
  });
}

export function filterDateField(sourceFields){
  return sourceFields.filter(field => {
    return FieldTypes.isDateField(field);
  })[0];
}

export function filterAggregateField(attributeSourceFields){
  return attributeSourceFields.filter(field => {
    return field.fieldName == AGGREGATE_FIELD_NAME;
  })[0];
}

export function filterOutUniqueCountAggregations(aggregations){
  return aggregations.filter(agg => {
    return agg.value !== 'UNIQUE_COUNT';
  });
}

export function hasMatchingFieldOrSubfieldWithType(selectedField, aggregationFieldType){
  if(selectedField.type === aggregationFieldType){
    return true;
  }
  const subFields = selectedField.subFields;

  for(let i = 0; i < subFields.length; i++){
    if(subFields[i].type === aggregationFieldType){
      return true;
    }
  }
  
  return false;
}

export function filterFieldsByAggregationType(fields, aggregation){
  const aggregationFieldType = AggregationUtils.getAggregationFieldType(aggregation);

  return fields.filter(field => aggregationFieldType == AggregationUtils.MIXED 
    || hasMatchingFieldOrSubfieldWithType(field.selectedField ? field.selectedField : field, aggregationFieldType));
}

export function fillSourceFieldsMetaData(sourceFields){
  const sourceFieldsHash = {};
  const groupableFields = [];

  sourceFields.forEach(field => {
    sourceFieldsHash[field.id] = field;
    if(field.groupable){
      groupableFields.push(field);
    }
  });

  return {sourceFieldsHash, groupableFields};
}
