/* Copyright 2019 Greyskies. All Rights Reserved. */
import _ from 'lodash';

const WHOLE_INTEGERS_REGEX = new RegExp(/^\d+$/);

export const isBlank = (object) => {
  return object === undefined || object === null;
};

export const isEmpty = (object) => {
  return object === undefined || object === null || object === '' || (Array.isArray(object) && !object.length);
};

export const isEmptyArray = (arr) => {
  return isEmpty(arr) || !arr.length;
};

export const isEmptyObject = (object) => {
  return isEmpty(object) || _.isEmpty(object);
};

export const isEmptyString = (object) => {
  return isEmpty(object) || object.trim() == '';
};

export const isStringBoolean = (string) => {
  return string === 'true' || string === 'false';
};

export const isIntegerValue = (value) => { 
  return WHOLE_INTEGERS_REGEX.test(value);
};

export const stringToBoolean = (string) => {
  if(string === 'true'){
    return true;
  }else if(string === 'false'){
    return false;
  }

  return string;
};

export const isValidNumber = (number) => {
  return typeof Number(number) === 'number';
};

export function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}

export function capitalize(string) {
  return string.replace(/\w\S*/g, (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  });
}
// should be renamed to isNumericPropertyValid
// separate isPropertyValid without nan check
export function isPropertyValid(obj, property){
  return obj.hasOwnProperty(property) && obj[property] && !isNaN(obj[property]);
}

export function getObjectValueByPropertyPath(obj, path){
  return path.split('.').reduce((res, prop) => res[prop], obj);
}
