/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { TooltippedButton, Icons, CommonValidationSchema } from 'react-template';
import LoginAPI from 'api/LoginAPI';
import { ValidationUtils, MsgDefaults } from 'js-utils';
import userDefaults from 'config/userDefaults';

const SHOW_PASSWORD = 'showPassword';
const SHOW_CONFIRM_PASSWORD = 'showConfirmPassword';
const SHOW_CURRENT_PASSWORD = 'showCurrentPassword';

export function validateConfirmPassword(value, password) {
  if (password === value) {
    return null;
  }
  return ValidationUtils.errorToValidationObject(ValidationUtils.ERR_MSGS.notMatching('Passwords'));
}

export const checkIfUsernameExists = (originalName, inputValidator, token) => (username) => {
  return CommonValidationSchema.checkIfNameExists(
    username, 
    'username', 
    true, 
    originalName,
    LoginAPI.checkUserExists,
    inputValidator,
    'Username', 
    [username.trim(), token],
  );
}

function getShowHidePasswordBtn(state, stateKey, onClick) {
  return (
    <TooltippedButton
      onClick={() => onClick({ [stateKey]: !state[stateKey] })}
      tooltip={`${state[stateKey] ? 'Hide' : 'Show'} Password`} className='btn-primary togglePasswordBtn'
    >
      <i className={state[stateKey] ? Icons.hide : Icons.view}></i>
    </TooltippedButton>
  );
}

export function getInfoPasswordBtn() {
  return (
    <TooltippedButton  className="btn-action infoBtn" tooltip={userDefaults.PASSWORD_VALIDATION} >
      <i className={Icons.info}/>
    </TooltippedButton>
  );
}

export function getPasswordsFormInputs(state, hasCurrentPassword, onClick, requiredAstric, showLabel, variableSizes) {
  return [
    ...(hasCurrentPassword ? [{
      type: 'text',
      label: showLabel ? getLabel("Current Password", requiredAstric) : null,
      keyword: 'currentPassword',
      validationLabel: 'Current Password',
      placeholder: MsgDefaults.getEnterMsg('Current Password'),
      validation: true,
      trimmed: true,
      password: !state[SHOW_CURRENT_PASSWORD],
      initialValue: state.currentPassword,
      variableSizes: variableSizes || {
        inputSize: 12,
      },
      buttons: getShowHidePasswordBtn(state, SHOW_CURRENT_PASSWORD, onClick),
    }] : []),
    {
      type: 'text',
      label: showLabel ? getLabel("Password", requiredAstric) : null,
      keyword: 'password',
      validationLabel: 'Password',
      placeholder: MsgDefaults.getEnterMsg('Password'),
      validation: true,
      trimmed: true,
      password: !state[SHOW_PASSWORD],
      initialValue: state.password,
      validateBefore: ['confirmPassword'],
      variableSizes: variableSizes || {
        inputSize: 12,
      },
      labelHint: showLabel ? (
        getInfoPasswordBtn()
      ) : null,
      buttons:  getShowHidePasswordBtn(state, SHOW_PASSWORD, onClick),
    },
    {
      type: 'text',
      label: showLabel ? getLabel("Confirm Password", requiredAstric) : null,
      keyword: 'confirmPassword',
      validationLabel: 'Confirm Password',
      placeholder: "Confirm Password",
      validation: true,
      trimmed: true,
      password: !state[SHOW_CONFIRM_PASSWORD],
      initialValue: state.confirmPassword,
      variableSizes: variableSizes || {
        inputSize: 12,
      },
      buttons: getShowHidePasswordBtn(state, SHOW_CONFIRM_PASSWORD, onClick),

    }];
}

export function getEmailFormInput(disabled, initialValue, showLabel, variableSizes) {
  return getTextInput("email", "Email", disabled, initialValue, showLabel, true, variableSizes);
}

export function getUsernameFormInput(disabled, initialValue, showLabel, requiredAstric, onBlurValidationPromise, variableSizes) {
  return {
    ...getTextInput("username", "Username", disabled, initialValue, showLabel, true, variableSizes, requiredAstric),
    onBlurValidationPromise,
  };
}

export function getFirstNameFormInput(disabled, initialValue, showLabel, variableSizes) {
  return getTextInput("firstName", "First Name", disabled, initialValue, showLabel, false, variableSizes);
}

export function getLastNameFormInput(disabled, initialValue, showLabel, variableSizes) {
  return getTextInput("lastName", "Last Name", disabled, initialValue, showLabel, false, variableSizes);
}

export function getMobileFormInput(disabled, initialValue, showLabel, variableSizes) {
  return getTextInput("mobile", "Mobile", disabled, initialValue, showLabel, false,variableSizes);
}

function getTextInput(keyword, label, disabled, initialValue, showLabel, trimmed, variableSizes, requiredAstric) {
  const input = {
    type: 'text',
    keyword,
    initialValue,
    disabled,
    validation: !disabled,
    trimmed,
    variableSizes,
    placeholder: MsgDefaults.getEnterMsg(label),
  };

  if(showLabel) {
    return {
      ...input,
      label: getLabel(label, requiredAstric),
    };
  } else {
    return {
      ...input,
      validationLabel: label,
      variableSizes: variableSizes ||  {
        inputSize: 12,
      },
    };
  }
}

function getLabel(label, required) {
  return `${label}${required ? '*' : ''}`
}