/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import {MsgDefaults, Utils as JSUtils} from 'js-utils';
import * as FilterUtils from 'utils/filterUtils';
import {LABELS} from 'utils/ffagDefaults';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import { fromJS } from 'immutable';
import * as defaults from 'utils/defaults';

const FIELD_NAME = 'fieldName';
const ID = 'id';

export default class FgaAttributeSelection extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeSubField = this.onChangeSubField.bind(this);
    this.renderObjectKeyComponent = this.renderObjectKeyComponent.bind(this);
    this.renderMultipleSelectedAttribute = this.renderMultipleSelectedAttribute.bind(this);
    this.shouldShowAllOption = this.shouldShowAllOption.bind(this);
  }

  onChangeField({target: {value: attribute}}){
    const selectedField = this.props.fieldsList.filter(
      field => UIConstructionUtils.getKeyFromId(field) == attribute)[0];

    this.props.onChange(selectedField);
  }

  onChangeSubField({target: {value: attribute}}){
    const fieldsList = this.props.fieldsList;

    for(const field of fieldsList){
      if(UIConstructionUtils.getKeyFromId(field) == attribute){
        this.props.onChange(field);
        break;
      }
      for(const subField of field.subFields){
        if(subField.id == attribute){
          this.props.onChange(subField);
          break;
        }
      }
    }
  }

  shouldShowAllOption(parentField) {
    return !JSUtils.isBlank(parentField) 
      && (this.props.hasWholeObject || this.props.supportMultiWholeObject);
  }

  renderObjectKeyComponent(){
    const parentField = DataSelectionCommonUtils.getParentField(
      this.props.attribute, this.props.fieldsList);

    let subFields = this.props.subFields;

    if(this.shouldShowAllOption(parentField)){
      const wholeObject = fromJS(parentField).toJS();

      wholeObject.fieldName = 'All';
      subFields = fromJS(this.props.subFields)?.toJS();
      subFields.unshift(wholeObject);
    }

    const options = UIConstructionUtils.wrapArrayInToSelectOptions(
      subFields, FIELD_NAME, ID, FIELD_NAME);

    return(
      <Col className='margBtm5' xs={this.props.colSize || 12}>
        <OverlayTrigger
          shouldUpdatePosition
          rootClose
          placement='bottom'
          trigger={this.props.child ? [] : ['hover', 'focus']}
          overlay={
            <Tooltip placement='bottom' className='in' >
              {defaults.SELECT_OBJECT_KEY}
            </Tooltip>
          }
        >
          <div>
            <MultipleSelect
              placeholder={MsgDefaults.getInsertMsg(LABELS.OBJECT_KEY)}
              onChange={this.onChangeSubField}
              value={this.props.attribute?.id}
              disabled={this.props.disabled}
              className={this.props.className + 'ObjectKey-menu'}
              mainClassName='evc-transparent-input'
              alwaysUpdate
              options={options}
            />
          </div>
        </OverlayTrigger>
      </Col>
    );
  }

  renderMultipleSelectedAttribute(){
    const parentFieldId = this.props.attribute?.parentFieldId;
    const fieldId 
    = this.props.attribute? UIConstructionUtils.getKeyFromId(this.props.attribute) : null;

    return (
      <Col className='margBtm5' xs={this.props.colSize || 12}>
        <OverlayTrigger 
          shouldUpdatePosition
          rootClose
          placement='bottom'
          trigger={this.props.child ? [] : ['hover', 'focus']}
          overlay={ 
            <Tooltip placement='bottom' className='in' >
              {this.props.tooltip}
            </Tooltip>
          }
        >
          <div>
            <MultipleSelect
              placeholder={this.props.placeholder}
              className={this.props.className + 'SelectAttr-menu'}
              disabled={this.props.disabled}
              options={this.props.options}
              value={parentFieldId == null ? fieldId : parentFieldId}
              onChange={this.onChangeField}
              grouped={this.props.grouped}
            />
          </div>
        </OverlayTrigger>
      </Col>
    )
    ;
  }

  render() {
    const showObjectKey = FilterUtils.isObjectFilter(this.props.attribute?.attributeType?.dataType) 
      || this.props.attribute?.parentFieldId != null;

    return (
      <Row>
        {this.renderMultipleSelectedAttribute()}
        <Fragment>
          {showObjectKey ? this.renderObjectKeyComponent() : null}
        </Fragment>
      </Row>
    );
  }
}
