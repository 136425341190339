/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {FormWithFooter, Icons} from 'react-template';
import LoginAPI from 'api/LoginAPI';
import inputValidator from 'validations/AccountManagementValidationSchema';
import { withRouter } from 'react-router';
import ExternalPageContainer from './ExternalPageContainer';
import ExternalPageAlert from './ExternalPageAlert';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import 'styles/login/forgetPassword.less';

@withRouter
export default class ForgetPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      email: '',
      loading: false,
      success: false,
      error: false,
    };
    
    this.sendEmail = this.sendEmail.bind(this);
    this.getFormInputs = this.getFormInputs.bind(this);
    this.getAlert = this.getAlert.bind(this);
  }

  sendEmail(){
    this.setState({loading: true, success:false, error: false}, () => {
      LoginAPI.resetPassword(this.state.email)
        .then(() => {
          this.setState({loading: false, success: true});
        })
        .catch(() => {
          this.setState({loading: false, error: true});
        });
    });
  }

  getFormInputs(){
    const variableSizes = {
      labelSize: 12,
      inputSize: 12,
    };
    return [AccountManagementUtils.getEmailFormInput(false, this.state.email, true, variableSizes)];
  }
  
  getAlert() {
    if(this.state.error) {
      return <ExternalPageAlert isError title='Error While Sending Mail' message='Something wrong happened in the server. Please contact the Support Team.'/>
    }
    if(this.state.success) {
      return <ExternalPageAlert title='Success' message='You will receive the password reset email (applicable only for local users)'/>
    }
  }

  render() {
    return (
      <ExternalPageContainer 
      title='Forget Password?' 
      subtitle= "Please enter your email address to reset your password"
      pageClassName='forgetPasswordPageWrapper'>
        {this.getAlert()}
        <FormWithFooter
          inputs={this.getFormInputs()}
          validationFunction={inputValidator}
          expandFullWidth
          noFooterBorder
          onChange={(result) => this.setState({...result})}
          onSubmit={this.sendEmail}
          disableSaveButton={this.state.loading}
          onCancel={()=>this.props.router.push({pathname: '/'})}
        >
          <Row>
            <Col xs={12}>
              <div className='alert alert-info'>
                <div className="alertHeading ev-auto-alertHeading"> 
                  <i className={`alertIcon ev-auto-alertIcon ${Icons.info}`}></i>
                  <p className="alertTitle ev-auto-alertTitle">Note:</p>
                </div>
                <p className="alertBody ev-auto-alertBody">
                'Password Reset' feature is not available for LDAP users. It is intended for local users. If you're a local user and need to reset your password, please submit your email address and you'll receive further instructions via email.
                </p>
              </div>
            </Col>
          </Row>
        </FormWithFooter>
      </ExternalPageContainer>
    );
  }
}
