/* Copyright 2019 Greyskies. All Rights Reserved. */
import {MsgDefaults} from 'js-utils';
export const FFAG_INPUT_NAME = "name";
export const FFAG_KEYWORD = "fga";
export const FFAG_ERROR_MESSAGE = "Error Adding FGA";
export const DATE_FILTER_TIME_ZONE_MSG = 'All selected date/time ranges are in';

export const defaultDataSelectionOptions = {
  showCollectionAggregation: true,
  showAdvancedOptions: true,
  hideReuse: false,
  finalAggLimits: {
    groupByFields: 0, 
    fields: 1,
  },
  filters:{
    hide: false,
    min: 0,
    max: null,
  },
  groupByFields:{
    hide: false,
    hideResolutionInterval: false,
    min: 0,
    max: null,
    defaultFields: {},
    mandatoryFieldType: undefined,
    canHaveObjectSubField: false,
    canHaveWholeObject: false,
    canHaveMultiValueField: true,
  },
  fields:{
    hide: false,
    min: 1,
    max: null,
    canHaveObjectSubField: false,
    canHaveWholeObject: false,
    canHaveNonNumericMultiValueField: true,
    canHaveMultiValueField: true,
  },
  columns:{
    hide: true,
    hideResolutionInterval: true,
    min: 0,
    max: null,
    canHaveObjectSubField: false,
    canHaveWholeObject: false,
    canHaveMultiValueField: true,
  },
  topologyNodes:{
    hide: true,
    hideResolutionInterval: true,
    min: 0,
    max: null,
    canHaveObjectSubField: false,
    canHaveWholeObject: false,
    canHaveMultiValueField: true,
  },
  limitSize: {
    showLimitSize: false,
    maxLimitSize: 0,
  },
};

export const LABELS = {
    SELECTED_FIELD: 'Attribute',
    AGGREGATION_TYPE: 'Aggregation Type',
    UNIT: 'Unit',
    INTERVAL: 'Interval',
    OPERATION: 'Operation',
    NAME: 'Name',
    MANDATORY_NAME: 'Name *',
    SOURCE_TYPE: 'Source Type',
    MAPPING_FIELD: 'Mapping Field',
    FIELD: 'Aggregate',
    FILTER: 'Filter',
    POST_AGGREGATION: 'Post Aggregation',
    GROUPING: 'Grouping',
    VALUE: 'Value',
    SELECTION: 'Selection',
    DATE_SELECTION: 'Date Selection',
    LIST_SELECTION: 'File filter',
    RELATION_TO_NEXT_FILTER: 'Relation to next filter',
    WEIGHTED_AVG_FIELD: 'Weighted Average Field',
    PUBLISH_FFAG: 'Publish this FGA',
    FFAG: 'FGA',
    FFAGs: 'FGAs',
    SELECTION_SECTION: 'Selection',
    NE_FILTER_SECTION: 'Network Element Filter',
    OBJECT_KEY: 'Object key',
    WEIGHTED_AVG_OBJECT_KEY: 'Weighted Average Object key',
}
export const CLEAR_SELECTION = 'Clear selection';
export const FAIL_DELETION_MESSAGE = 'Error while deleteing FGA';
export const SUCCESS_CLONE_MESSAGE = 'FGA cloned Successfully';
export const FAIL_CLONE_MESSAGE = 'Error while clonning FGA';
export const CLONE_TITLE = 'Clone FGA';
export const FORMULA_ATTRIBUTE_BINDING_FGA_RELATION_ERROR = 'FGA cannot be deleted because this FGA is attached to the following attribute bindings: ';
export const RECORD_TYPE_VALIDATION = "Please select a source";
export const FFAG_PAGE_NAME = 'FGA';
export const FFAG = 'ffag';

export const CREATE_OPTION = 'CREATE';
export const SELECT_EXIST_OPTION = 'SELECT_EXIST_OPTION';
export const CREATE_OPTION_LABEL = 'Create new';
export const SELECT_EXIST_OPTION_LABEL = 'Reuse';
export const CREATE_OPTION_PLACEHOLDER = 'Please Select FGA';
export const SAVING_MODEL_HEADER_TITLE = 'This FGA is attached to the following : ';
export const POST_AGGREGATION_TOOLTIP_MESSAGE = 'Will only work with Table and Map widgets';

export const USER_RIGHT = {
    ADMIN: 'ADMIN',
    EDITOR: 'EDITOR',
    VIEWER: 'VIEWER',
};
export const COLLECTION_AGGREGATION_CONSTANTS = {
  COLLECTION_AGGREGATION: 'Collection Aggregation',
  TIME_INTERVAL_PLACEHOLDER: MsgDefaults.getSelectMsg('Time Interval'),
  AGGREGATION_TYPE_PLACEHOLDER: MsgDefaults.getSelectMsg('Aggregation Type'),
  NTH_PERCENTILE: 'NTH_PERCENTILE',
  PERCENTILE_DEFAULT: '95',
  TIME_INTERVAL_DEFAULT: 5,
  UNIT_DEFAULT: 'MINUTES',
  TYPE_DEFAULT: 'MAX',
};

export const LABEL_OPTIONS = ['Field', 'Aggregation'];
export const TEMPLATE_FFAG = 'Template FGA';

export const FFAG_SETTINGS_KEYWORDS = {
  ffagTypeKeyword:  'settingsType',
  dataSelectionKeyword: 'ffag',
  seriesAggregationKeyword: 'aggregationType',
  nthPercentileKeyword: 'percentileValue',
  unitKeyword: 'unit',
  topNSettingsKeyword: 'topNSettings',
  isLatestKeyword: 'latest',
};
