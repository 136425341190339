/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Col } from 'react-bootstrap';
import { fromJS } from 'immutable';
import CollapsableComponent from 'containers/CollapsableComponent';
import {MsgDefaults, AggregationUtils, DateTimeUtils, DataModelTypeUtils} from 'js-utils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import DataGroupingInputRow from './DataGroupingInputRow';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';

export default class DataGroupingInput extends React.Component {

  constructor(props) {
    super(props);
  
    this.addGroupBy = :: this.addGroupBy;
    this.setGroupByRowsListState = :: this.setGroupByRowsListState;
    this.setSelectedFieldsSet = :: this.setSelectedFieldsSet;
    this.selectedFieldsSet = new Set();
    this.updateStateFromProps = :: this.updateStateFromProps;

    this.state = this.updateStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    if (props[props.keyword] != null) {
      props[props.keyword].forEach((groupByField) => {
        groupByField.id != undefined ? this.selectedFieldsSet.add(groupByField.selectedField.id) : null;
      });
    }
    this.setState(this.updateStateFromProps(props));
  }

  updateStateFromProps(newProps) {
    if (newProps[newProps.keyword] != null) {
      newProps[newProps.keyword].forEach((groupByField) => {
        groupByField.id != undefined ? this.selectedFieldsSet.add(groupByField.selectedField.id) : null;
      });
    }

    return {
      collapsed: false,
      groupByRowsList: newProps[newProps.keyword] || [],
      recordType: newProps.recordType,
    };
  }

  addGroupBy() {
    const groupByRowsList = fromJS(this.state.groupByRowsList).toJS();

    groupByRowsList.push({
      idCounter: DateTimeUtils.getCurrentDateTimeinMillieSeconds(),
    });

    this.setState({ 
      groupByRowsList,
    });
    this.props.onChange({ [this.props.keyword]: groupByRowsList }, false, true, false);
  }

  getDataGroupingInputRow(index, groupByRow) {
    const selectedFieldsInRowsList = [];

    for (let i = 0; i < this.state.groupByRowsList.length; i++) {
      if (i != index && this.state.groupByRowsList[i].selectedField) {
        selectedFieldsInRowsList.push(this.state.groupByRowsList[i].selectedField.id);
      }
    }

    const usedFields = selectedFieldsInRowsList.concat(this.props.aggregationFieldIds);

    const selectedField = this.state.groupByRowsList[index]?.selectedField;
    const subFields = DataSelectionCommonUtils.getSubFields(selectedField,
      this.props.attributesList, usedFields);
    const parentField = DataSelectionCommonUtils.getParentField(
      selectedField, this.props.attributesList);

    let wholeObjectIndex = null;
    
    for(let i = 0; i < this.state.groupByRowsList.length; i++){
      const field = this.state.groupByRowsList[i].selectedField;

      if(field && parentField && field.id === parentField.id){
        wholeObjectIndex = i;
        break;
      }
    }

    const hasWholeObject = this.props.options.canHaveWholeObject 
        && ((parentField && !selectedFieldsInRowsList.includes(parentField.id)) 
          || index === wholeObjectIndex);

    const filteredFieldsList = this.props.attributesList ?
      this.props.attributesList.filter(item => {
        if (item.elasticsearchName === AggregationUtils.COUNT_ES_FIELDNAME){
          return false;
        }

        const isObject = DataModelTypeUtils.isObjectFieldType(item);
        const isSelected = selectedFieldsInRowsList.includes(item.id);
        const hasNoSubFields = item?.subFields.length === 0;

        if(!isObject){
          return !isSelected;
        }

        if(!isSelected && hasNoSubFields && this.props.options.canHaveWholeObject){ 
          return true;
        }
        
        return DataSelectionCommonUtils.hasUnusedSubFields(item?.subFields, usedFields)
         || hasWholeObject;
      }) : [];

    return (
      <DataGroupingInputRow
        key={UIConstructionUtils.getKeyFromId(groupByRow)}
        fieldsList={filteredFieldsList}
        selectedFieldsSet={this.selectedFieldsSet}
        setSelectedFieldsSet={this.setSelectedFieldsSet}
        groupByRowsList={this.state.groupByRowsList}
        subFields={subFields}
        hasWholeObject={hasWholeObject}
        setGroupByRowsListState={this.setGroupByRowsListState}
        index={index}
        showCustomInterval={this.props.showCustomInterval}
        customUnits={this.props.customUnits}
        customUnitsValues={this.props.customUnitsValues}
        hideResolutionInterval={this.props.options.hideResolutionInterval}
        selectedField={groupByRow.selectedField}
        resolution={groupByRow.resolution}
        resolutionUnit={groupByRow.resolutionUnit}
        singleEntityTitle={this.props.singleEntityTitle}
        validationOutcome= {this.props.validationOutcome.groupByList && this.props.validationOutcome.groupByList[this.props.keyword]? this.props.validationOutcome.groupByList[this.props.keyword][index] : null}
        disableComponent={this.props.disableComponent}
        hasUnit={this.props.options.hasUnit}
        fieldUnitFactor={groupByRow.fieldUnitFactor}

      />
    );
  }

  setGroupByRowsListState(groupByRowsList, validateItems = false, validateList = false, validateListItem = false) {
    this.setState({ groupByRowsList });
    this.props.onChange({ [this.props.keyword]: groupByRowsList }, validateItems, validateList, validateListItem);
  }

  setSelectedFieldsSet(selected, add) {
    add ? this.selectedFieldsSet.add(selected.id) :
      this.selectedFieldsSet.delete(selected);
  }

  render() {
    const groupByFilters = this.state.groupByRowsList ?
      this.state.groupByRowsList.map((item, index) => {
        const groupByRow = this.getDataGroupingInputRow(index, item);
        return (
          groupByRow
        );
      }) : null;
      
    return (
      <CollapsableComponent
        className={this.props.groupByTitle}
        title={this.props.groupByTitle}
        validation={this.props.validationOutcome[this.props.keyword]}
        wrapInRow
        buttons={[
          ButtonsConstructor.add(
            this.addGroupBy, MsgDefaults.getAddNewTitle(this.props.singleEntityTitle),
            this.props.disableComponent || (!this.props.disableComponent 
              && (this.props.options.max && groupByFilters.length >= this.props.options.max)),
          ),
        ]}
      >
          {groupByFilters}
      </CollapsableComponent>
    );
  }
}
