/* Copyright 2019 Greyskies. All Rights Reserved. */

import BigDecimal from 'bigdecimal';
import { sortByKeyword } from './ArrayUtils';
import * as DataModelTypeUtils from './DataModelTypeUtils';
import {isBlank} from './Utils';
import * as AggregationUtils from './AggregationUtils';
import * as d3 from 'd3';

export const AVAILABLE_NUMBER_UNITS = [
  {
    label: '',
    value: 1,
    fullLabel:'',
  }, {
    label: 'K',
    value: 1E3,
    fullLabel:'Kilo',
  }, {
    label: 'M',
    value: 1E6,
    fullLabel:'Mega',
  }, {
    label: 'G',
    value: 1E9,
    fullLabel:'Giga',
  }, {
    label: 'T',
    value: 1E12,
    fullLabel:'Tera',
  }, {
    label: '%',
    value: 0.01,
    fullLabel:'%',
  },
];

export const AVAILABLE_NUMBER_COUNT_UNITS = [
  {
    label: '',
    value: 1,
    fullLabel:'',
  }, {
    label: 'Thousand',
    value: 1E3,
    fullLabel:'Thousand',
  }, {
    label: 'Million',
    value: 1E6,
    fullLabel:'Million',
  }, {
    label: 'Billion',
    value: 1E9,
    fullLabel:'Billion',
  }, {
    label: 'Trillion',
    value: 1E12,
    fullLabel:'Trillion',
  },
];

export const NANO_SECOND_TIME_UNIT = {
  label: 'Nanoseconds',
  value: 1 / 1000000000,
  fullLabel: 'Nanoseconds',
  abbreviation: 'nanosec',
};

export const AVAILABLE_TIME_UNITS = [
  {
    label: 'Microseconds',
    value: 1 / 1000000,
    fullLabel: 'Microseconds',
    abbreviation: 'microsec',
  }, 
  {
    label: 'Milliseconds',
    value: 1 / 1000,
    fullLabel:'Milliseconds',
    abbreviation: 'millisec',
  }, 
  {
    label: 'Seconds',
    value: 1,
    fullLabel:'Seconds',
    abbreviation: 'sec',
  }, 
  {
    label: 'Minutes',
    value: 60,
    fullLabel:'Minutes',
    abbreviation: 'min',
  }, 
  {
    label: 'Hours',
    value: 60 * 60,
    fullLabel:'Hours',
    abbreviation: 'hr',
  }, 
  {
    label: 'Days',
    value: 60 * 60 * 24,
    fullLabel:'Days',
    abbreviation: 'day',
  }, {
    label: 'Weeks',
    value: 60 * 60 * 24 * 7,
    fullLabel:'Weeks',
    abbreviation: 'week',
  }, 
  {
    label: 'Months',
    value: 60 * 60 * 24 * 30,
    fullLabel:'Months',
    abbreviation: 'month',
  }, 
  {
    label: 'Years',
    value: 60 * 60 * 24 * 365,
    fullLabel:'Years',
    abbreviation: 'yr',
  },
];

export const DEFAULT_UNIT_DIVIDER = 1;
export const DEFAULT_PRECISION = 2;
export const MIN_PRECISION = 0;
export const MAX_PRECISION = 10;
export const DEFAULT_VALUE_FORMAT = ',.2~f';
export const PERCENTAGE = '%';

export const formatNumberToString = d3.format(DEFAULT_VALUE_FORMAT);
 
export function getDefaultPrecisionIfBlank(precision){
  if(isBlank(precision)){
    return DEFAULT_PRECISION;
  }

  return precision;
}

export function getChartValueFormat(precision){
  return `,.${getDefaultPrecisionIfBlank(precision)}~f`;
}

export function getDefaultUnitDivider(){
  return DEFAULT_UNIT_DIVIDER;
}

export function isTimeDataType(dataType){
  return dataType == DataModelTypeUtils.TIME_DATA_TYPE;
}

export function getAvailableUnits(dataType){
  return isTimeDataType(dataType) ? AVAILABLE_TIME_UNITS : dataType == AggregationUtils.COUNT ? AVAILABLE_NUMBER_COUNT_UNITS : AVAILABLE_NUMBER_UNITS;
}

export function generateTooltipUnits(dataType) {
  const availableUnits = getAvailableUnits(dataType);
  const result = {};

  availableUnits.forEach(element => result[element.value] = element.label);
  
  return result;

}

export function getFactor(unit){
  if(unit === '%'){
    return 0.01;
  }
  if(unit && unit[0] === 'K'){
    return 1E3;
  } else if(unit && unit[0] === 'M'){
    return 1E6;
  } else if(unit && unit[0] === 'G'){
    return 1E9;
  } else if(unit && unit[0] === 'T'){
    return 1E12;
  }

  return 1;
}

export function safelyConvertFromUnitBaseToFactorBase(unit, dataType){
  if(isNaN(unit) || unit == null || unit == undefined){
    return this.getFactor(unit);
  }

  return unit;
}

export function getUnitLabelByFactor(factor, dataType){
  const availableUnits = getAvailableUnits(dataType);
  let label = '';

  if(isNaN(factor) && factor && factor.length > 0){
    label = factor[0];
  } else if(factor){
    const correspondingUnit = availableUnits.find(u => {
      return u.value == factor;
    });

    label = correspondingUnit ? correspondingUnit.label : '';
  }

  return label;
}

export function getUnitLabel(factor, baseUnit, dataType){
  const isTime = isTimeDataType(dataType);

  if(!isTime && (baseUnit == '%' || factor == 0.01)){
    return '%';
  }

  const label = getUnitLabelByFactor(factor, dataType);

  return isTime ? label : label + (baseUnit || '');
}

export function getNewValue(value, newFactor){
  const decimalValue = new BigDecimal.BigDecimal(value);
  const decimalNewFactor = new BigDecimal.BigDecimal(newFactor);
  const temp = decimalValue.divide(decimalNewFactor, BigDecimal.RoundingMode.DOWN());

  return temp.floatValue();
}

export function getFinalValueForm(realValue, factorUnit, baseUnit, precision, dataType){
  if(realValue == null || realValue == undefined
    || realValue == '' || isNaN(realValue)){
    return realValue;
  }

  let result = realValue;

  if(baseUnit !== '%'){
    result = this.getNewValue(result, this.safelyConvertFromUnitBaseToFactorBase(factorUnit, dataType));
  }

  if(!isBlank(precision)){
    result = result.toFixed(precision);
  }

  return Number(result);
}

export function getUnitFactor(selectedScaleDetails){
  return selectedScaleDetails.operation == '/' ? selectedScaleDetails.scaleValue
    : 1 / selectedScaleDetails.scaleValue;
}

export function sortScaleDetails(scaleDetails){
  const scaleDetailsData = scaleDetails.map(scaleDetail => (
    {
      id: scaleDetail.id,
      unit: scaleDetail.displayName,
      factor: getUnitFactor(scaleDetail),
    }
  ));

  const sortedScaleDetails = scaleDetailsData.sort(sortByKeyword('factor'));

  const percentageIndex = sortedScaleDetails.findIndex(sortedScaleDetail => sortedScaleDetail.unit == '%');

  if(percentageIndex != -1) {
    sortedScaleDetails.push(sortedScaleDetails.splice(percentageIndex, 1)[0]);
  }

  return sortedScaleDetails;
}

export function getBaseCountUnit() {
  return AVAILABLE_NUMBER_COUNT_UNITS[0].label;
}

export const getTimeBestUnit = (timeInSeconds) => {
  let resolution = NANO_SECOND_TIME_UNIT;
  const timeUnits = [NANO_SECOND_TIME_UNIT, ...AVAILABLE_TIME_UNITS];

  for(let i = timeUnits.length - 1; i >= 0 ; i--){
    if(timeInSeconds / timeUnits[i].value >= 1){
      resolution = timeUnits[i];
      break;
    }
  }

  return resolution;
};

export const convertTimeToItsBestUnit = (timeInSeconds, bestUnit, hasOptionalS) =>
  isNaN(timeInSeconds) ? '' : `${(timeInSeconds / bestUnit.value).toFixed(2)} ${bestUnit.abbreviation}${hasOptionalS ? '(s)' : 's'}`;

