class CytoscapeDomNode {
  constructor (cy, params = {}) {
    this._cy       = cy;
    this._params   = params;
    this._node_dom = {};

    const cy_container = cy.container();

    if (params.dom_container) {
      this.nodeDomContainer = params.dom_container;
    } else {
      const nodeDomContainer = document.createElement('div');

      nodeDomContainer.style.position = 'absolute';
      nodeDomContainer.style.zIndex = 10;

      const cy_canvas = cy_container.querySelector('canvas');

      cy_canvas.parentNode.appendChild(nodeDomContainer);

      this.nodeDomContainer = nodeDomContainer;
    }

    cy.on('add', 'node', (ev) => {
      this._add_node(ev.target, this.nodeDomContainer);
    });

    cy.on('layoutstop', (evt) => {
      this.regenerateNodes(cy, this.nodeDomContainer);
    });

    cy.on('render', (ev) => {
      const pan  = cy.pan();
      const zoom = cy.zoom();
      const transform = `translate(${pan.x}px,${pan.y}px) scale(${zoom})`;

      this.nodeDomContainer.style.msTransform = transform;
      this.nodeDomContainer.style.transform = transform;
    });

    cy.on('search', (ev) => {
      this.regenerateNodes(cy, this.nodeDomContainer);
    });

    cy.on('position bounds', 'node', (ev) => {
      const cy_node = ev.target;
      const id      = cy_node.id();

      if (!this._node_dom[id]) {
        return;
      }

      this.fixNodeLayerPosition(this._node_dom[id], cy_node);
    });
  }

  fixNodeLayerPosition(dom, node){
    const style_transform 
      = `translate(-50%, -50%) translate(${node.position('x').toFixed(2)}px, ${node.position('y').toFixed(2)}px)`;
    
    dom.style.webkitTransform = style_transform;
    dom.style.msTransform     = style_transform;
    dom.style.transform       = style_transform;

    dom.style.display = 'inline';
    dom.style.position = 'absolute';
  }

  regenerateNodes (cy, nodeDomContainer) {
    while (nodeDomContainer.firstChild) {
      nodeDomContainer.removeChild(nodeDomContainer.firstChild);
    }
    for (const n of cy.nodes()){
      this._add_node(n, nodeDomContainer);
    }
  }

  _add_node (n, nodeDomContainer) {
    const data = n.data();

    if (!data.dom) {
      return;
    }

    if (data.skip_node_append !== true) {
      nodeDomContainer.appendChild(data.dom);
    }
    data.dom.__cy_id = n.id();

    this._node_dom[n.id()] = data.dom;
    this.fixNodeLayerPosition(this._node_dom[n.id()], n);
  }

  node_dom (id) {
    return this._node_dom[id];
  }
}


function register (cy) {
  if (!cy) {
    return;
  }

  cy('core', 'domNode', function (params, opts) {
    return new CytoscapeDomNode(this, params, opts);
  });
}


if (typeof(cytoscape) !== 'undefined') {
  register(cytoscape);
}


module.exports = register;