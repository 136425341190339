/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';

import {FormWithFooter, Loader} from 'react-template';
import ExternalPageContainer from './ExternalPageContainer';
import inputValidator from 'validations/AccountManagementValidationSchema';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import LoginAPI from 'api/LoginAPI';
import { withRouter } from 'react-router';
import ExternalPageAlert from './ExternalPageAlert';
import { RequestUtils } from 'js-utils';

@withRouter
export default class UserRegistration extends React.Component {
  constructor(props) {
    super(props);
    const token = props.location?.query?.token;

    this.getFormInputs = this.getFormInputs.bind(this);
    this.inputValidator = this.inputValidator.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateURLToken = this.validateURLToken.bind(this);

    this.state = {
      password: '',
      confirmPassword: '',
      username: '',
      firstName: '',
      lastName: '',
      mobile: '',
      showPassword: false,
      showConfirmPassword: false,
      loading: true,
      token,
      waitingResponse: false,
      error: false,
    };
  }

  componentDidMount() {
    this.validateURLToken();
  }

  validateURLToken(){
    LoginAPI.validateURLToken(this.state.token, 'USER_INVITATION').then(value => {
      if(value){
        this.setState({
          loading: false,
        });
      }else{
        this.props.router.push({
          pathname: '/error',
        });
      }
    });
  }

  inputValidator(key, value, state){
    if(key == 'confirmPassword'){
      return AccountManagementUtils.validateConfirmPassword(value, state?.password || this.state.password);
    }
    
    return inputValidator(key, value);
  }

  getFormInputs() {
    const variableSizes = {
      labelSize: 12,
      labelSizeSm: 12,
      inputSize: 12,
      inputSizeSm: 12,
      size: 12,
    };

    return [
      AccountManagementUtils.getUsernameFormInput(false, this.state.username, true, 
        true, AccountManagementUtils.checkIfUsernameExists('', this.inputValidator, this.state.token), variableSizes),
      ...AccountManagementUtils.getPasswordsFormInputs(this.state, false, this.setState.bind(this), 
        true, true, variableSizes),
      AccountManagementUtils.getFirstNameFormInput(false, this.state.firstName, true, variableSizes),
      AccountManagementUtils.getLastNameFormInput(false, this.state.lastName, true, variableSizes),
      AccountManagementUtils.getMobileFormInput(false, this.state.mobile, true, variableSizes),
    ];
  }

  onSubmit() {
    this.setState({waitingResponse: true, error: false}, () => {
      const userInfo = {
        username: this.state.username,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobile: this.state.mobile,
        token: this.state.token,
      };

      LoginAPI.completeRegistration(userInfo).then(() => {
        this.props.router.push({
          pathname: '/',
          state: {
            successMessage: 'USER_REGISTRATION',
          },
        });
      })
        .catch((error) => {
          if(error && error.errorStatus == RequestUtils.ERR_CODES.FORBIDDEN) {
            this.props.router.push({
              pathname: '/error',
            });
          }else {
            this.setState({waitingResponse: false, error: true});
          }
        });
    });
  }

  render() {
    if(this.state.loading){
      return <Loader />;
    }

    return (
      <ExternalPageContainer subtitle={'Kindly Update Your Details, To Accept Your Invitation'} pageClassName='userRegistrationPage'>
        {this.state.error ? <ExternalPageAlert isError title='Error While Creating Account' 
          message='Something went wrong. Please refresh the page and try again'/> : null}

        <FormWithFooter
          inputs={this.getFormInputs()}
          hideCancelButton
          expandFullWidth
          noFooterBorder
          validationFunction={this.inputValidator}
          onChange={(result) => this.setState({...result})}
          onSubmit={this.onSubmit}
          submitButtonText='Confirm'
          disableSaveButton={this.state.waitingResponse}
        />
      </ExternalPageContainer>
    );
  }
}
