import React, { Component, Fragment} from 'react';
import { Row, Col, FormControl, InputGroup,
  OverlayTrigger, Tooltip} from 'react-bootstrap';

import OldTagsInput from 'react-tagsinput';
import Switch from 'react-toggle-switch';
import 'react-tagsinput/react-tagsinput.css';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-tomorrow_night';

import TooltippedButton from 'buttons/TooltippedButton';
import {DataInput, Checkbox, MultipleSelect, Radio, 
  RadioGroup, DatePicker, DateRangePicker, NumericInput, NumericInputComponent, TagsInput} from './simple/SimpleInputs';
import TreeItemView from 'dataSelection/records/TreeItemView';

import ResolutionInput from './compound/ResolutionInput';
import ItemsListComponent from './compound/ItemsListComponent';
import GroupSharing from './compound/GroupSharing';
import Modal from 'containers/Modal';
import CommonTreeWrapper from 'dataSelection/trees/CommonTree/CommonTreeWrapper';


import {wrapArrayInToSelectOptions} from 'utils/UIConstructionUtils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import Icons from 'templates/Icons';

import 'styles/inputs/formConstructors.less';

// All Form construction functions must be defined and bound here
// the function will only return the pure component, no wrapping
// inside containers should be done here, all inputs will be wrapped
// in the same way in wrapInput function
// Any functions added to the constructor must be defined inside Form
// i.e. setField, onBlurValidation

export default class FormConstructors extends Component {
  constructor(props) {
    super(props);

    this.constructInput = ::this.constructInput;
    this.constructRows = ::this.constructRows;
    this.constructText = ::this.constructText;
    this.constructTextArea = ::this.constructTextArea;
    this.constructTagsInput = ::this.constructTagsInput;
    this.constructCheckbox = ::this.constructCheckbox;
    this.constructToggle = ::this.constructToggle;
    this.constructMultipleSelect = ::this.constructMultipleSelect;
    this.constructRadio = ::this.constructRadio;
    this.constructFileUpload = :: this.constructFileUpload;
    this.constructDatePicker = ::this.constructDatePicker;
    this.constructDateRangePicker = ::this.constructDateRangePicker;
    this.constructTreeSelectorPopup = ::this.constructTreeSelectorPopup;
    this.setTreeRef = ::this.setTreeRef;
  }

  constructText(input) {
    return(
      <DataInput
        pureInput
        className={input.class}
        disabled={input.disabled}
        name={input.keyword}
        password={input.password}
        value={this.state[input.keyword]}
        placeholder={input.placeholder}
        onChange={this.setField(input)}
        onBlur={this.onBlurValidation(input)}
        isTextarea={input.isTextArea}
      /> 
    );
  }

  constructCustomComponent(input){
    return input.component;
  }

  constructEditor(input) {
    return (
      <AceEditor
        mode={input.mode}
        theme={input.theme}
        name={input.key}
        highlightActiveLine={input.highlightActiveLine}
        showGutter={input.showGutter}
        showPrintMargin={input.showPrintMargin}
        placeholder={input.placeholder}
        value={this.state[input.keyword]}
        maxLines={input.maxLines}
        minLines={input.minLines}
        wrapEnabled={input.wrapEnabled}
        setOptions={{
          enableLiveAutocompletion: input.enableLiveAutocompletion,
          showLineNumbers: input.showLineNumbers,
          tabSize: input.tabSize,
          showPrintMargin: input.showPrintMargin,
        }}
        commands={input.preventNewLine ? [{
          name: 'commandName', 
          bindKey: {win: 'Enter', mac: 'Enter'}, 
          exec: () => {}
        }] : []}
        editorProps={{ $blockScrolling: true }}
        onChange={this.setField(input)}
        width='auto'
        height={input.height || '350px'}
      />
    );
  }

  constructTextArea(input) {
    input.isTextArea = true;

    return this.constructText(input);
  }

  constructTagsInput(input) {
    if (input.newTag) {
      return (
        <TagsInput
          onChange={this.setField(input)}
          options={input.options}
          placeholder={input.placeholder}
          selected={input.selected}
          paginate={input.paginate}
          disabled={input.disabled}
          minLength={input.minLength || 0}
          id={input.id} 
          labelKey={input.labelKey || 'label'}
        />
      );
    }

    return (      
      <OldTagsInput
        value={this.state[input.keyword]}
        onChange={this.setField(input)}
        addOnBlur
        addOnPaste
        validationRegex={input.validationRegex}
        addKeys= {[9, 13, 32]}
        pasteSplit={(tags) => tags.split(new RegExp(input.pasteSeparators.join('|'))).map(tag => tag.trim())}
        inputProps={{placeholder: input.placeholder}}
        onValidationReject={this.onTagValidationReject(input)}
      />
    );
  }

  constructResolutionInput(input) {
    const resolution = {
      interval: input.resolutionInterval,
      unit: input.resolutionUnit,
    };

    return(
      <ResolutionInput 
        resolution={resolution}
        onResolutionChange={this.setField(input)}
        showAutoResolution={input.showAutoResolution}
        placeholder={input.placeholder}
        stopValidation={input.stopValidation}
        resolutionOptions={input.resolutionOptions}
        disableComponent={input.disabled}
      />
    );
  }

  constructFileUpload(input){
    const image = input.fileType === 'image' ? <img className='uploadedImage ev-auto-uploadedImage' src={this.state[input.keyword]} /> : null;
    const fileExtension = input.fileType ===  'image' ? 'image/*' : (input.fileType === 'zip' ? '.zip' : '.csv');
    
    return(
      <Fragment>      
        <InputGroup>
          {input.fileplaceholder ? (
            <div className="form-control placeholder-input">
              {input.fileplaceholder}
            </div>
          ) : (
            <FormControl type="text" className="pointer-none" value={this.state[input.name]} />
          )}
          <InputGroup.Addon className="addonReset">
            <label className="upload-btn-wrapper btn btn-primary">
              Browse
              <input 
                type="file"
                onChange={this.uploadFile(input)} 
                accept={fileExtension} 
              />
            </label>
          </InputGroup.Addon>
          <InputGroup.Addon className="addonReset">
            <TooltippedButton 
              bsStyle='primary' 
              className='removeUploaded'
              tooltip='clear'
              onClick={() => this.clearUploadedFile(input)}
            >
              <i className={Icons.close}></i>
            </TooltippedButton>
          </InputGroup.Addon>
        </InputGroup>
        {image}
      </Fragment>
    );
  }

  constructRadio(input) {
    const options = input.options.map(option => {
      return <Radio 
        className={`${input.children && !input.inLineRadio ? 'col-xs-12' : 'col-md-4 col-xs-6'}  ${input.radioClass || ''}`} 
        id={option.label} 
        label={option.label} 
        key={option.value}
        card={option.card}
        value={option.value} 
        checked={this.state[input.keyword] === option.value} 
        disabled={input.disabled}
        onChange={this.setField(input)} 
      />;
    });

    return(
      <Row>
        <RadioGroup className={`${input.radioGroupClassName || ''}`}>
          {options}
        </RadioGroup>
      </Row>
    ); 
  }

  constructCheckbox(input) {
    return(
      <Checkbox
        checked={this.state[input.keyword]}
        disabled={input.disabled}
        bsClass='checkbox-container'
        onChange={this.setField(input)}
      />
    );
  }

  constructToggle(input) {
    return(
      <Switch on={this.state[input.keyword]}
        enabled={!input.disabled}
        onClick={this.setField(input)}>
        <div className='handle'>
          {this.state[input.keyword] ? input.onText : input.offText}
        </div>
      </Switch>
    );
  }

  constructMultipleSelect(input) {
    const options = input.groupedOptions || wrapArrayInToSelectOptions(input.options, 
      'value', 'value', 'text');

    return( 
      <MultipleSelect
        key={input.reactKey}
        className='input-text'
        mainClassName={input.mainClassName}
        disabled={input.disabled}
        value={this.state[input.keyword]}
        placeholder={input.placeholder}
        multiple={input.multiple}
        options={options}
        onClose={this.onBlurValidation(input)}
        onChange={this.setField(input)}
        selectAll={input.selectAll}
        alwaysUpdate={input.alwaysUpdate}
      />
    );
  }

  constructItemsList(input){
    return(
      <ItemsListComponent
        userGroups={input.userGroups}
        changeGroupRight={input.changeGroupRight}
        removeGroup={input.removeGroup}
      />
    );
  }

  constructSharingCircle(input){
    return( 
      <GroupSharing
        groupSharingObject={input.groupSharingObject}
        suggestions={input.suggestions}
        editableSharedGroupsKeyword={input.editableSharedGroupsKeyword}
        viewableSharedGroupsKeyword={input.viewableSharedGroupsKeyword}
        notifyGroupsKeyword={input.notifyGroupsKeyword}
        notificationMessageKeyword={input.notificationMessageKeyword}
        onChangeGroupSharing={input.onChangeGroupSharing}
        caller={input.caller}
        disableNotifingUsers={input.disableNotifingUsers}
      />
    );
  }

  constructSeparator(){
    return <div className='horizontal-divider' />;
  }

  constructCheckboxGroup = (input) => {
    const checkboxes = input.options.map(option => {
      option.type = input.type;
      option.parent = input;
      option.validation = input.validation;

      return(
        <Row>
          <Col lg={12}>
            <Checkbox
              checked={this.state[option.keyword]}
              label={option.label}
              disabled={input.disabled || option.disabled}
              bsClass='checkbox-container'
              onChange={this.setField(option)}
            />
          </Col>
        </Row>
      );
    });

    return checkboxes;
  }

  constructDatePicker(input){
    return (
      <DatePicker
        timePicker={!input.dateOnly}
        timePicker24Hour={!input.dateOnly}
        onChange={this.setField(input)}
        date={input.initialValue}
        placeholder={input.placeholder}
      />
    );
  }

  constructDateRangePicker(input){
    return (
      <DateRangePicker
        timePicker
        timePicker24Hour
        noCaret={!input.hasCaret}
        onChange={this.setField(input)}
        from={this.state[input.keyword].from}
        to={this.state[input.keyword].to}
        relativeFrom={this.state[input.keyword].relativeFrom}
        relativeTo={this.state[input.keyword].relativeTo}
        relativeDate={this.state[input.keyword].relativeDate}
        relativeDateMode={input.relativeDateMode}
        placeholder={input.placeholder}
        containerClassName={`${input.mandatoryFilter ? '' : 'optionalFilter'} ${input.containerClassName || '' }`}
        showRangeLabels
        hoverDirection={input.hoverDirection}
        disabled={input.disabled}
        opens={input.opens}
        noRanges={input.noRanges}
        rootClassName={input.rootClassName}
      />
    );
  }

  constructNumber(input){
	const Component = input.newComponent ? NumericInputComponent : NumericInput;
	 
    return (
      <Component
        label={input.label}
        min={input.min}
        max={input.max}
        step={input.step}
        placeholder={input.placeholder}
        disabled={input.disabled}
        value={this.state[input.keyword]}
        allowEmptyEntry
        pureInput
        validationMessage={this.state.validation[input.keyword] ?
          this.state.validation[input.keyword].label : null}
        onChange={this.setField(input)}
        onBlur={this.onBlurValidation(input)}
        suffixLabel={input.suffixLabel}
      />
    );
  }

  setTreeRef(ref){
    this.commonTreeWrapper = ref;
  }

  constructTree(input){
    return (
      <div className='constructTreeWrapper'>
        <CommonTreeWrapper
          key={input.keyword}
          ref={ input.setTreeRef || this.setTreeRef}
          entityOptions={input.entityOptions}
          updateSelection={this.setField(input)}
          // onSelect={this.setField(input)}
          canClick={input.canClickNode}
          selection={this.state[input.keyword]}
          {...input.treeActions}
          />
      </div>
    );
  }

  setTreeOutput= (keyword) => {
    const isValid = this.commonTreeWrapper.saveSelection();

    if(isValid){
      this.closePopupInputModal(keyword)();
    }
  }
  
  constructTreeSelectorPopup(input){
    if(input.showTreeViewModel){
      return this.constructTreeViewItem(input, [input.treeInput], this.setTreeOutput );
    }
    
    return this.constructPopupInput(input.popupInput, [input.treeInput], this.setTreeOutput);
  }

  getPopupInputModel(input, modelInputs, savePopupInputModel){
    return(
      <Modal bsSize="large"
          className='modal-height-50'
          onHide={this.closePopupInputModal(input.keyword)}
          footerButtons={[
              ButtonsConstructor.getButtonComponent({
                className: 'model_tree_select',
                onClick: () => savePopupInputModel(input),
                disabled: input.disableSaving,
                bsStyle: 'primary'}, 'Select')
          ]}
        show={this.state[`${input.keyword}showPopupInputModal`]}
          title={input.title}>
        {
          modelInputs.map((input) => {
            return this.constructInput(input);
          })
        }
      </Modal>
    );
  }

  constructTreeViewItem(input, modelInputs, savePopupInputModel){
    const onSave = () => {
      if(savePopupInputModel){
        savePopupInputModel(input.keyword);
      } else {
        input.savePopupInputModel();
        this.closePopupInputModal(input.keyword)();
      }      
    };

    return(
      <Fragment>
        <div className="keyFilter KeyFilterStyle" onClick={input.disabled ? null : this.openPopupInputModal(input.keyword)}>
          <TreeItemView
            selection={input.initialValue || {}}
            openSelection={() => this.openPopupInputModal(input.keyword)}
            componentPlaceholder={input.placeholder}
            disableComponent={input.disabled}
            maxShownSelections={input.maxShownSelections || 1}/>
        </div>
        {
          this.state[`${input.keyword}showPopupInputModal`] ?
            this.getPopupInputModel(input, modelInputs || input.modelInputs, onSave) : null
        }
      </Fragment>
    );
  }
  
  constructPopupInput(input, modelInputs, savePopupInputModel){
    const onSave = () => {
      if(savePopupInputModel){
        savePopupInputModel(input.keyword);
      } else {
        input.savePopupInputModel();
        this.closePopupInputModal(input.keyword)();
      }      
    };
   
    return (<Fragment>
      <div className="keyFilter KeyFilterStyle" onClick={input.disabled ? null : this.openPopupInputModal(input.keyword)}>
        <OverlayTrigger shouldUpdatePosition
          rootClose placement="bottom" trigger='hover'
          overlay={
            <Tooltip placement="bottom"
              className={this.state[input.keyword] ? 'in' : 'hidden'}>
              {this.getDisplayValue(input)}
            </Tooltip>}>
            <span className='displayVal'>
            {this.getDisplayValue(input)}
          </span>
        </OverlayTrigger>
        {ButtonsConstructor.getButtonComponent({
          className: 'selectAttrPath-btn tree-btn filterSelectNE-btn btn btn-default',
          disabled: input.disabled,
          onClick: this.openPopupInputModal(input.keyword),
          bsStyle: ''},
          <i className={input.popupIcon}></i>)}
      </div>
      {
        this.state[`${input.keyword}showPopupInputModal`] ?
          this.getPopupInputModel(input, modelInputs || input.modelInputs, onSave) : null
      }
      
      </Fragment>);
  }
  
  constructInputGroup = (input) => {
    return <Fragment></Fragment>;
  }

}