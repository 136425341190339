/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from "react";
import { Col, Row } from 'react-bootstrap';
import {Icons} from 'react-template';

export default function ExternalPageAlert(props) {
  return (
    <Row>
      <Col xs={12}>
        <div className={`alert ${props.isError ? 'alert-danger' : 'alert-success'}`} >
            <div className="alertHeading ev-auto-alertHeading"> 
              <i className={`alertIcon ev-auto-alertIcon ${props.isError ? Icons.close : Icons.checkMark}`}></i>
              <p className="alertTitle ev-auto-alertTitle">{props.title}</p>
            </div>
            <p className="alertBody ev-auto-alertBody">{props.message}</p>
        </div>
      </Col>
    </Row>
  );
}