/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as AutomationWorkflowAPI from 'api/AutomationWorkflowAPI';

export const saveWorkflow = (data) => {
  return AutomationWorkflowAPI.saveAutomationWorkflow(data);
};

export const checkWorkflowNameExists = (workflowName) => {
  return AutomationWorkflowAPI.checkWorkflowNameExists(workflowName);
};

export const getAutomationWorkflow = (workflowId) => {
  return AutomationWorkflowAPI.getAutomationWorkflow(workflowId);
};

export const initiateAutomationWorkflowTable = () => {
  return AutomationWorkflowAPI.initiateAutomationWorkflowTable();
};

export const deleteAutomationWorkflow = (workflowId) => {
  return AutomationWorkflowAPI.deleteAutomationWorkflow(workflowId);
};

export const getAutomationWorkflowList = (workflowId) => {
  return AutomationWorkflowAPI.getAutomationWorkflowList(workflowId);
};

export const checkAutomationWorkflowActivation = (tId) => {
  return AutomationWorkflowAPI.checkAutomationWorkflowActivation(tId);
};

export const resumeAutomationWorkflowAction = (pId, tId, lastStage) => {
  return AutomationWorkflowAPI.resumeAutomationWorkflowAction(pId, tId, lastStage);
};

export const fireAutomationWorkflow = (data) => {
  return AutomationWorkflowAPI.fireAutomationWorkflow(data);
};

export const getAutomationWorkflowParameters = (workflowId) => {
  return AutomationWorkflowAPI.getAutomationWorkflowParameters(workflowId);
};


