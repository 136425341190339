/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';
const BASE_URL = 'admin/automationWorkflow/';
import axios from 'axios';

export const saveAutomationWorkflow = (data) => {
  return axios.post(BASE_URL, data);
}

export const getAutomationWorkflow = (id) => 
  request(BASE_URL, {
    method: 'GET',
    data: {id}
  });

export const listWorkflowAttributeTypes = () => request(`${BASE_URL}workflowAttributeTypes`);

export const checkWorkflowNameExists = (name) => 
  request (BASE_URL + 'checkWorkflowNameExists', {
    method: 'GET',
    data: {name}
  });

export const getAutomationWorkflowList = (query) => 
  request(BASE_URL + 'tableData', {
    method: 'POST',
    data: query
  });

export const initiateAutomationWorkflowTable = () => 
  request(BASE_URL + 'tableData/initiate', {
    method: 'GET',
  });

export const deleteAutomationWorkflow = (id) => 
  request(`${BASE_URL}${id}`, {
    method: 'DELETE',
  });

export const checkAutomationWorkflowActivation = (tId) => 
  request(BASE_URL + 'checkAutomationWorkflowActivation', {
    data: {tId},
    method: 'GET',
  }); 

export const resumeAutomationWorkflowAction = (processId, taskId, lastStage) => 
  request(BASE_URL + 'resumeWorkflowAction', {
    data: {
      pId: processId,
      tId: taskId,
      lastStage,
    },
    method: 'GET',
  });

export const fireAutomationWorkflow = (data) => {
  const url = `${BASE_URL}fireAutomationWorkflow`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(url, data , config);  
};

export const getAutomationWorkflowParameters = (id) => 
  request(BASE_URL + 'getAutomationWorkflowParameters', {
    method: 'GET',
    data: {
      id,
    }
  });


