/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import TooltippedButton from 'buttons/TooltippedButton';
import Icons from './Icons';
import 'styles/templates/helpHeader.less';

const DEFAULT_BTN_SIZE = 40;

export default class HelpHeader extends Component{
  state = {
    shown : false,
  }

  render(){
    const calcualtedWidth = `calc(100% - ${this.props.btnNumber * ( this.props.btnSize || DEFAULT_BTN_SIZE)}px)`;
    const width = this.props.buttonsWidth ? `calc(100% - ${this.props.buttonsWidth})` : calcualtedWidth;
    
    return(
      <div>
        <div className={`row helpHeader ${this.props.className || '' }`}>
          <div className="col-xs-12">
            <div className='horizontal-divider'>
              {this.props.backFn ?
                <TooltippedButton className={`${this.props.className || '' }-backBtn btn-action`}
                  tooltip={this.props.backTooltip || 'Back' }
                  onClick={this.props.backFn}>
                  <i className={Icons.arrowLeft}></i>
                </TooltippedButton>: null
              }
              <div className='title-wrapper' style={{width}}>
                <h5 className="headerTitle">
                  {this.props.label}
                  {this.props.help ?
                    <TooltippedButton className={`btn-action ${this.props.className || '' }-helpBtn`}
                      tooltip='Help'
                      onClick={() => {
                        this.setState({
                          shown:!this.state.shown,
                        });
                      }}>
                      <i className={Icons.helpCircle}></i>
                    </TooltippedButton> : null
                  }
                  {this.props.collapse ?
                    <TooltippedButton className={`btn-action ${this.props.className || '' }-helpBtn`}
                      tooltip='Collapse'
                      onClick={this.props.collapse}
                    >
                      <i className={Icons.filter}></i>
                    </TooltippedButton>
                    : null
                  }
                </h5>
                {this.state.shown ?
                  <div className='help-text'>
                    {this.props.help}
                  </div> : ''
                }
              </div>
              <div className="headerActions">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
        {
          this.props.showWarning ? 
            <div className='pageWarning'>
              <span className='warningMessage'>
                <i className={`warningIcon ${Icons.info}`}></i>
                <span className='warningText'>{this.props.warningMessage}</span>
              </span>
            </div> : null
        }
      </div>
    );
  }
}
