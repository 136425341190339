/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {ErrorPage} from 'react-template';
import AnalystActivation from 'components/pages/Events/AnalystActivation';
import AutomationWorkflowActivation from 'components/pages/AutomationWorkflow/AutomationWorkflowActivation';
import UserProfile from 'components/pages/UserProfile/UserProfile';
import UserResetPassword from 'components/pages/UserProfile/ResetPassword';
import * as WFPDefaults from 'config/PermissionsDefaults';

import Login from 'components/pages/Login/Login';
import ForgetPassword from 'components/pages/Login/ForgetPassword';
import ResetPassword from 'components/pages/Login/ResetPassword';
import UserRegistration from 'components/pages/Login/UserRegistration';

export const BASIC_ROUTES = [
  {
    path: 'event/analystSelfHealingActionActivation',
    component: AnalystActivation,
  },
  {
    path: 'workflow/workflowActionActivation',
    component: AutomationWorkflowActivation,
  },
  {
    path: 'error/:error',
    component: ErrorPage,
  },
  {
    path: 'userProfile',
    component: UserProfile,
  },
  {
    path: 'userProfile/resetPassword',
    component: UserResetPassword,
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

export const LOGIN_ROUTES = (expired) => [
  {
    path: '/forgetpassword',
    component: () => <ForgetPassword/>,
  },
  {
    path: '/resetpassword',
    component: () => <ResetPassword/>,
  },
  {
    path: '/userRegistration',
    component: () => <UserRegistration/>,
  },
  {
    path: '/error',
    component: () => <ErrorPage params={{error: 1001}}/>,
  },
  {
    path: '*',
    indexRoute:  undefined,
    component: () => <Login expired={expired}/>,
  }];

export const PAGES = {
  COLLECTION_METHOD : 'collectionMethods',
  SET_COLLECTION_METHOD: 'setCollectionMethod',
  IMPORT_COLLECTION_METHOD: 'importCollectionMethod',
};

export const MAIN_ROUTES = {
  HOMEPAGE: 'admin/homepage',
  USER_MANAGEMENT: 'admin/UserManagement',
  SET_USER: 'admin/userManagement/setUser',
  INVITE_USERS: 'admin/userManagement/inviteUsers',
  NBI_APPLICATION_CLIENTS_MANAGEMENT: 'admin/ApplicationClientManagement',
  SET_NBI_APPLICATION_CLIENT: 'admin/applicationClientManagement/setClient',
  COLLECTION_PROFILES: 'admin/collectionProfiles',
  SET_COLLECTION_PROFILES: 'admin/collectionProfiles/setCollection',
  SYNTHETIC_COLLECTION_PROFILES: 'admin/syntheticCollectionProfiles',
  SET_SYNTHETIC_COLLECTION_PROFILES: 'admin/syntheticCollectionProfiles/setSyntheticCollection',
  CONFIGURATION_COLLECTION_PROFILES: 'admin/configurationCollectionProfiles',
  SET_CONFIGURATION_COLLECTION_PROFILES: 'admin/configurationCollectionProfiles/setConfigurationCollection',
  SQL_COLLECTION_PROFILES: 'admin/sqlCollectionProfiles',
  SET_SQL_COLLECTION_PROFILES: 'admin/sqlCollectionProfiles/setSQLCollection',
  '3RDPARTYNMSS': 'admin/3rdPartyNMSs',
  ADD_NMS: 'admin/3rdPartyNMSs/addNMS',
  EDIT_PATHS: 'admin/3rdPartyNMSs/editPaths',
  FILE_PATTERNS: 'admin/filePatterns',
  FILES: 'admin/files',
  NETWORK_COLLECTOR: 'admin/networkCollector',
  ADD_NETWORK_COLLECTOR: 'admin/networkCollector/addNetworkCollector',
  NETWORK_COLLECTOR_FILEMAPPING: 'admin/networkCollector/filemapping',
  NETWORK_AUDITOR: 'admin/networkAuditor',
  ADD_NETWORK_AUDITOR: 'admin/networkAuditor/addNetworkAuditor',
  NETWORK_AUDITOR_FILEMAPPING: 'admin/networkAuditor/filemapping',
  EVENT_LOGS: 'admin/logs/eventLogs',
  USER_LOGS: 'admin/logs/userLogs',
  GROUP_MANAGEMENT: 'admin/groupManagement',
  SET_GROUP: 'admin/groupManagement/setGroup',
  MANAGE_OWNERSHIP: 'admin/manageOwnership',
  ADMIN_DEFINED: 'admin/adminDefined',
  NETWORK_ELEMENT_MANAGEMENT: 'admin/networkElementManagement',
  CONFIGURE_NETWORK_ELEMENT: 'admin/networkElementManagement/configureNetworkElement',
  EVENTS: 'admin/events',
  SET_EVENT: 'admin/events/setEvent',
  AUTOMATION_RUNS_INFO: 'admin/events/automationRunsInfo',
  TRIGGERED_EVENTS: 'admin/triggeredEvents',
  TAGS:'admin/tags',
  ADD_TAG:'admin/tags/addTag',
  LICENSE: 'admin/license',
  LOOKUP: 'admin/lookup',
  ADD_LOOKUP_TABLE: 'admin/lookup/addLookupTable',
  EVENT_HEALING: 'admin/eventHealing',
  ADD_EVENT_HEALING: 'admin/eventHealing/AddEventHealing',
  COLLECTION_METHOD_MANAGEMENT: `admin/${PAGES.COLLECTION_METHOD}`,
  DATA_BINDING_MANAGEMENT: 'admin/dataBindingManagement',
  SYNTHETIC_BINDING_MANAGEMENT: 'admin/syntheticBindingManagement',
  CONFIGURATION_BINDING_MANAGEMENT: 'admin/configurationBindingManagement',
  IMPORT_CONFIGURATION_BINDING: 'admin/configurationBindingManagement/importConfigurationBinding',
  SQL_BINDING_MANAGEMENT: 'admin/sqlBindingManagement',
  IMPORT_SQL_BINDING: 'admin/sqlBindingManagement/importSqlBinding',
  IMPORT_DATA_BINDING: 'admin/dataBindingManagement/importDataBinding',
  IMPORT_SYNTHETIC_BINDING: 'admin/syntheticBindingManagement/importSyntheticBinding',
  SET_COLLECTION_METHOD: `admin/${PAGES.COLLECTION_METHOD}/${PAGES.SET_COLLECTION_METHOD}`,
  IMPORT_COLLECTION_METHOD: `admin/${PAGES.COLLECTION_METHOD}/${PAGES.IMPORT_COLLECTION_METHOD}`,
  SET_DATA_BINDING: 'admin/dataBindingManagement/setDataBinding',
  SET_CONFIGURATION_BINDING: 'admin/configurationBindingManagement/setConfigurationBinding',
  SET_SYNTHETIC_BINDING: 'admin/syntheticBindingManagement/setSyntheticBinding',
  SET_SQL_BINDING: 'admin/sqlBindingManagement/setSqlBinding',
  PUSH_BASED_DATA_BINDING_MAPPING_MANAGEMENT: 'admin/PushBasedDataBindingMappingManagement',
  SET_PUSH_BASED_DATA_BINDING_MAPPING: 'admin/PushBasedDataBindingMappingManagement/SetPushBasedDataBindingMapping',
  PUSH_BASED_DATA_BINDING_MAPPING :'admin/PushBasedDataBindingMappingManagement/SetPushBasedDataBindingMapping/PushBasedDataBindingMapping',
  FGA: 'admin/fga',
  ADD_FGA: 'admin/fga/addFga',
  TEMPLATES_PLACEHOLDERS: 'admin/templatesPlaceholders',
  SET_TEMPLATES_PLACEHOLDER: 'admin/templatesPlaceholders/setTemplatesPlaceholder',
  NBI: 'admin/nbi',
  SET_NBI: 'admin/nbi/setNBI',
  DESIGN_SPACE_ORGANIZER: 'admin/designSpaceOrganizer',
  RECORDS_MANAGEMENT: 'admin/recordsManagement',
  SET_RECORD: 'admin/recordsManagement/setRecord',
  AUDITING_TEMPLATES_MANAGEMENT: 'admin/auditingTemplatesManagement',
  SET_AUDITING_TEMPLATE: 'admin/auditingTemplatesManagement/setAuditingTemplate',
  ROLLUP_PLANS_MANAGEMENT: 'admin/rollUpPlansManagement',
  SET_ROLLUP_PLAN: 'admin/rollUpPlansManagement/setRollUpPlan',
  RETENTION_PLANS_MANAGEMENT:'admin/lakehouseRetentionPlansManagement',
  SET_LAKEHOUSE_RETENTION_PLAN: 'admin/lakehouseRetentionPlansManagement/setLakehouseRetentionPlan',
  DATA_MODEL_PATH: 'admin/dataModelTree',
  SET_DATA_MODEL_ATTRIBUTE_PATH: 'admin/dataModelTree/setAttribute',
  SET_DATA_MODEL_CONTAINER_PATH: 'admin/dataModelTree/setContainer',
  SET_DATA_MODEL_LIST_PATH: 'admin/dataModelTree/setList',
  ORGANIZATION_GROUP_MANAGEMENT: 'admin/organizationGroupManagement',
  SET_ORGANIZATION_GROUP: 'admin/organizationGroupManagement/setOrganizationGroup',
  ROUTING_GROUPS_MANAGEMENT: `admin/${WFPDefaults.ROUTING_GROUP_KEY}`,
  SET_ROUTING_GROUP: `admin/${WFPDefaults.ROUTING_GROUP_KEY}/setRoutingGroup`,
  DATA_FLOWS_MANAGEMENT: `admin/${WFPDefaults.DATA_FLOW_KEY}`,
  SET_DATA_FLOW: `admin/${WFPDefaults.DATA_FLOW_KEY}/SetDataFlow`,
  NAVIGATION_PAGE: 'reporter/navigationPage',
  DASHBOARD: 'reporter/dashboard',
  DASHBOARD_CONFIG: 'reporter/dashboard/dashboardConfig',
  IMPORT_DASHBOARDS: 'reporter/dashboard/importDashboards',
  MANAGE_DASHBOARDS: 'reporter/dashboard/manageDashboards',
  DASHBOARD_ASSOCIATE: 'reporter/dashboard/associate',
  REPORT: 'reporter/report',
  MANAGE_REPORTS: 'reporter/report/manageReports',
  REPORT_CONFIG: 'reporter/report/reportConfig',
  IMPORT_REPORTS: 'reporter/report/importReports',
  REPORT_ASSOCIATE: 'reporter/report/associate',
  DOWNLOAD_REPORT: 'reporter/report/downloadReport',
  ANALYSIS: 'reporter/report/Analysis',
  SCHEDULE_REPORTS: 'reporter/scheduleReports',
  ADD_SCHEDULED_REPORT: 'reporter/scheduleReports/AddScheduledReport',
  HISTORICAL_EXPORTS: 'reporter/scheduleReports/historicalExports',
  FORECASTING: 'reporter/forecasting',
  ML_JOBS: 'reporter/mlJobs',
  SET_ML_JOB: 'reporter/mlJobs/setMLJob',
  EXPORT_FLOWS: 'reporter/exportFlows',
  REPORTER_ERROR: 'reporter/error',
  ADMIN_ERROR: 'error',
  ADMIN: 'admin',
  REPORTER: 'reporter',
  UNREACHABILITY_PLAN_MANAGEMENT: `admin/${WFPDefaults.UNREACHABILITY_PLAN_KEY}`,
  SET_UNREACHABILITY_PLAN: `admin/${WFPDefaults.UNREACHABILITY_PLAN_KEY}/setUnreachabilityPlan`,
  CP_BLOCK_LIST: `admin/${WFPDefaults.CP_BLOCK_LIST}`,
  AUTOMATION_WORKFLOWS: 'admin/automationWorkflow',
  ADD_AUTOMATION_WORKFLOW: 'admin/automationWorkflow/addAutomationWorkflow',
  ATTRIBUTE_TYPES: 'admin/attributeTypes',
  ADD_ATTRIBUTE_TYPE: 'admin/attributeTypes/addAttributeType',
  LDAP_CONFIG_MANAGEMENT: 'admin/LDAPConfigManagement',
  SET_LDAP_CONFIG: 'admin/LDAPConfigManagement/SetLDAPConfig',
  CONFIGURATION_LISTING: `reporter/${WFPDefaults.CONFIGURATION_LISTING}`,
  DIFF_VIEWER: `reporter/${WFPDefaults.CONFIGURATION_DIFF_VIEWER}`,
  CONFIGURATION_FILE_VIEW: `reporter/${WFPDefaults.CONFIGURATION_LISTING}/${WFPDefaults.CONFIGURATION_VIEWER}`,
  FILE_EXPORT: 'reporter/fileExport',
  SET_FILE_EXPORT: 'reporter/fileExport/setFileExport',
  SCHEDULED_FILE_EXPORTS: 'reporter/ScheduledFileExports',
  ADD_SCHEDULED_FILE_EXPORT: 'reporter/ScheduledFileExports/addScheduledFileExport',
  OTEL: `reporter/${WFPDefaults.OTEL}`,
  OTEL_TRACE: `reporter/${WFPDefaults.OTEL}/${WFPDefaults.TRACE}`,
  ADD_CUSTOM_ANALYTICS: 'admin/customAnalytics/addCustomAnalyticsProject',
  CUSTOM_ANALYTICS: 'admin/customAnalytics',
  ANALYSIS_JOBS_MANAGEMENT: 'admin/analyticsJobsManagement',
  SET_ANALYSIS_JOBS: 'admin/analyticsJobsManagement/setAnalyticsJob',
  ANALYTICS_RUNS_INFO: 'admin/customAnalytics/analyticsRunsInfo',
  INCIDENTS: 'admin/incidents',
  SET_INCIDENT: 'admin/incidents/setIncident',
  INCIDENT_EXPLORER: `admin/${WFPDefaults.INCIDENT_EXPLORER}`,
  IMPORT_INCIDENT:'admin/incidents/importIncident',
};
