/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as AggregationUtils from '../AggregationUtils';
import * as DateTimeUtils from '../DateTimeUtils';
import * as UnitConversionUtils from '../UnitConversionUtils';
import * as FieldTypes from '../types/FieldTypes';
import * as ColorPalette from '../colorPalette/ColorPalette';
import * as WidgetTypes from '../types/WidgetTypes';

const NETWORK_ELEMENT = FieldTypes.NETWORK_ELEMENT;
const ATTRIBUTE = DateTimeUtils.ATTRIBUTE;
const DATE_FIELD_NAME = FieldTypes.DATE_FIELD_NAME;
const DATE_FIELD_LABEL = 'Date';
const DEFAULT_PERCENTILE_VALUE = AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE;
const VALUE_FIELD = 'VALUE';
const HORIZONTAL = 'horizontal';

export const VERTICAL = 'vertical';

const DESC = 'DESC';

const DEFAULT_N_VALUE = 10;
const DEFAULT_N_DIRECTION = DESC;

const legendDirectionDefaults = {
  [VERTICAL]: 'right',
  [HORIZONTAL]: 'bottom',
};

const legendLabelWidthDefaults = {
  [VERTICAL]: null,
  [HORIZONTAL]: 150,
};

const LINE_CHART_DEFAULTS = {
  MIN_LINE_THICKNESS: 1,
  MAX_LINE_THICKNESS: 8,
  DEFAULT_LINE_THICKNESS: 2,
  DEFAULT_STROKE_TYPE: 'SOLID',
};

const chartWidgetDefaults = {
  HORIZONTAL,
  VERTICAL,
  legendDirectionDefaults,
  legendLabelWidthDefaults,
};

export const DEFAULT_FONT_SIZE_FORMAT = 12;
const DEFAULT_TXT_ORIENTATION = 315;

export const FGA_TYPES = {
  MAIN_FGA: 'MAIN',
  NODE_TOOLTIP: 'NODE_TOOLTIP',
  LINK_LABEL: 'LINK_LABEL',
  LINK_DRILLDOWN: 'LINK_DRILLDOWN',
  NODE_DRILLDOWN: 'NODE_DRILLDOWN',
  OVERLAY_TOOLTIP: 'OVERLAY_TOOLTIP',
  OVERLAY_DRILLDOWN: 'OVERLAY_DRILLDOWN',
};

export function getDefaultChartOptions(colorPaletteType, color) {
  const palleteColor = color || ColorPalette.getMainColor(colorPaletteType);

  return {
    xAxis: [],
    yAxis: ['VALUE.value'],
    chartType: 'line',
    aggregation: AggregationUtils.DEFAULT_NUMERIC_AGGREGATION,
    series: {
      VALUE: {
        color: palleteColor,
        plotOnY: true,
        seriesType: 'line',
        lineThickness: LINE_CHART_DEFAULTS.DEFAULT_LINE_THICKNESS,
        strokeType: LINE_CHART_DEFAULTS.DEFAULT_STROKE_TYPE,
      },
    },
    seriesFields: [
      ATTRIBUTE,
      NETWORK_ELEMENT,
    ],
  };
}

export function getDefaultChartFormat() {
  return {
    xAxis: {
      dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
      txtOrientation: DEFAULT_TXT_ORIENTATION,
    },
    yAxis: {
      precision: UnitConversionUtils.DEFAULT_PRECISION,
    },
    y2Axis: {
      precision: UnitConversionUtils.DEFAULT_PRECISION,
    },
    showLegend: true,
    horizontalBar: false,
    groupBar: false,
    dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
    barWidth: null,
    fontSize: DEFAULT_FONT_SIZE_FORMAT,
    showGridlines: true,
    showDataOnBar: false,
    legendPosition: chartWidgetDefaults.legendDirectionDefaults[chartWidgetDefaults.VERTICAL],
    direction: chartWidgetDefaults.VERTICAL,
    labelWidth: chartWidgetDefaults.legendLabelWidthDefaults[chartWidgetDefaults.VERTICAL],
  };
}


export function getDefaultMLAnomalyChartOptions() {
  return {
    xAxis: [DATE_FIELD_NAME],
    yAxis: [],
    chartType: 'line',
    series: {},
  };
}

export function getDefaultMLAnomalyChartFormat() {
  return {
    xAxis: {
      dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
      txtOrientation : DEFAULT_TXT_ORIENTATION,
    },
    yAxis: {
      precision: UnitConversionUtils.DEFAULT_PRECISION,
    },
    showExpectedLine: true,
    showBands: true,
    showLegend: false,
    dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
    fontSize: DEFAULT_FONT_SIZE_FORMAT,
    showGridlines: true,
    legendPosition: chartWidgetDefaults.legendDirectionDefaults[chartWidgetDefaults.VERTICAL],
    direction: chartWidgetDefaults.VERTICAL,
    labelWidth: chartWidgetDefaults.legendLabelWidthDefaults[chartWidgetDefaults.VERTICAL],
  };
}

export function getTOPNChartOptions(colorPaletteType){
  const palleteColor = ColorPalette.getMainColor(colorPaletteType);

  return {
    xAxis: [],
    yAxis:['VALUE.value'],
    chartType: 'rect',
    aggregation: AggregationUtils.DEFAULT_NUMERIC_AGGREGATION,
    series: {
      VALUE: {
        color: palleteColor,
        plotOnY: true,
        seriesType: 'rect',
        lineThickness: LINE_CHART_DEFAULTS.DEFAULT_LINE_THICKNESS,
        strokeType: LINE_CHART_DEFAULTS.DEFAULT_STROKE_TYPE,
      },
    },
    seriesFields: [ ],
    n: DEFAULT_N_VALUE,
    nDirection: DEFAULT_N_DIRECTION,
    indicesOfRefxAxis: [],
  };
}

export function getPieChartOptions(colorPaletteType){
  const palleteColor = ColorPalette.getMainColor(colorPaletteType);

  return {
    aggregation: AggregationUtils.DEFAULT_NUMERIC_AGGREGATION,
    series: {
      VALUE: {
        color: palleteColor,
        plotOnY: true,
      },
    },
    seriesFields: [
      FieldTypes.ATTRIBUTE,
      FieldTypes.NETWORK_ELEMENT,
    ],
    chartType: WidgetTypes.WIDGET_TYPES.PIE_CHART,
    xAxis: [],
  };
}

export function convertMetricToChartOptions(options, chartType, trendLineOptions, resolutionStep) {
  const chartOptions = getDefaultChartOptions();
  const chartFormat = getDefaultChartFormat();

  chartOptions.series[VALUE_FIELD].seriesType = chartType;
  if (trendLineOptions) {
    chartOptions.series[VALUE_FIELD].color = trendLineOptions.color;
    chartOptions.isGradient = true;
  }
  chartOptions.chartType = chartType;
  chartOptions.xAxis.push(DATE_FIELD_NAME);
  chartOptions.seriesFields = [];
  chartOptions.percentileValue = options.percentileValue || DEFAULT_PERCENTILE_VALUE;
  chartOptions.unit = options.unit;
  chartOptions.aggregation = options.chartType;
  chartOptions.threshold = [];
  chartOptions.interactiveLegendData = [];
  chartOptions.resolutionStep = resolutionStep;

  chartFormat.xAxis.label = DATE_FIELD_LABEL;
  chartFormat.showLegend = false;
  chartFormat.yAxis.divider = options.unit;

  return { chartOptions, chartFormat };
}

export function getMLAnomalyBandsFields(config, header){
  const anomalyBandFields = [ FieldTypes.RESERVED_NAMES.ML_HIGH_UPPER_FIELD_NAME,
    FieldTypes.RESERVED_NAMES.ML_HIGH_LOWER_FIELD_NAME,
    FieldTypes.RESERVED_NAMES.ML_MID_UPPER_FIELD_NAME,
    FieldTypes.RESERVED_NAMES.ML_MID_LOWER_FIELD_NAME,
    FieldTypes.RESERVED_NAMES.ML_LOW_UPPER_FIELD_NAME,
    FieldTypes.RESERVED_NAMES.ML_LOW_LOWER_FIELD_NAME,]

  if(!config.chartFormat.showBands){
    header = header.filter(field => !anomalyBandFields.includes(field))
  }
  if(!config.chartFormat.showExpectedLine){
    header = header.filter(field => FieldTypes.RESERVED_NAMES.ML_ENSEMBLE_EXPECTED_FIELD_NAME != field);
  }
  
  return header;
}

export const getTableDataCroppedMessage = (count) => {
  return `Showing only first ${count} rows, sorting may not be accurate due to cropping.`;
};

export const getTableMaxAllowedSizeMessage = (count) => {
  return `Displaying only the first ${count} rows as the maximum data retrieval limit has been reached.`;
};


