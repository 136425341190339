/* Copyright 2019 Greyskies. All Rights Reserved. */

export default {
  styles: {
    evSm: ' ev-sm',
    evLg: ' ev-lg',
    ev2x: ' ev-2x',
    ev3x: ' ev-3x',
    ev4x: ' ev-4x',
    ev6x: ' ev-6x',
  },
  activate:'ev ev-activate evc-text-success',
  add: 'ev ev-add',
  addDashboard: 'ev ev-add-dashboard',
  addRecords: 'ev ev-add-records',
  adminSettings: 'ev ev-admin-settings',
  annotate: 'ev ev-annotate',
  anomlyDelection: 'ev ev-anomaly-detection',
  ansibleAutomationTask: 'ev ev-sm ev-ansible-script',
  api: 'ev ev-api',
  appAccess: 'ev ev-app-access',
  approvalEmail: 'ev ev-approval-email',
  archive: 'ev ev-archive',
  areaLayer:'ev ev-area-layer',
  arrowDown: 'ev ev-arrow-down',
  arrowUp: 'ev ev-arrow-up',
  arrowLeft: 'ev ev-arrow-left',
  arrowRight: 'ev ev-arrow-right',
  arrows: 'ev ev-arrows',
  artBoard: 'ev ev-art-board',
  assocFormat: 'ev ev-lg ev-association-format',
  assocSetting: 'ev ev-lg ev-association-settings',
  attributeBinding: 'ev ev-attr-binding',
  attributeNode: 'ev ev-attribute',
  attrTypes: 'ev ev-attribute-types',
  auditingTemplate: 'ev ev-auditing-template',
  automation: 'ev ev-automation-main',
  bambooBuild:'ev ev-bamboo-build',
  ban: 'ev ev-ban',
  barChart: 'ev ev-bar-chart',
  blockList: 'ev ev-block-list',
  boldCircle: 'ev ev-bold-circle',
  branch:'ev ev-branch',
  brush: 'ev ev-annotate',
  build:'ev ev-build',
  calendar: 'ev ev-calendar',
  caretDown: 'ev ev-caret-down',
  caretLeft: 'ev ev-caret-left',
  caretRight: 'ev ev-caret-right',
  caretUp: 'ev ev-caret-up',
  chart: 'ev ev-chart',
  checkMark: 'ev ev-check',
  checkMarkCircle: 'ev ev-check-circle',
  chevronDown: 'ev ev-chevron-down',
  chevronLeft: 'ev ev-chevron-left',
  chevronRight: 'ev ev-chevron-right',
  chevronUp: 'ev ev-chevron-up',
  circle: 'ev ev-circle',
  circularAdd: 'ev ev-plus-circle',
  clock:'ev ev-clock',
  clone: 'ev ev-clone',
  close: 'ev ev-close',
  cloudUpload: 'ev ev-cloud-upload',
  upload: 'ev ev-upload',
  collectionManagement: 'ev ev-collection-management',
  collectionMethod:'ev ev-collection-method',
  collectionProfile: 'ev ev-collection-profile',
  compress:'ev ev-compress',
  configBinding: 'ev ev-config-binding',
  configCollection: 'ev ev-config-collection',
  copy: 'ev ev-files-o',
  dash: 'ev ev-minus',
  dashboard: 'ev ev-dashboard',
  dashboardNavigation: 'ev ev-navigation',
  dashed:'ev ev-dashed',
  dataAnalysis: 'ev ev-data-analysis',
  dataAssociation: 'ev ev-lg ev-data-association',
  database: 'ev ev-database',
  dataFlow: 'ev ev-data-flow',
  dataModel: 'ev ev-data-model',
  dataModelTree: 'ev ev-data-model-tree',
  dataRetention: 'ev ev-data-retention',
  dataRollup: 'ev ev-data-rollup',
  dataWarehouse:'ev ev-data-warehouse',
  deactivate:'ev ev-deactivate',
  delete: 'ev ev-trash',
  designSettings: 'ev ev-design-settings',
  destinationAttributeNode: 'ev-destination-attr',
  diamond: 'ev ev-diamond',
  discoveryMetricBinding: 'ev ev-discovery-metric-binding',
  discoveryMetricCollection: 'ev ev-discovery-metric-collection',
  dotted:'ev ev-dotted',
  dottedLayer:'ev ev-dotted-layer',
  download: 'ev ev-download',
  downloadLicense:'ev ev-download-license',
  drilldown: 'ev ev-drilldown',
  drillUp: 'ev ev-drillup',
  dynamicResolution: 'ev ev-dynamic-resolution',
  edit: 'ev ev-pencil-square',
  emailSettings: 'ev ev-email-settings',
  error: 'ev ev-expired evc-text-danger',
  eventAutomation : 'ev ev-automation',
  eventHealing: 'ev ev-event-healing', 
  eventHealingDefault: 'ev ev-event-healing ',
  events: 'ev ev-events',
  excel: 'ev ev-exportexcel',
  exclamation: 'ev ev-expired',
  expand:'ev ev-expand',
  expired: 'ev ev-expired ev-6x evc-text-danger',
  exportCenter: 'ev ev-export-center',
  externalLink: 'ev ev-external-link',
  favorite: 'ev ev-star ev-text-warning', 
  fga: 'ev ev-fga',
  fgaSub: 'ev ev-fga-sub',
  fileDiff: 'ev ev-diff',
  fileExport: 'ev ev-file-export',
  fileSection: 'ev ev-file-section',
  fileText: 'ev ev-file-text',
  filter: 'ev ev-filter',
  folder: 'ev ev-folder-o',
  font: 'ev ev-font',
  forcasting: 'ev ev-forecasting',
  gauge: 'ev ev-gauge',
  handTool: 'ev ev-hand',
  heatMap: 'ev ev-heat-map',
  help: 'ev ev-question',
  helpCircle: 'ev ev-question',
  hide: 'ev ev-hide',
  history:'ev ev-history',
  hourGlassEnd: 'ev ev-hour-glass-end',
  iFrame: 'ev ev-iframe',
  image: 'ev ev-image',
  import: 'ev ev-import',
  info: 'ev ev-info',
  inProgress: 'ev ev-inprogress InprogressColor',
  installer:'ev ev-installer',
  invalid: 'ev ev-expired evc-text-danger',
  landingPage: 'ev ev-landingpage',
  layers: 'ev ev-layers',
  ldap: 'ev ev-ldap',
  license: 'ev ev-license',
  lineChart: 'ev ev-line-chart',
  linkDetails: 'ev ev-lg ev-link-settings',
  linkedListNode: 'ev ev-link-list',
  listNode: 'ev ev-list',
  lock: 'ev ev-lock',
  lookup: 'ev ev-lookup',
  mainEvents: 'ev ev-main-events',
  manageGroups: 'ev ev-managegroups',
  manageLandingPage: 'ev ev-manage-landingpage',
  manageUsers: 'ev ev-manageusers',
  mapIcon: 'ev ev-map',
  markDown: 'ev ev-markdown',
  marker: 'ev ev-map-marker',
  menuIcon: 'ev ev-ellipsis-v',
  metric: 'ev ev-single-value',
  mlAnomaly: 'ev ev-anomaly-detection',
  mlForcast: 'ev ev-forecasting',
  mlJobs: 'ev ev-ml-jobs',
  moreActions: 'ev ev-ellipsis-h',
  navicon: 'ev ev-navicon',
  nbi: 'ev ev-nbi',
  neAllocation: 'ev ev-ne-allocation',
  networkElement: 'ev ev-manage-network-element',
  nodeSettings: 'ev ev-lg ev-node-settings',
  notificationEmail: 'ev ev-notification-email',
  organizationGroup: 'ev ev-org-group',
  ownership:'ev ev-ownership',
  pageBreak: 'ev ev-i-cursor pgBreak',
  palette: 'ev ev-palette',
  paste: 'ev ev-paste',
  pause: 'ev ev-pause',
  pdf: 'ev ev-exportpdf',
  pencil: 'ev ev-pencil',
  permissions: 'ev ev-permissions',
  pieChart: 'ev ev-pie-chart',
  placeholder: 'ev ev-placeholder',
  play: 'ev ev-play',
  probeList: 'ev ev-probe-list',
  pullBased: 'ev ev-pull-based',
  pushBased: 'ev ev-push-based',
  rawData: 'ev ev-raw-data',
  recenter:'ev ev-crosshairs',
  reset: 'ev ev-refresh',
  refresh:'ev ev-repeat',
  report: 'ev ev-report',
  reportDesigner: 'ev ev-reportdesigner',
  reporterSettings: 'ev ev-reporter-settings',
  resetHistory:'ev ev-reset-history',
  resetPositions: 'ev ev-reset-topology',
  resetZoom: 'ev ev-reset-zoom',
  resolution: 'ev ev-lg ev-collection-aggregation',
  routingGroup: 'ev ev-routing-group',
  routingManagement: 'ev ev-routing-management',
  save: 'ev ev-save',
  scheduledFileExports: 'ev ev-schedule-file-export',
  scheduledReports: 'ev ev-scheduledreports',
  sendEmail:'ev ev-send',
  settings:'ev ev-settings',
  signOut: 'ev ev-sign-out',
  solid:'ev ev-solid',
  sourceAttributeNode: 'ev-source-attr',
  spark: 'ev ev-spark',
  spin: 'ev ev-inprogress Spin animated',
  sqlBinding: 'ev ev-sql-binding',
  sqlCollection: 'ev ev-sql-collection',
  square: 'ev ev-square',
  squareAdd: 'ev ev-plus-square',
  success: 'ev ev-success ev-6x evc-text-success',
  successDefault: 'ev ev-success evc-text-success',
  syntheticBinding: 'ev ev-synthetic-binding',
  syntheticCollection: 'ev ev-synthetic-collection',
  systemConfig: 'ev ev-system-config',
  systemLogs: 'ev ev-system-logs',
  table: 'ev ev-table',
  tags: 'ev ev-tags',
  theme: 'ev ev-theme',
  threshold: 'ev ev-lg ev-threshold',
  TimeComparisonChart: 'ev ev-time-comparison',
  topNChart: 'ev ev-top-n',
  topNTable: 'ev ev-topn-table',
  topology: 'ev ev-topology',
  topologyNode: 'ev ev-topology-list',
  transferOwnership:'ev ev-transfer-ownership',
  tree: 'ev ev-tree',
  triggeresEvents: 'ev ev-triggerd-events',
  undoReset: 'ev ev-undo',
  unFavorite: 'ev ev-star-o', 
  unifiedView: 'ev ev-unified-file',
  unLock: 'ev ev-unlock',
  unreachability: 'ev ev-unreachability',
  user: 'ev ev-user',
  userLogs: 'ev ev-user-logs',
  users:'ev ev-users',
  variableBody: 'ev ev-var-body',
  variableHeader: 'ev ev-var-header',
  version:'ev ev-version',
  view: 'ev ev-eye',
  viewGroups: 'ev ev-viewgroups',
  workflow: 'ev ev-workflow',
  workflowArrow: 'ev ev-workflow-arrow',
  zoom: 'ev ev-zoom',
  zoomIn: 'ev ev-zoom-in',
  zoomOut: 'ev ev-zoom-out',
  signal: 'ev ev-signal',
  inviteUser: 'ev ev-invite-user',
  showDeleted: 'ev ev-show-deleted',
  hideDeleted: 'ev ev-hide-deleted',
  opentelemetry: 'ev ev-opentelemetry',
  customAnalytics: 'ev ev-custom-analytics',
  search: 'ev ev-search-icon',
  analyticsJob: 'ev ev-analytics-job',
  analyticsProject: 'ev ev-analytics-project',
  pending: 'ev ev-pending',
  timeSeries: 'ev ev-retention-time-series',
  retentionBigData: 'ev ev-retention-big-data',
  severityBlocker: 'ev ev-blocker',
  severityCritical: 'ev ev-critical',
  severityMajor: 'ev ev-major',
  severityMinor: 'ev ev-minor',
  severityTrival: 'ev ev-trival',
  addIncident: 'ev ev-add-incident',
  incidentsExplorer: 'ev ev-incidents-explorer',
  incidents: 'ev ev-incidents',
};
