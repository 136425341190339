/* Copyright 2019 Greyskies. All Rights Reserved. */
import * as MsgDefaults from './MessagingAndTitlesDefaults';

export const MIN = 'MIN';
export const MAX = 'MAX';
export const AVERAGE = 'AVERAGE';
export const SUM = 'SUM';
export const PRODUCT = 'PRODUCT';
export const WEIGHTED_AVG = 'WEIGHTED_AVG';
export const NTH_PERCENTILE = 'NTH_PERCENTILE';
export const NTH_PERCENTILE_DEFAULT_VALUE = 95;
export const NON_NUMERIC_AGGREGATION_DEFAULT = 'LATEST';
export const UNION = 'UNION';
export const INTERSECT = 'INTERSECT';
export const DEFAULT_NUMERIC_AGGREGATION = MAX;
export const NUMERIC = 'NUMERIC';
export const NONNUMERIC = 'NONNUMERIC';
export const MIXED = 'MIXED';
export const LIST = 'LIST';
export const COUNT = 'COUNT';
export const LATEST = 'LATEST';
export const UNIQUE_COUNT = 'UNIQUE_COUNT';
export const UC = 'UC';
export const WA = 'WA';
export const COUNT_ES_FIELDNAME = 'docCou';
export const AGGREGATION_LABEL = 'Aggregation';
export const AGGREGATION_PLACEHOLDER = MsgDefaults.getSelectMsg('Aggregation');

export const NUMERIC_BASIC_AGGREGATIONS = [
  {
    name: 'Average',
    value: AVERAGE,
    aggType: NUMERIC,
    fieldType: NUMERIC,
    shortDisplayName: 'Avg',
  },
  {
    name: 'Sum',
    value: SUM,
    aggType: NUMERIC,
    fieldType: NUMERIC,
  },
  {
    name: 'Nth Percentile',
    value: NTH_PERCENTILE,
    aggType: NUMERIC,
    fieldType: NUMERIC,
  },
  {
    name: 'Max',
    value: MAX,
    aggType: NUMERIC,
    fieldType: NUMERIC,
  },
  {
    name: 'Min',
    value: MIN,
    aggType: NUMERIC,
    fieldType: NUMERIC,
  },
];

export const NONNUMERIC_BASIC_AGGREGATIONS = [
  {
    name: 'Latest',
    value: 'LATEST',
    aggType: NONNUMERIC,
    fieldType: NONNUMERIC,
  },
];

export const MIXED_BASIC_AGGREGATIONS = [
  ...NUMERIC_BASIC_AGGREGATIONS,
  ...NONNUMERIC_BASIC_AGGREGATIONS,
];

export const PRODUCT_AGGREGATION = [
  {
    name: 'Product',
    value: PRODUCT,
    aggType: NUMERIC,
    fieldType: NUMERIC,
  },
];

export const VALUE_AGGREGATIONS = [
  ...NUMERIC_BASIC_AGGREGATIONS,
  ...PRODUCT_AGGREGATION,
];

export const SPECIAL_AGGREGATIONS = [
  {
    name: 'Weighted Average',
    value: 'WEIGHTED_AVG',
    aggType: NUMERIC,
    fieldType: NUMERIC,
    shortDisplayName: 'WA',
  },
];

export const COMMON_AGGREGATIONS = [
  {
    name: 'Unique Count',
    value: 'UNIQUE_COUNT',
    aggType: NUMERIC,
    fieldType: MIXED,
    shortDisplayName: 'UC',
  },
  {
    name: 'Count',
    value: 'COUNT',
    aggType: NUMERIC,
    fieldType: MIXED,
  },
];

export const LIST_AGGREGATIONS = [
  {
    name: 'Union',
    value: 'UNION',
    aggType: LIST,
    fieldType: MIXED,
  },
  {
    name: 'Intersect',
    value: 'INTERSECT',
    aggType: LIST,
    fieldType: MIXED,
  },
  
];

export const NUMERIC_AGGREGATIONS = [
  ...NUMERIC_BASIC_AGGREGATIONS,
  ...COMMON_AGGREGATIONS,
  ...SPECIAL_AGGREGATIONS,
];

export const NONNUMERIC_AGGREGATIONS = [
  ...NONNUMERIC_BASIC_AGGREGATIONS,
  ...COMMON_AGGREGATIONS,
];

export const ALL_AGGREGATIONS = [
  ...NUMERIC_BASIC_AGGREGATIONS,
  ...SPECIAL_AGGREGATIONS,
  ...NONNUMERIC_AGGREGATIONS,
];

export const ALL_AGGREGATIONS_WITH_LIST = [
  ...ALL_AGGREGATIONS,
  ...LIST_AGGREGATIONS,
];

export const getAggregationsByType = (type) => {
  if(type == NUMERIC){
    return NUMERIC_AGGREGATIONS;
  }else if (type == NONNUMERIC){
    return  NONNUMERIC_AGGREGATIONS;
  } else if(type == LIST) {
    return LIST_AGGREGATIONS;
  }

  return []; 
};

export const getAggregation = (type) => {
  return ALL_AGGREGATIONS_WITH_LIST.find(aggregation => aggregation.value === type);
};

export function getAggregationType(aggregation){
  const aggregationObject = getAggregation(aggregation);

  return aggregationObject ? aggregationObject.aggType : null;
}

export function getAggregationDisplayName(aggregationObject){
  if(aggregationObject){
    return (aggregationObject.shortDisplayName ? aggregationObject.shortDisplayName : aggregationObject.name);
  }

  return null;
}

export function getAggregationFieldType(aggregation){
  const aggregationObject = getAggregation(aggregation);

  return aggregationObject ? aggregationObject.fieldType : null;
}

export function isFieldAcceptedByAggregation(aggregationFieldType, fieldType) {
  return aggregationFieldType === MIXED || aggregationFieldType === fieldType;
}

export function isNumeric(type){
  return type == NUMERIC;
}

export function isNTHPercentile(type){
  return type === NTH_PERCENTILE;
}

export function isCount(type){
  return type == COUNT;
}

export function isUniqueCount(type){
  return type == UNIQUE_COUNT;
}

export function isOfTypeCount(type){
  return type == COUNT || type == UNIQUE_COUNT;
}

export function isLatest (type){
  return type == LATEST;
}

export function isWeightedAverage(type) {
  return type == WEIGHTED_AVG;
}

export function isUnion(type) {
  return type == UNION;
}

export function isIntersect(type) {
  return type == INTERSECT;
}

export function isListAggregationType(type) {
  return LIST_AGGREGATIONS.find(aggregation => aggregation.value === type);
}

export const isNonNumericAggregationType = (type) => {
  return NONNUMERIC_BASIC_AGGREGATIONS.find(aggregation => aggregation.value === type);
};

export function isBasicNumericAggregation(type){
  return isNumeric(getAggregationType(type))
    && !(isOfTypeCount(type) || isWeightedAverage(type));
}
