/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import Scrollbar from 'react-scrollbar';
import LabeledComponent from 'containers/LabeledComponent';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import Radio from 'inputs/simple/Radio';
import RadioGroup from 'inputs/simple/RadioGroup';
import FilterInput from './FilterInput';
import DataFieldInput from './DataFieldInput';
import DataGroupingInput from './DataGroupingInput';
import RoutingSelectionInput from './RoutingSelectionInput';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import {CompareUtils, AggregationUtils, FieldTypes, WidgetTypes, 
  DataModelTypeUtils} from 'js-utils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as defaults from 'utils/defaults';
import ValidationOutput from 'containers/ValidationOutput';
import {fromJS} from 'immutable';
import * as filterUtils from 'utils/filterUtils';
import 'styles/dataSelection/records/dataSelection.less';
import CollectionAggregation from './CollectionAggregation';
import CollapsableComponent from 'containers/CollapsableComponent';
import PostAggregationFilter from './PostAggregationFilter';
import { getPostAggregationFilterAggregatesList } from 'utils/DataSelectionCommonUtils';
import NumericInputComponent from 'inputs/simple/NumericInputComponent';
import MappingFilter from './MappingFilter';

export default class DataSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourcesOptions:[],
      sourceFields:[],
      filteredSourceFields: [],
      dataSource: this.props.dataSelectionArgs.dataSource,
      limitSize: this.props.dataSelectionArgs.limitSize,
    };
    this.obligatoryNERefFilterIndex = -1;
    this.dataSourceComponent = this.getDataSourceComponent.bind(this);
    this.sourceComponent = this.getSourceComponent.bind(this);
    this.filterComponent = this.getFilterComponent.bind(this);
    this.fieldsComponent = this.getFieldsComponent.bind(this);
    this.getOrderableFieldsComponent = this.getOrderableFieldsComponent.bind(this);
    this.getCollectionAggregationComponent = this.getCollectionAggregationComponent.bind(this);
    this.getAdvancedSection = this.getAdvancedSection.bind(this);
    this.setDataSource = this.setDataSource.bind(this);
    this.setSourceType = this.setSourceType.bind(this);
    this.filterAttributes = this.filterAttributes.bind(this);
    this.getAggregations = this.getAggregations.bind(this);
    this.getAutoNEMappingFieldComponent = this.getAutoNEMappingFieldComponent.bind(this);
    this.getMappingFilter = this.getMappingFilter.bind(this);
    this.getPostAgregationFilterComponent = this.getPostAgregationFilterComponent.bind(this);
    this.onDataSelectionArgsChange = this.onDataSelectionArgsChange.bind(this);
    this.shouldDisableCollectionAggregation = this.shouldDisableCollectionAggregation.bind(this);
    this.getRecordTypeFields = this.getRecordTypeFields.bind(this);
    this.renderLimitSizeComponent = this.renderLimitSizeComponent.bind(this);
    this.onLimitSizeChange = this.onLimitSizeChange.bind(this);
    this.onLimitSizeBlur = this.onLimitSizeBlur.bind(this);
    this.filterAttributeOptions = this.filterAttributeOptions.bind(this);
    this.onChangeWithPlaceholders = this.onChangeWithPlaceholders.bind(this);
    this.getLinkMappingFieldComponents = this.getLinkMappingFieldComponents.bind(this);
    this.getAutoNEMappingFieldComponent = this.getAutoNEMappingFieldComponent.bind(this);
    this.hasGroupableSubFields = this.hasGroupableSubFields.bind(this);
  }

  componentDidMount(){
    this.updateDataSelection(this.props.id, this.props.dataSelectionArgs);
  }

  componentWillReceiveProps(nextProps){
    if((nextProps.id != this.props.id) 
      || (nextProps.id == null && this.props.id == null 
        && this.props.dataSelectionArgs.sourceType != null && nextProps.dataSelectionArgs.sourceType == null) 
      || (this.state.dataSource !== nextProps.dataSelectionArgs.dataSource) 
      || (!nextProps.dataSelectionArgs.sourceType && this.props.dataSelectionArgs.sourceType)){
      this.updateDataSelection(nextProps.id, nextProps.dataSelectionArgs);
    }else if((nextProps.shouldFilterNonNumericFields !== this.props.shouldFilterNonNumericFields ||
      nextProps.dataSelectionArgs.isCollectionTimeAggsEnabled !== this.props.dataSelectionArgs.isCollectionTimeAggsEnabled)
      && nextProps.dataSelectionArgs.sourceType 
      && this.props.dataSelectionArgs.sourceType 
      && nextProps.dataSelectionArgs.sourceType.id === this.props.dataSelectionArgs.sourceType.id){
        const sourceFields = fromJS(this.state.sourceFields).toJS();
        const allFields = this.getFilteredAndAnalyzable(nextProps.shouldFilterNonNumericFields || nextProps.dataSelectionArgs.isCollectionTimeAggsEnabled, sourceFields);
        this.setState(allFields);
    }
  }

  prepareRecordSources(isDataSourceRecordsForAttributes) {
    if (!this.props.getFilteredRecordSourcesByDataType) {
      return this.props.getRecordSources(isDataSourceRecordsForAttributes,
        this.props.filteredAttributeType,
        this.props.selectedNEDataStructure,
        this.props.attributeTypeName,
      );
    }
    const params = {
      filteredAttributeType: this.props.filteredAttributeType,
      filteredSourceAttributeType: this.props.filteredSourceAttributeType,
      filteredDestinationAttributeType: this.props.filteredDestinationAttributeType,
      selectedNEDataStructure: this.props.selectedNEDataStructure,
      selectedSourceNEDataStructure: this.props.selectedSourceNEDataStructure,
      selectedDestinationNEDataStructure: this.props.selectedDestinationNEDataStructure,
    };
    
    return this.props.getFilteredRecordSourcesByDataType(params, isDataSourceRecordsForAttributes);
  }

  updateDataSelection(id, dataSelectionArgs){
    if(id == undefined || dataSelectionArgs.dataSource){
      this.isDataSourceRecordsForAttributes = DataSelectionCommonUtils.isDataSourceRecordsForAttributes(dataSelectionArgs.dataSource);
      const recordSources = this.prepareRecordSources(this.isDataSourceRecordsForAttributes);
      recordSources.then(sourcesOptions => {
        this.setState({sourcesOptions,
          sourceFields: [],
          filteredSourceFields: [],
          dataSource: dataSelectionArgs.dataSource,
          limitSize: dataSelectionArgs.limitSize,
        });
        
        if(dataSelectionArgs.sourceType){
          this.getRecordTypeFields(dataSelectionArgs);
        }
      });
    }
  }

  getRecordTypeFields(dataSelectionArgs){
    this.props.getRecordSourceFieldsById(dataSelectionArgs.sourceType.id).then(response => {
      const sourceFields = response;

      this.obligatoryNERefFilterIndex = DataSelectionUtils.getObligatoryNERefFilterIndex(sourceFields);
      const allFields = this.getFilteredAndAnalyzable(dataSelectionArgs.isCollectionTimeAggsEnabled, sourceFields);

      this.setState({
        sourceFields,
        filteredSourceFields: allFields.filteredSourceFields,
        analyzableSourceField: allFields.analyzableSourceField,
      });

      if(this.props.onSourceFieldsLoaded){
        const sourceFieldsMetaData = DataSelectionUtils.fillSourceFieldsMetaData(sourceFields);

        this.props.onSourceFieldsLoaded({
          groupableFields: sourceFieldsMetaData.groupableFields,
          sourceFieldsHash: sourceFieldsMetaData.sourceFieldsHash,
        });
      }
    });
  }

    getMappingFilter(sourceFields, mappingFilterKeyword, filteredAttributeTypeKeyword, selectedNEDataStructure, dataSelectionArgs, index = 0){
      let mappingFilter = null;

      if(dataSelectionArgs && dataSelectionArgs[mappingFilterKeyword]){
        mappingFilter = dataSelectionArgs[mappingFilterKeyword];
      }else{
        const availableFields = this.filterAttributeOptions(sourceFields, filteredAttributeTypeKeyword, selectedNEDataStructure);
        
        mappingFilter = availableFields && availableFields.length > 0 ? {
          selectedField: availableFields[index],
          operation: 'in',
        } : null;
      }
      
      return mappingFilter;
    }

    getFilteredAndAnalyzable(isCollectionTimeAggsEnabled, sourceFields){
      const filteredSourceFields = isCollectionTimeAggsEnabled ? 
        sourceFields.filter(field => DataSelectionUtils.hasMatchingFieldOrSubfieldWithType(
          field, AggregationUtils.NUMERIC)) : sourceFields;
      const analyzableSourceField = this.filterAttributes(filteredSourceFields);

      return {filteredSourceFields, analyzableSourceField};
    }

  setDataSource(e){
    if (e.target.value !== this.props.dataSelectionArgs.dataSource) {
      const selectedDataSource = e.target.value;

      const recordSources = this.prepareRecordSources(DataSelectionCommonUtils.isDataSourceRecordsForAttributes(selectedDataSource));
      recordSources.then(sourcesOptions => {
          this.setState({
            sourcesOptions,
            dataSource: selectedDataSource, 
          }, () => {
            const hideSelectedData = this.props.dataSelectionArgs.sourceType ? !sourcesOptions.filter(x => x.id == this.props.dataSelectionArgs.sourceType.id)[0] : true;
            // Send all props to can validate on them in case return to show hiddenDataSelection
            const dataSelectionArgs = {
              ...this.props.dataSelectionArgs,
              dataSource: selectedDataSource,
              hideSelectedData,
              hideValidation: hideSelectedData,
            }
            this.onChangeWithPlaceholders(dataSelectionArgs, !hideSelectedData, !hideSelectedData, !hideSelectedData, 
              {isPlaceholdersChanged: true});      
          });      
      });
    }
  }

  onLimitSizeChange({target: {value}}){
    this.setState({limitSize: value});
  }

  onLimitSizeBlur(){
    const dataSelectionArgs = {
      ...this.props.dataSelectionArgs,
      limitSize: this.state.limitSize,
    };

    this.props.onChange(dataSelectionArgs);      
  }

  setSourceType(selected) {
    if(selected != (this.props.dataSelectionArgs.sourceType && this.props.dataSelectionArgs.sourceType.id)){
      this.props.getRecordSourceFieldsById(selected).then(sourceFields => {
        const filters = [];
        const postAggregationFilters = [];
        let routingFilter;
        const sourceType = this.state.sourcesOptions.filter(option => option.id == selected)[0];

        if(this.isDataSourceRecordsForAttributes){
          this.obligatoryNERefFilterIndex = DataSelectionUtils.getObligatoryNERefFilterIndex(sourceFields);
          const obligatoryRefFilter = DataSelectionUtils.getObligatoryRoutingFilter(
            sourceFields[this.obligatoryNERefFilterIndex], this.isDataSourceRecordsForAttributes);

            this.setState({
              sourceFields,
              filteredSourceFields: sourceFields,
              analyzableSourceField: this.filterAttributes(sourceFields),
            });

          if(obligatoryRefFilter && !this.props.dataSelectionOptions.hideNESelection){
            routingFilter = obligatoryRefFilter;
          }
        }else{
          routingFilter = DataSelectionUtils.getObligatoryRoutingFilter(
            sourceType.routingField, this.isDataSourceRecordsForAttributes);

          this.setState({
            sourceFields,
            filteredSourceFields: sourceFields,
            analyzableSourceField: this.filterAttributes(sourceFields),
          });
        }

        const groupByFields = DataSelectionUtils.getDefaultsForGroupBy(sourceFields, 
          this.props.dataSelectionOptions.groupByFields, this.isDataSourceRecordsForAttributes, 
          this.props.showCustomInterval, this.props.customUnitsValues, this.props.customUnits);

        const sourceFieldsMetaData = DataSelectionUtils.fillSourceFieldsMetaData(sourceFields);
        let defaultDestinationFieldIndex = 0;
        if(this.props.filteredSourceAttributeType == this.props.filteredDestinationAttributeType){
          if(!(this.props.filteredSourceAttributeType == DataModelTypeUtils.REF_DATA_TYPE) || this.props.selectedSourceNEDataStructure == this.props.selectedDestinationNEDataStructure){
            defaultDestinationFieldIndex = 1;
          }
        }
        const dataSelectionArgs = DataSelectionCommonUtils.getClearedFFAG({sourceType,
          filters, 
          routingFilter: this.props.dataSelectionOptions.hideNESelection ? null : routingFilter, 
          groupByFields, 
          groupableFields: sourceFieldsMetaData.groupableFields,
          sourceFieldsHash: sourceFieldsMetaData.sourceFieldsHash,
          mappingFilter: this.props.dataSelectionOptions.hasAutoNEMapping ? this.getMappingFilter(sourceFields, 'mappingFilter', 'filteredAttributeType', this.props.selectedNEDataStructure) : null,
          sourceMappingFilter: this.props.dataSelectionOptions.hasMultipleNEMapping ? this.getMappingFilter(sourceFields, 'sourceMappingFilter', 'filteredSourceAttributeType', this.props.selectedSourceNEDataStructure) : null,
          destinationMappingFilter: this.props.dataSelectionOptions.hasMultipleNEMapping ? this.getMappingFilter(sourceFields, 'destinationMappingFilter', 'filteredDestinationAttributeType', this.props.selectedDestinationNEDataStructure,
            null, defaultDestinationFieldIndex) : null,
          postAggregationFilters,
          postAggregationFilterEnabled: false,
        });
          
        dataSelectionArgs.placeholders = {};
        this.props.onChange(dataSelectionArgs, true);
      });
    }
  }

  onChangeWithPlaceholders(newdataSelectionArgs, 
    checkValidation, checkListValidation, checkListItemsValidation, extraOptions = {}){
    if(newdataSelectionArgs.hideSelectedData){
      newdataSelectionArgs.placeholders = {};
    }else if(extraOptions.isPlaceholdersChanged){
      newdataSelectionArgs.placeholders = DataSelectionCommonUtils.getPlaceHoldersFromDataSelection({
        filters: newdataSelectionArgs.filters 
          || this.props.dataSelectionArgs.filters, 
        postAggregationFilters: newdataSelectionArgs.postAggregationFilters 
          || this.props.dataSelectionArgs.postAggregationFilters, 
        routingFilter: newdataSelectionArgs.routingFilter 
          || this.props.dataSelectionArgs.routingFilter, 
        dataSelectionOptions: this.props.dataSelectionOptions});
    }

    this.props.onChange(newdataSelectionArgs, 
      checkValidation, checkListValidation, checkListItemsValidation, extraOptions);
  }

  onDataSelectionArgsChange(updatedDataSelectionArgs, checkValidation, checkListValidation, checkListItemsValidation) {
    let dataSelectionArgs = fromJS(this.props.dataSelectionArgs).toJS();
      dataSelectionArgs = {
        ...dataSelectionArgs,
        ...updatedDataSelectionArgs,
      }

    dataSelectionArgs.postAggregationFilters = DataSelectionCommonUtils.getPostAggregationFilters(dataSelectionArgs.postAggregationFilters, dataSelectionArgs.fields);
    this.props.onChange(dataSelectionArgs, checkValidation, checkListValidation, checkListItemsValidation);
  }

    getDataSourceComponent(){
      return this.props.dataSelectionOptions.hideDataSourceComponent ? null : (
          <RadioGroup label="Data Source" disabled={this.props.disableComponent}
            className='dataSource-radioGrp row'
            labelClassName={`col-xs-12 ${this.props.isAssociation? '': 'col-md-3'}`}
            childrenClassName={`col-xs-12 ${this.props.isAssociation? 'evc-nesting-indentation': 'col-md-9'}`}>
              <Row>
                <Radio 
                  className='col-xs-6 attribute-radio'
                  disabled={this.props.disableComponent}
                  checked={this.isDataSourceRecordsForAttributes}
                  onChange={this.setDataSource}
                  value={defaults.RECORDS_FOR_ATTRIBUTES} label={"Attributes"} />
                <Radio 
                  disabled={this.props.disableComponent}
                  className='col-xs-6 records-radio'
                  checked={!this.isDataSourceRecordsForAttributes}
                  onChange={this.setDataSource}
                  value={defaults.RECORDS} label={"Records"} />
              </Row>
          </RadioGroup>
        );
    }

    getSourceFieldsComponent(hideSelectedData, sourceFields){
      const fieldsView = (hideSelectedData || sourceFields == undefined) ? null
      :
      <Row>
        <Col xs={12}>
            <Scrollbar
            speed={0.8}
            contentClassName="content"
            className={sourceFields.length > 0 ? "evc-scrollArea" : ''}
            smoothScrolling
            horizontal={false}
            key={this.props.dataSelectionArgs?.sourceType?.id}>
              { sourceFields.filter(index => !index.hidden).map((index) => {
                    return (
                    <Row>
                        <Col xs={12}>
                          <span className='ds-source-field'> 
                            {index.fieldName}
                          </span>
                        </Col>
                    </Row>
                    );
              })}
            </Scrollbar>
        </Col>
      </Row>;

      return fieldsView;
    }

    getSourceComponent(hideSelectedData){
      if(this.props.dataSelectionOptions.hideSourceType){
        return null;
      }
      
      const sourcesOptions = UIConstructionUtils.wrapArrayInToSelectOptions(this.state.sourcesOptions,
          'id',
          'id',
          'recordName');

      const fieldsView = this.getSourceFieldsComponent(hideSelectedData, this.state.sourceFields);

      return (
        <Row>
          <LabeledComponent label="Source Type*" size={12}
            labelSize={this.props.isAssociation? 12 : 3 }
            inputSize={this.props.isAssociation? 12 : 9 }>
              <div className={this.props.isAssociation ? 'evc-nesting-indentation' : '' }>
                <MultipleSelect  disabled={this.props.disableComponent}
                placeholder='Please Select Source Type'
                className="srcType-menu"
                value={!hideSelectedData && this.props.dataSelectionArgs.sourceType ? 
                    this.props.dataSelectionArgs.sourceType.id : null}
                options={sourcesOptions}
                onChange={(e) => this.setSourceType(e.target.value)}
                />
                <ValidationOutput validation={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.sourceTypeInput } />
                {fieldsView}
              </div>
          </LabeledComponent>
        </Row>
      );
    }

    filterAttributeOptions(attributeOptions, filteredAttributeTypeKeyword, selectedNEDataStructure){
      if(selectedNEDataStructure){
        return attributeOptions.filter(field => field.ref && field.refType.id == selectedNEDataStructure);
      }

      return attributeOptions.filter(field => field.attributeType && 
        field.attributeType.dataType == this.props[filteredAttributeTypeKeyword]
        && field.attributeType.name != 'DATETIME');
    }

    getMappingFieldComponent(attributeOptions, hideSelectedData, filteredAttributeType, mappingFilter, selectedNEDataStructure, mappingFilterKeyword, label, filterMultiValuedFields) {
      return (
        <MappingFilter
          keyword={mappingFilterKeyword}
          isAssociation={this.props.isAssociation}
          disabled={this.props.disableComponent}
          hideSelectedData={hideSelectedData}
          filteredAttributeType={filteredAttributeType}
          mappingFilter={mappingFilter}
          attributeOptions={attributeOptions}
          selectedNEDataStructure={selectedNEDataStructure}
          onChange={this.props.onChange}
          dataSelectionArgs={this.props.dataSelectionArgs}
          label={label}
          filterMultiValuedFields={filterMultiValuedFields}
        />
      );
    }

    getLinkMappingFieldComponents(attributeOptions, hideSelectedData) {
      return (
        <Fragment>
          {this.getMappingFieldComponent(attributeOptions, hideSelectedData, this.props.filteredSourceAttributeType,
            this.props.dataSelectionArgs.sourceMappingFilter, this.props.selectedSourceNEDataStructure, 
            'sourceMappingFilter', 'Source Mapping Field*', true)}
          {this.getMappingFieldComponent(attributeOptions, hideSelectedData, this.props.filteredDestinationAttributeType,
            this.props.dataSelectionArgs.destinationMappingFilter, this.props.selectedDestinationNEDataStructure, 
            'destinationMappingFilter', 'Destination Mapping Field*', true)}
          {<ValidationOutput validation={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.distinctSrcAndDestMappingFields } />}
        </Fragment>
      );
    }

    getAutoNEMappingFieldComponent(attributeOptions, hideSelectedData){
      if (this.props.dataSelectionOptions.hasAutoNEMapping) {
        return (this.getMappingFieldComponent(attributeOptions, hideSelectedData, this.props.filteredAttributeType,
          this.props.dataSelectionArgs.mappingFilter, this.props.selectedNEDataStructure, 'mappingFilter', 'Mapping Field*'));
      }
  
      return null;
    }

  getRoutingSelectionComponent(operations){
    if(this.props.dataSelectionArgs.routingFilter && !this.props.dataSelectionOptions.hideNESelection){
      if(this.isDataSourceRecordsForAttributes && this.obligatoryNERefFilterIndex < 0){
        this.obligatoryNERefFilterIndex = DataSelectionUtils.getObligatoryNERefFilterIndex(this.state.sourceFields);
      }

      return(
        <RoutingSelectionInput
          onChange={this.onChangeWithPlaceholders}
          routingFilter={this.props.dataSelectionArgs.routingFilter}
          selectedField={this.isDataSourceRecordsForAttributes ? this.state.sourceFields[this.obligatoryNERefFilterIndex] : this.props.dataSelectionArgs.sourceType.routingField}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          validationOutcome={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.selectionInput}
          operations={operations}
          isDataSourceRecordsForAttributes={this.isDataSourceRecordsForAttributes}
          disableComponent={this.props.disableComponent}
          nePlaceholder={this.state.nePlaceholder}
          isTemplate={this.props.isTemplate}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          hasVariables={this.props.hasVariables}
      />
      );
    }
    
    return null;
  }

    getFilterComponent(attributeOptions, hideSelectedData){
      let component = null;

      if(!this.props.dataSelectionOptions.filters.hide){
        component = <FilterInput
          getAutoCompleteSuggestions={this.props.getAutoCompleteSuggestions}
          updateFiltersList={this.onChangeWithPlaceholders}
          attributesList={filterUtils.getFilterableFields(attributeOptions, this.props.dataSelectionOptions.filters)}
          filters={hideSelectedData ? [] : this.props.dataSelectionArgs.filters}
          validationOutcome={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.filterInput}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          disableComponent={this.props.disableComponent || this.disableAdd}
          placeholderOptions={this.props.placeholderOptions}
          isTemplate={this.props.isTemplate}
          hasVariables={this.props.hasVariables}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          serverTimeZone={this.props.serverTimeZone} />;
      }

      return component;
    }

  getAggregations(){
    if (this.props.dataSelectionArgs.isCollectionTimeAggsEnabled){
      return AggregationUtils.NUMERIC_BASIC_AGGREGATIONS;
    } else if (this.props.shouldFilterNonNumericFields){
      return AggregationUtils.ALL_AGGREGATIONS.filter(aggregation => aggregation.value !== AggregationUtils.LATEST)
    }

    return AggregationUtils.ALL_AGGREGATIONS;
  }

    getFieldsComponent(attributeOptions, hideSelectedData, filterOptions, groupByFieldIds){
      let component = null;

      if(!this.props.dataSelectionOptions.fields.hide){

        component = <DataFieldInput
          attributeList={attributeOptions}
          fields={hideSelectedData ? [] : (this.props.dataSelectionArgs.fields || [])}
          groupByFieldIds={groupByFieldIds}
          aggregations={this.getAggregations()}
          onChange={this.onDataSelectionArgsChange}
          recordType={this.props.dataSelectionArgs.sourceType}
          minFields={this.props.dataSelectionOptions.fields.min}
          maxFields={this.props.dataSelectionOptions.fields.max}
          validationOutcome={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.fieldInput}
          fieldsList={filterOptions}
          disableComponent={this.props.disableComponent || this.disableAdd}
          hasUnit={this.props.dataSelectionOptions.fields.hasUnit}
        />;
      }

      return component;
    }

    getOrderableFieldsComponent(groupByOptions, hideSelectedData, keyword, 
        title, singleEntityTitle, aggregationFieldIds){
      let component = null;

      if(!this.props.dataSelectionOptions[keyword].hide){
        component = 
          <DataGroupingInput
            key={keyword}
            attributesList={groupByOptions}
            groupByFields={hideSelectedData ? [] : this.props.dataSelectionArgs.groupByFields}
            aggregationFieldIds={aggregationFieldIds}
            columns={hideSelectedData ? [] : this.props.dataSelectionArgs.columns}
            topologyNodes={hideSelectedData ? [] : this.props.dataSelectionArgs.topologyNodes}
            onChange={keyword == 'groupByFields' ? this.onDataSelectionArgsChange : this.props.onChange}
            recordType={this.props.dataSelectionArgs.sourceType}
            showCustomInterval={this.props.showCustomInterval}
            customUnits={this.props.customUnits}
            customUnitsValues={this.props.customUnitsValues}
            validationOutcome={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.groupByInput }
            disableComponent={this.props.disableComponent || this.disableAdd}
            groupByTitle={title}
            singleEntityTitle={singleEntityTitle}
            keyword={keyword}
            options={this.props.dataSelectionOptions[keyword]}
          />;
      }

      return component;
    }

    shouldDisableCollectionAggregation(props) {
      const isAllAggregationsBasicNumeric = (props.dataSelectionArgs.fields || []).reduce( (result, field) => {
        return result && AggregationUtils.isBasicNumericAggregation(field.aggregationType);
      }, true);

      return props.disableComponent || this.disableAdd
      || !isAllAggregationsBasicNumeric
      || (!props.dataSelectionOptions.showCollectionAggregation 
        && props.dataSelectionArgs.isCollectionTimeAggsEnabled);
    }
    
    getCollectionAggregationComponent(props){
      return props.dataSelectionOptions.showCollectionAggregation || props.dataSelectionArgs.isCollectionTimeAggsEnabled ? 
        <CollectionAggregation
        validation={props.dataSelectionArgs.hideValidation ? {} : props.validationOutcome.collectionAggregationValidation}
        onChange={props.onChange}
        isAssociation={this.props.isAssociation}
        disableComponent={this.shouldDisableCollectionAggregation(props)}
        aggregations={AggregationUtils.NUMERIC_BASIC_AGGREGATIONS}
        dataSelectionArgs={props.dataSelectionArgs.hideSelectedData ? {} : props.dataSelectionArgs}
        /> : null;
    }

    getPostAgregationFilterComponent(operations){
      return (
        <PostAggregationFilter 
          operations={operations}
          updateFiltersList={this.onChangeWithPlaceholders}
          attributesList={getPostAggregationFilterAggregatesList(this.props.dataSelectionArgs.fields)}
          filters={this.props.hideSelectedData ? [] : this.props.dataSelectionArgs.postAggregationFilters}
          validationOutcome={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.postAggregationFilter}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          disableComponent={this.props.disableComponent || this.disableAdd}
          placeholderOptions={this.props.placeholderOptions}
          isTemplate={this.props.isTemplate}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          serverTimeZone={this.props.serverTimeZone}
          postAggregationFilterEnabled={this.props.dataSelectionArgs.postAggregationFilterEnabled}
          showPostAggregationFilter={this.props.dataSelectionOptions.showPostAggregationFilter}
        />
      );
    }

    filterAttributes(sourceFields){
      if (this.props.widgetType == WidgetTypes.WIDGET_TYPES.MLFORECAST){
        return sourceFields.filter(field =>
          field.seasonality == DataSelectionUtils.SEASONALITY_VALUES.SEASONAL
          || FieldTypes.isCountField(field));
      }else if (this.props.widgetType == WidgetTypes.WIDGET_TYPES.MLANOMALY){
        return sourceFields.filter(field =>
          field.seasonality == DataSelectionUtils.SEASONALITY_VALUES.SEASONAL
            || field.seasonality == DataSelectionUtils.SEASONALITY_VALUES.NON_SEASONAL
            || FieldTypes.isCountField(field));
      }
      return [];
    }

    getAdvancedSection(props, operations){
      if(props.dataSelectionOptions.showAdvancedOptions || this.props.dataSelectionArgs.isCollectionTimeAggsEnabled){
        return (
          <Fragment>
            <div className='horizontal-divider'></div>
            <CollapsableComponent
              title={DataSelectionUtils.LABELS.ADVANCED}
              wrapInRow
              validation={this.props.dataSelectionArgs.hideValidation ? {} : this.props.validationOutcome.advancedSection}
              initiallyCollapsed
              className='ds-collaspable-wrapper'
            > 
              {this.getCollectionAggregationComponent(props)}
              {this.getPostAgregationFilterComponent(operations)}
            </CollapsableComponent>
          </Fragment>
        )}
      return null;
    }

    getTopologyNodesComponent(hideSelectedData, keyword, title, singleEntityTitle){
      if(!this.props.dataSelectionOptions.topologyNodes.hide){
        const topologyNodesOptions = this.state.sourceFields.filter(field => field.groupable 
          && field.fieldName != FieldTypes.DATE_FIELD_NAME && !field.geoLocationField);
        return this.getOrderableFieldsComponent(topologyNodesOptions, hideSelectedData, keyword, title, singleEntityTitle, []);
      }
    }

    renderLimitSizeComponent(){
      return this.props.dataSelectionOptions.limitSize?.showLimitSize ? 
        <Row>
          <LabeledComponent
            componentClassName= 'margTop20'
            size={12}
            label='Limit Results'
            labelSize={12}
            inputSize={12}>      
              <div className='evc-nesting-indentation'>
                <NumericInputComponent
                  pureInput
                  min={1}
                  max={this.props.dataSelectionOptions.limitSize?.maxLimitSize}
                  inputType='Integer'
                  onChange={this.onLimitSizeChange}
                  onBlur={this.onLimitSizeBlur}
                  value={this.state.limitSize}
                />
                <ValidationOutput validation={this.props.validationOutcome.limitSize} />  
              </div>
          </LabeledComponent>
        </Row>
        : null;
     }

     hasGroupableSubFields(field){
      const subFields = field.subFields;

      for(let i = 0; i < subFields.length; i++){
        if(subFields[i].groupable){
          return true;
        }
      }

      return false;
     }
    
    render(){
      this.isDataSourceRecordsForAttributes = DataSelectionCommonUtils.isDataSourceRecordsForAttributes(this.props.dataSelectionArgs.dataSource);

      const operations = UIConstructionUtils.wrapArrayInToSelectOptions(CompareUtils.COMPARATORS_LIST, "value", "value", "name");
      let groupByOptions = [];
      let fieldsOptions = [];
      let filterOptions = [];
      let groupByFieldIds = [];
      let aggregationFieldIds = [];

      if (this.state.sourceFields && this.state.sourceFields.length > 0) {
        const fieldsList = this.props.dataSelectionArgs.fields.map(agg => {
          if (agg.selectedField) {
            aggregationFieldIds.push(agg.selectedField.id);
            return agg.selectedField.fieldName;
          }
        });
        const groupByFieldsList = this.props.dataSelectionArgs.groupByFields.map(groupBy => {
          if (groupBy.selectedField) {
            groupByFieldIds.push(groupBy.selectedField.id);
            return groupBy.selectedField.fieldName;
          }
        });

        filterOptions = this.state.sourceFields.filter(field => (!DataModelTypeUtils.isObjectFieldType(field) 
          || field.subFields?.length !== 0));
          
        groupByOptions = this.state.sourceFields.filter(field => (!fieldsList.includes(field.fieldName)
          || DataModelTypeUtils.isObjectFieldType(field)) 
          && (field.groupable || this.hasGroupableSubFields(field)));

        fieldsOptions = WidgetTypes.isMLForecast(this.props.widgetType) ?
          this.state.analyzableSourceField.filter(field => !groupByFieldsList.includes(field.fieldName))
          : this.state.filteredSourceFields.filter(field => (!groupByFieldsList.includes(field.fieldName)
          || DataModelTypeUtils.isObjectFieldType(field)) && !FieldTypes.isDateField(field));
      }

      const dataSourceComponent = this.getDataSourceComponent();
      const sourceComponent = this.getSourceComponent(this.props.dataSelectionArgs.hideSelectedData);
      this.disableAdd = this.props.dataSelectionArgs.hideSelectedData || !(this.props.dataSelectionArgs.sourceType && this.props.dataSelectionArgs.sourceType.id);
      
      const autoNEMappingFilter = this.getAutoNEMappingFieldComponent(filterOptions, this.props.dataSelectionArgs.hideSelectedData);
      const autoLinkNeMappingFilter = this.getLinkMappingFieldComponents(filterOptions, this.props.dataSelectionArgs.hideSelectedData)

      const routingSelectionComponent = this.getRoutingSelectionComponent(operations);
      const filterComponent = this.getFilterComponent(filterOptions, this.props.dataSelectionArgs.hideSelectedData);
      const fieldsComponent = this.getFieldsComponent(fieldsOptions, this.props.dataSelectionArgs.hideSelectedData, this.state.sourceFields, groupByFieldIds);
      const groupByComponent = this.getOrderableFieldsComponent(groupByOptions, this.props.dataSelectionArgs.hideSelectedData, 'groupByFields', 'Grouping', 'Group', aggregationFieldIds);
      const columnsComponent = this.getOrderableFieldsComponent(this.state.sourceFields, this.props.dataSelectionArgs.hideSelectedData, 'columns', 'Columns', 'Column', []);
      const topologyNodesComponent = this.getTopologyNodesComponent(this.props.dataSelectionArgs.hideSelectedData, 'topologyNodes', 'Nodes', 'Node');
      const advancedSection = this.getAdvancedSection(this.props, operations);
      return (
        <Row className='data-selection-wrapper'>
          <Col xs={12}>
            {dataSourceComponent}
            {sourceComponent}
            {routingSelectionComponent}
            {autoNEMappingFilter}
            {this.props.dataSelectionOptions.hasMultipleNEMapping ? autoLinkNeMappingFilter : null}
            {filterComponent}
            {groupByComponent}
            {fieldsComponent}
            {columnsComponent}
            {topologyNodesComponent}
            {advancedSection}
            {this.renderLimitSizeComponent()}
          </Col>
        </Row>
      );

    }

}