/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';
import Immutable from 'immutable';

export default {
  getUserList: () => request('admin/user/'),
  getUserListFiltered: (query) => request('admin/user/tableData/', {
    data: query,
    method: 'POST',
  }),
  getUser: (reqUserId) => request('admin/user/', {
    method: 'GET',
    data: {reqUserId}
  })
  .then(value => {
    return value;
  })
  .catch((error) => {
    return error;
  }),

  initiateUsersTable: () => request('admin/user/tableData/initiate'),
  getUserData: () => fetch('user/userData', {
    method : 'GET',
    responseType : 'json',
    contentType : 'json',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
  .then(
    response => {
      if(response.ok){
        return response.json();
      }
      if(response.status === 401){
        return ({loggedIn: false});
      }else if(response.status === 503 || response.status === 403){
        return({expired: true});
      }

      return response.text().then(
        text => {
          throw text;
        }
      );
    }
  )
  .then(
    (response) => {
      const {expired, ...result} = response;

      if(expired){
        return {expired: true};
      }

      if(result.workflowPermissions){
        return result;
      }

      return {loggedIn : response.loggedIn};
    }
  ),

  saveUser: user => request('admin/user/', {
    method: 'POST',
    data: Immutable.fromJS(user).update( val => val.trim ? val.trim() : val ),
  }).then( (response) => ({response}) ).catch( message => ({
    response: false
  })),

  deleteUser: (username, transferToUserId) => request(`admin/user/${username}`, {
    method: 'DELETE',
    data: { transferToUserId },
  }).then( (response) => (response) ),

  validateUserEmail: (username, email) =>
    request('admin/user/validateEmail/', {
      data: {username, email}
    }).then(
      () => true
    ).catch(
      () => false
    ),

  addUser: (newEvent, isNewUser, isInvite) => {
    const requestMethod = isNewUser ?  'PUT' : 'POST' ;
    const requestURL = isInvite ? "admin/user/invite" : "admin/user/";

    return request(requestURL, {
      data: newEvent,
      method: requestMethod,
    });
  },

  checkUserExists: username => request('admin/user/checkUsernameExists', {
    data: {username}
  }),

  checkEmailExists: email => request('admin/user/checkEmailExists', {
    data: {email}
  }).then(
    () => false
  ).catch(
    () => true
  ),
  getAvailableRanks: () => request('admin/user/getAvailableRanks'), 
  updateTheme: (theme) => request('admin/user/updateTheme', {
    data:  {theme},
  }),
  reinviteUser: (data) => request('admin/user/reinvite', {
    method: 'POST',
    data,
  }),
  unlockUser: (username) => request(`admin/user/unlock/${username}`, {
    method: 'PUT',
  }).then( (response) => (response) ),
};
