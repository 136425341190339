/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import AppActions from 'actions/AppActions';
import {FormWithFooter, Icons, TooltippedButton} from 'react-template';
import LoginAPI from 'api/LoginAPI';
import { withRouter } from 'react-router';
import ExternalPageContainer from './ExternalPageContainer';
import { MsgDefaults, RequestUtils } from 'js-utils';

import 'styles/login/login.less';
import ExternalPageAlert from './ExternalPageAlert';


@withRouter
export default class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      loginStatus: 'default',
      loading: false,
      showPassword: false,
      firstLoad: true,
    };
    this.getFormInputs = this.getFormInputs.bind(this);
    this.login = this.login.bind(this);
    this.onForgetPassword = this.onForgetPassword.bind(this);
    this.getAlert = this.getAlert.bind(this);
  }

  componentDidMount(){
    if(!this.props.expired){
      document.getElementsByName('username')[0].focus();;
    }
  }

  login(){
    const formData = {
      username: this.state.username,
      password: this.state.password,
    };

    this.setState({ loading : true, firstLoad: false }, () => { 
      LoginAPI.submitLogin(formData).then(value => {
        if(value.loginStatus === true){
          AppActions.getUserData();
          AppActions.getAppConfigs();
        }        
        this.setState({
          loginStatus: value.loginStatus,
          loading: value.loginStatus,
          errorStatusCode: value.errorStatusCode,
        });
      });
    });
  }

  onForgetPassword(){
    this.props.router.push({
      pathname:'/forgetpassword',
    });
  }

  getShowHidePasswordBtn(stateKey){
    return (
      <TooltippedButton 
        onClick={() => this.setState({[stateKey]: !this.state[stateKey]})}
        tooltip={MsgDefaults.getShowHideTitle('Password', this.state[stateKey])}
        className='btn-primary togglePasswordBtn'
      >
        <i className={this.state[stateKey] ? Icons.hide : Icons.view} /> 
      </TooltippedButton>
    );
  }

  getFormInputs(){
    return [{
      type: 'text',
      label: 'Username',
      keyword: 'username',
      placeholder: MsgDefaults.getEnterMsg('Username'),
      validationLabel: 'Username',
      validation : false,
      required: true, 
      trimmed: true,
      forceUpdateOnChange: true,
      initialValue: this.state.username,
      variableSizes: {
        inputSize: 12,
      },
    },
    {
      type: 'text',
      label: 'Password',
      keyword: 'password',
      placeholder: MsgDefaults.getEnterMsg('Password'),
      validationLabel: 'Password',
      validation : false,
      required: true, 
      trimmed: true,
      forceUpdateOnChange: true,
      password: !this.state.showPassword,
      initialValue: this.state.password,
      variableSizes: {
        inputSize: 12,
      },
      buttons: this.getShowHidePasswordBtn('showPassword'),
    }];
  }

  getAlert() {
    if(!this.state.loginStatus) {
      if(this.state.errorStatusCode == RequestUtils.ERR_CODES.LOCKED){
        return (
          <ExternalPageAlert
            isError
            title='Account Locked!'
            message='Your account has been locked due to multiple unsuccessful login attempts.
              Please use the "Forgot Password" option or
              contact your administrator to unlock your account.'
          />
        );
      }else if(this.state.errorStatusCode == RequestUtils.ERR_CODES.FORBIDDEN){
        return(
          <ExternalPageAlert
            isError 
            title='Login Failed!'
            message='Insert a valid username and password.'
          />
        ); 
      } 
    }      
    
    const successMessage = this.props.location.state?.successMessage;

    if(this.state.firstLoad && successMessage) {
      if(successMessage == 'RESET_PASSWORD') {
        return(
          <ExternalPageAlert
            title='Success' 
            message='Your password has been reset successfully.'
          />
        ); 
      } else if(successMessage == 'USER_REGISTRATION') {
        return(
          <ExternalPageAlert 
            title='Success' 
            message='Your account has been created successfully.'
          />
        ); 
      }
    }
    
    return null;
  }

  render() {
    return (
      <ExternalPageContainer>
        {this.getAlert()}
        { this.props.expired ? 
          <ExternalPageAlert isError title='License Expired!' message='Your license has expired. Please contact support.'/>
          : <FormWithFooter
            inputs={this.getFormInputs()}
            validateOnFirstLoad
            submitOnEnter
            hideCancelButton
            expandFullWidth
            noFooterBorder
            submitButtonText='Login'
            footerClass='loginFormFooter'
            onChange={(result) => this.setState({...result})}
            disableSaveButton={
              !this.state.isFormValidated 
              || this.state.loading
            }
            onSubmit={this.login}
            footerButtons={
              <div onClick={this.onForgetPassword} className='forgetPassLinkWrapper ev-auto-forgetPassLinkWrapper'>
                <a className='forgetPassLink'>Forgot Password?</a>
              </div>
            }
          />
        }
      </ExternalPageContainer>
    );
  }
}
