/* Copyright 2019 Greyskies. All Rights Reserved. */

import AppDispatcher from 'dispatcher/AppDispatcher';
import UserManagementConstants from 'constants/UserManagementConstants';
import UserAPI from '../api/UserAPI';

export default {
  getUserList() {
    UserAPI.getUserList().then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.GET_USER_LIST,
        data: value,
      });
    });
  },
  setUserList(query) {
    return UserAPI.getUserListFiltered(query).then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.SET_USER_LIST,
        data: value,
      });
    });
  },
  setField(index, fieldKey, value) {
    AppDispatcher.dispatch({
      actionType: UserManagementConstants.SET_FIELD,
      data: {
        index,
        fieldKey,
        value,
      },
    });
  },
  deleteUser(userName, newOwnerId) {
    return UserAPI.deleteUser(userName, newOwnerId);
  },
  validateUserEmail(username, email) {
    UserAPI.validateUserEmail(username, email).then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.VALIDATE_EMAIL,
        data: {
          status: value,
          username,
        },
      });
    });
  },
  getAvailableRanks() {
    UserAPI.getAvailableRanks().then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.GET_RANKS,
        data: value,
      });
    });
  },
  addUser(data, isNewUser, isInvite){
    return UserAPI.addUser(data, isNewUser, isInvite).then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.ADD_USER,
        data: value,
      });
      
      return value;
    });
  },
  getUser(reqUserId, errorCallback) {
    return UserAPI.getUser(reqUserId).then(value => {
      AppDispatcher.dispatch({
        actionType: UserManagementConstants.GET_USER,
        data: value,
        errorCallback,
      });
    });
  },
  updateTheme(theme){
    return UserAPI.updateTheme(theme).then(value => { 
      return value;
    });
  },
  initiateUsersTable(){
    return UserAPI.initiateUsersTable().then(value => { 
      return value;
    });
  },
  reinviteUser(data){
    return UserAPI.reinviteUser(data);
  },
  unlockUser(userName){
    return UserAPI.unlockUser(userName);
  },
};
