/* Copyright 2019 Greyskies. All Rights Reserved. */

export const SYSTEM_SETTINGS_NAME = 'General Settings';
export const PLACEHOLDERS_SETTINGS = 'Placeholder Settings';
export const TOPOLOGY_SETTINGS = 'Topology Settings';
export const USER_MANAGEMENT = 'User Management';
export const GROUP_MANAGEMENT = 'Group Management';
export const OWNERSHIP_MANAGEMENT = 'Access Management';
export const LDAP_MANAGEMENT = 'LDAP Management';
export const DATA_TYPES_NAME = 'Data Types';
export const ADVANCED_METRIC_BINDING = 'Advanced Metric Binding';
export const SYNTHETIC_TESTING = 'Synthetic Testing';
export const ADVANCED_METRIC_COLLECTION = 'Advanced Metric Collection';
export const ATTRIBUTE_DATA_INSERTION = 'Attribute Data Insertion';
export const DATA_ANALYSIS = 'Data Analysis';
export const NETWORK_BROWSER = 'Network Browser';
export const EVENT_DEFINITIONS = 'Event Definitions';
export const TAG_DEFINITIONS = 'Tag Definitions';
export const TEMPLATES = 'Automation Templates';
export const REST_API = 'Rest API';
export const STREAMING_NBI = 'Streaming NBI';
export const AUTOMATION_WORKFLOWS = 'Automation Workflow';
export const EVENT_LOGS_NAME = 'System Logs';
export const FGA = 'FGA';
export const FILE_EXPORT = 'File Export';
export const SCHEDULE_FILE_EXPORT = 'Schedule File Export';
export const AUDITING_TEMPLATE_MANAGEMENT = 'Auditing Template Management';
export const USER_PROFILE = 'User Profile';
export const OTEL = 'Traces Explorer';
export const CUSTOM_ANALYTIC = 'Analytics Projects';
export const ANALYTIC_JOBS = 'Analytics Jobs';
export const ANALYTIC_LOGS = 'Analytics Logs';
export const INCIDENT_DEFINITIONS = 'Incident Definitions';
export const INCIDENT_EXPLORER = 'Incident Explorer';
export const INCIDENTS = 'Incidents';