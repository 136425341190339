/* Copyright 2019 Greyskies. All Rights Reserved. */

import CytoscapeComponent from 'react-cytoscapejs';
import popper from 'cytoscape-popper';
import cytoscape from 'cytoscape';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import React, {Component} from 'react';
import * as topologyUtils from 'utils/topologyUtils';
import TopologyDetailsPopup from './TopologyDetailsPopup';
import {fromJS} from 'immutable';
import Tippy from 'tippy.js';
import {UnitConversionUtils, DateTimeUtils, FieldTypes,
  AggregationUtils, Utils, RequestUtils, ArrayUtils} from 'js-utils';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import 'styles/dataVisualization/topology/topology.less';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import * as Icons from 'templates/Icons';
import klay from 'cytoscape-klay';
import fcose from 'cytoscape-fcose';
import nodeHtml  from './Plugins/CytoNodeHtml';

const ASC = 'ASC';
const DESC = 'DESC';
const errorTexts = {
  CANNOT_RETRIEVE_DATA : 'Can\'t retrieve data',
  MISSING_PLACEHOLDERS : 'There are missing placeholders',
}

cytoscape.use( klay );
cytoscape.use( fcose );
cytoscape.use( popper );
cytoscape.use(nodeHtml);
export default class Topology extends Component {

  constructor(props) {
    super(props);
    this.getTopologyNodePositions = this.getTopologyNodePositions.bind(this);
    this.topologyNodePositions = {};
    this.topologyNodePositionsMap = {};
    this.closePopUp = this.closePopUp.bind(this);
    this.openPopUp = this.openPopUp.bind(this);
    this.getTableColumns = this.getTableColumns.bind(this);
    this.getLinkObject = this.getLinkObject.bind(this);
    this.makeTippyTooltip = this.makeTippyTooltip.bind(this);
    this.generateTooltipOnNode = this.generateTooltipOnNode.bind(this);
    this.openNodePopUp = this.openNodePopUp.bind(this);
    this.getNodeObject = this.getNodeObject.bind(this);
    this.getTableNodeColumns = this.getTableNodeColumns.bind(this);
    this.getTableLinkColumns = this.getTableLinkColumns.bind(this);
    this.onEdgeClick = this.onEdgeClick.bind(this);
    this.onNodeClick = this.onNodeClick.bind(this);
    this.onClickLinkAttribute = this.onClickLinkAttribute.bind(this);
    this.onClickNodeAttribute = this.onClickNodeAttribute.bind(this);
    this.onGetTopologyDataError = this.onGetTopologyDataError.bind(this);
    this.onAfterDataRetrieved = this.onAfterDataRetrieved.bind(this);

    this.node = null;
    this.link = null;
    this.NO_MAPPING_FOUND = 'No mapping found';
    this.state = {
      data: undefined,
      nodeData: undefined,
      showPopup: false,
      showNodePopup: false,
      tooltipObj:{
        multiselect: false,
        attributeSelected: null,
      },
      drillDownWidgetParameterStatus: undefined,
      drillDownWidgetIcon: undefined,
    };
  }

  shouldComponentUpdate(nextProps){
    return !nextProps.preventWidgetUpdate;
  }

  componentDidMount(){
    if(this.cy){
      this.cy.domNode({'dom_container': this.nodesContainer});

      this.cy.on('dragfreeon', 'node', (evt) => {
        const node = evt.target;

        if (!_.isEqual(node.position(), this.topologyNodePositionsMap[node.id()])) {
          const nodePositions = this.getTopologyNodePositions();
          this.props.updateTopologyNodePositions(nodePositions);
        }
      });
      this.cy.on('mouseover', 'node', (evt) => {
        this.generateTooltipOnNode(evt.target);
        if(evt.target.tippy){
          evt.target.tippy.show();
        }
      });
      this.cy.on('mouseout', 'node', (evt) => {
        if(evt.target.tippy){
          evt.target.tippy.hide();
        }
      });
      this.cy.on('click', 'node', this.onNodeClick);
      this.cy.on('click', 'edge', this.onEdgeClick);
      
      this.cy.one('layoutstop', (evt) => {
        this.cy.fit();
      });

      this.cy.on('layoutstop', (evt) => {
        if(this.updateLayout){
          this.cy.fit();
          this.updateLayout = false;
        }
      });

      this.cy.on('resize', (evt) => {
        if(this.props.preview){
          this.cy.fit();
        }
      });

      const props = fromJS(this.cyComponent.props).toJS();

      this.cyComponent.updateCytoscape(null, props);

    }
    window.addEventListener('onAfterDataRetrieved', this.onAfterDataRetrieved);
  }

  onAfterDataRetrieved(e){
    if(e.detail?.widgetId == this.props.widgetId){
      this.dataRetrieved = true;
    }
  }

  componentDidUpdate(prevProps){
    if(this.cy 
      && !this.props.preview 
      && (prevProps.style.height !== this.props.style.height) 
      || (prevProps.style.width !== this.props.style.width)){
      this.cy.resize();
      this.cy.fit();
    }
    if(this.props.topologyLayout != topologyUtils.TOPOLOGY_LAYOUTS.TOPOLOGY_SAVED_LAYOUT
      && this.dataRetrieved
    ){
      this.dataRetrieved = false;
      this.updateLayout = true;
      this.cy.layout(this.props.layout).run();
    }
    if(!Utils.isBlank(this.props.topologyFormat.searchWord)){
      this.cy.emit('search');
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.cy){
      if(nextProps.isResetTopologyViewPort && this.props.isResetTopologyViewPort !== nextProps.isResetTopologyViewPort){
        this.handleResetViewPort();
        this.props.onResetChartZooming(false);
      }
      if((nextProps.topologyLayout != this.props.topologyLayout 
        || nextProps.topologyFormat.topologyLayoutDirection != this.props.topologyFormat.topologyLayoutDirection
        || nextProps.topologyFormat.searchWord != this.props.topologyFormat.searchWord
        || nextProps.topologyFormat.showDirectionArrow != this.props.topologyFormat.showDirectionArrow )
        && nextProps.topologyLayout != topologyUtils.TOPOLOGY_LAYOUTS.TOPOLOGY_SAVED_LAYOUT
      ){
        this.updateLayout = true;
      }
    }
  }

  componentWillUnmount(){
    if(this.cy){
      this.cy.removeAllListeners();
    }
    window.removeEventListener('onAfterDataRetrieved', this.onAfterDataRetrieved);
  }

  onEdgeClick(evt){
    if(this.props.isLinkSettingsValid()){
      const edge = evt.target;
      const sourceNodeLabel = edge.source().data('label');
      const destionationNodeLabel = edge.target().data('label');
      const link = this.getLinkObject(edge);
      const title = `${sourceNodeLabel} <> ${destionationNodeLabel}`;

      this.openPopUp(title, link);
    }
  }

  onNodeClick(evt){
    const nodeTarget = evt.target;
    const node = this.getNodeObject(nodeTarget.data());

    if(this.props.shouldSendNodeDrilldownRequest(node)){
      const nodeLabel = nodeTarget.data('label');
      const title = `${nodeLabel}`;
      
      this.openNodePopUp(title, node);
    }
  }

  generateTooltipOnNode(node){
    if(!Utils.isBlank(node.data().value) 
      && !Utils.isBlank(node.data().value.value)){
      this.makeTippyTooltip(node);
    } else {
      node.tippy = null;
    }
  }

  makeTippyTooltip(node) {
    const ref = node.popperRef(); 
    const dummyDomEle = document.createElement('div');
    const tooltipAttributeOptions = node.data().tooltipAttributeOptions;
    let tooltipPrefix = 'Value';

    node.tippy = new Tippy(dummyDomEle, { 
      trigger: 'manual',
      lazy: false, 
      onCreate: instance => { instance.popperInstance.reference = ref; },
      arrow: true,
      animation: 'fade',
      content: () => {
        const content = document.createElement('div');
        if(tooltipAttributeOptions && tooltipAttributeOptions.isSingleAttributeSelected){ 
          if(!Utils.isBlank(tooltipAttributeOptions.attributeSelectedName)){
            tooltipPrefix = tooltipAttributeOptions.attributeSelectedName;
          }
        }
        content.innerHTML = tooltipPrefix + ': '+ node.data().nodeTooltipFinal;
        
        return content;
      },
    });
  }

  getLinkObject(edge){
    return {
      id: edge.data().id,
      source: edge.data().source,
      target: edge.data().target,
      originalSource: edge.data().originalSource,
      originalTarget: edge.data().originalTarget,
    };
  }

  getNodeObject(nodeData){
    return {
      id: nodeData.id,
      ref: nodeData.ref,
      dataType: nodeData.dataType,
      dataValue: nodeData.dataValue,
      fieldNames: nodeData.fieldNames,
    };
  }

  getFieldType(field){
    if(field.aggregationType){
      return AggregationUtils.getAggregationType(field.aggregationType);
    }

    return field.selectedField.type;
  }

  getNodeHeader(fields = [], showAggregation, settingsType){
    const repeateFieldsData = showAggregation ? DataSelectionCommonUtils.getRepeatedAggFieldsData(fields) : {};
    
    return fields.reduce((filteredFields, field) => {
      if(!(settingsType == DataSelectionCommonUtils.FGA_TYPE_TOPN && FieldTypes.isDateField(field.selectedField))){
        filteredFields.push({
          headerName: showAggregation ? DataSelectionCommonUtils.getCompoundAggregateFieldName(field, {multipleAggregateOnSameKpi: true}) : field.selectedField.fieldName,
          fieldname: DataSelectionCommonUtils.getCompoundAggregateFieldName(field, repeateFieldsData),
          esName: field.selectedField.elasticsearchName,
          attributeId: field.selectedField.id,
          attributeUnit: field.fieldUnitFactor ||  UnitConversionUtils.DEFAULT_UNIT_DIVIDER,
          type: this.getFieldType(field),
          isRef: FieldTypes.isRefField(field.selectedField),
          isDate: FieldTypes.isDateField(field.selectedField),
          baseUnit: DataSelectionCommonUtils.getFieldBaseUnit(field),
          dataType: DataSelectionCommonUtils.getFieldDataType(field),
        });
      }

      return filteredFields;
    }, []);
  }

  getConvertedValueWithUnit(value, unit, header){
    const convertedValue = UnitConversionUtils.getFinalValueForm(value, unit, header.baseUnit, 2, header.dataType);
    const unitLabel = Utils.isEmpty(convertedValue) ? '' : UnitConversionUtils.getUnitLabel(unit, header.baseUnit, header.dataType);

    return `${convertedValue}${Utils.isEmpty(unitLabel) ? '' : ' ' + unitLabel}`
  }

  getTableColumns(dataConfig, onClickAttribute, drilldownBtnTooltip, defaultDrillDownChartType){
    const settingsType = dataConfig.settingsType;
    const topNSettings = dataConfig.topNSettings || {};
    const groupByNodeHeaders = this.getNodeHeader(dataConfig.groupByFields, null, settingsType);
    const topNFieldName = settingsType == DataSelectionCommonUtils.FGA_TYPE_TOPN ? DataSelectionCommonUtils.getCompoundAggregateFieldName(topNSettings.topNField, {multipleAggregateOnSameKpi: true}) : null;

    const fgaFields = [
      ...groupByNodeHeaders,
      ...this.getNodeHeader(dataConfig.fields, true),
      ...this.getNodeHeader(dataConfig.columns),
    ];
    const fgaFieldsLength = fgaFields.length;
    const width = fgaFieldsLength > 0 ?  Math.floor(topologyUtils.COLUMNS_TOTAL_WIDTH / fgaFieldsLength) 
      : topologyUtils.COLUMNS_TOTAL_WIDTH;
      
    return fgaFields.map((header) => {
      const isSortingColumn = topNFieldName && (header.headerName == topNFieldName);
      const sortingColIcon = isSortingColumn
        && topNSettings.nDirection == DESC ? Icons.arrowDown + Icons.styles.evLg :
        isSortingColumn && topNSettings.nDirection == ASC ? Icons.arrowUp + Icons.styles.evLg : null;
          
      return {
        header: {
          label: header.headerName,
          props:{
            style:{
              width:`${width}%`,
            },
          },
          sortableFormatedHeader : () => (
            <div>
              {header.headerName}
              &nbsp;
              {settingsType == DataSelectionCommonUtils.FGA_TYPE_TOPN ?
                <i className={sortingColIcon}></i> : null }
            </div>
          ),
        },
        property: `${header.fieldname}`,
        cell:{
          formatters:[
            (value, {rowData}) => {
              if(header.isDate){
                return DateTimeUtils.epochToDateFormat(value); 
              }else if(!FieldTypes.isNumericField(header) && header.isRef){
                return ArrayUtils.joinStrings(value.NETWORK_ELEMENT_TREE_PATH || value.value);
              }else if(header.type && !Utils.isBlank(value.value) ){  
                const unit = header.attributeUnit || UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
                const fullConvertedValue = Array.isArray(value.value) ? value.value.map(singleValue => 
                  this.getConvertedValueWithUnit(singleValue, unit, header)).join(', ')
                  : this.getConvertedValueWithUnit(value.value, unit, header);                
                const title = header.headerName;
                const hasDrilldown = (
                  (settingsType == DataSelectionCommonUtils.FGA_TYPE_AGGREGATE
                    && !DataSelectionCommonUtils.hasDateInGrouping(dataConfig.groupByFields))
                  || settingsType == DataSelectionCommonUtils.FGA_TYPE_TOPN)
                  && this.props.drillDown
                  && !Utils.isEmpty(value.value)
                  && FieldTypes.isNumericField(header);
                
                let drillDownButton = null;

                if(hasDrilldown){
                  const topologyCellDrilldownFilter = {
                    Aggregate: header.fieldname,
                  };

                  groupByNodeHeaders.forEach(field => {
                    let value = rowData[field.fieldname];

                    if(field.isRef){
                      value = rowData[field.fieldname].NETWORK_ELEMENT_ID;
                    }

                    if(!Utils.isEmpty(value)){
                      topologyCellDrilldownFilter[field.esName] = value;
                    }
                  });

                  const item = {
                    attributeId: header.attributeId, 
                    title,
                    unit,
                    topologyCellDrilldownFilter,
                    defaultDrillDownChartType,
                  };

                  drillDownButton = (ButtonsConstructor.getButtonWrapper(
                    'TooltippedButton', 
                    drilldownBtnTooltip, 
                    Icons.drilldown, 
                    'btn-action pull-right row-padding', 
                    onClickAttribute,
                    item
                  ));
                }

                return (
                  <div className='drilldownCell'>
                    <div  className={`${ this.props.drillDown ? 'drilldownValue' : ''}`}>
                     {fullConvertedValue}
                    </div>                    
                    {drillDownButton}
                  </div>
                );
              }

              return ArrayUtils.joinStrings(value);
            },
          ],
        },
      };
    });
  }

  getTableLinkColumns(){
    const topologyDataSettings = this.props.topologyDataSettings;

    const dataConfig = {
      settingsType: topologyDataSettings.linkSettingsType,
      topNSettings: topologyDataSettings.topNLinkSettings, 
      fields: topologyDataSettings.linkSettingsFfag.fields,
      columns: topologyDataSettings.linkSettingsFfag.columns,
      groupByFields: topologyDataSettings.linkSettingsFfag.groupByFields,
    };
  
    return this.getTableColumns(dataConfig, this.onClickLinkAttribute, 
      'DrillDown Link Data', this.props.topologyDataConfig.linkDrillDownChartType);
  }

  getTableNodeColumns(){
    if(this.state.data && this.dataIdentifier){
      const dataConfig = 
        this.props.topologyDataConfig.nodeDrillDownTopologyMappings[this.dataIdentifier];

      return this.getTableColumns(dataConfig, this.onClickNodeAttribute, 
        'DrillDown Node Data', this.props.topologyDataConfig.nodeDrillDownChartType);
    }

    return [];
  }
 
  onClickLinkAttribute(item){
    this.props.drillDown({ 
      isTopologyDrilldown: true,
      link: this.link,
      title: item.title,
      isNodeDrilldown: false,
      unit: item.unit,
      topologyCellDrilldownFilter: item.topologyCellDrilldownFilter,
      defaultDrillDownChartType: item.defaultDrillDownChartType,
    });
    this.closePopUp();
  }
  
  onClickNodeAttribute(item) {
    this.props.drillDown({ 
      isTopologyDrilldown: true,
      isNodeDrilldown: true,
      node: this.node,
      title: item.title,
      unit: item.unit,
      topologyCellDrilldownFilter: item.topologyCellDrilldownFilter,
      defaultDrillDownChartType: item.defaultDrillDownChartType,
    });
    this.closePopUp();
  }
  
  getTopologyNodePositions(){
    this.topologyNodePositionsMap = {};

    this.cy.nodes().forEach(node => {
      this.topologyNodePositionsMap[node.id()] = fromJS(node.position()).toJS();
    });

    return this.topologyNodePositionsMap;
  }

  closePopUp(){
    this.link = null;
    this.node = null;

    this.setState({
      showPopup: false,
      showNodePopup: false,
      data: undefined,
      nodeData: undefined,
    });
  }

  openPopUp(popupTitle, link){
    this.link = link;
    this.setState({
      showPopup: true,
      data: undefined,
      popupTitle,
    });

    this.props.getTopologyData(link, false)
      .then((data) => {
        this.setState({data: data.dataPoints, drillDownWidgetParameterStatus : undefined});
      })
      .catch((error => {
        if(error && error.codeStatus == RequestUtils.ERR_CODES.UNPROCESSABLE_ENTITY_CODE){
          this.onGetTopologyDataError(this.props.topologyDataSettings.linkSettingsType, error.text);
        }
      }));
  }
  openNodePopUp(nodePopupTitle, node){
    this.node = node;
    this.setState({
      showNodePopup: true,
      nodeData: undefined,
      nodePopupTitle,
    }, () => {
      this.props.getTopologyData(node, true)
        .then((data) => {
          this.dataIdentifier = data.dataIdentifier;
    
          this.setState({
            data: this.dataIdentifier ? data.dataPoints : [],
            drillDownWidgetParameterStatus: this.dataIdentifier ? undefined : this.NO_MAPPING_FOUND,
          });
        })
        .catch((error => {
          if(error && error.codeStatus ==  RequestUtils.ERR_CODES.UNPROCESSABLE_ENTITY_CODE){
            this.onGetTopologyDataError(this.dataIdentifier ? 
              this.props.topologyDataConfig.nodeDrillDownTopologyMappings[this.dataIdentifier].settingsType : '', error.text);
          }
        }));
    });
  }

  onGetTopologyDataError(settingsType, errorStatus = CANNOT_RETRIEVE_DATA) {
    let icon = null;

    switch (settingsType) {
    case DataSelectionCommonUtils.FGA_TYPE_TOPN:
      icon = Icons.topNTable;
      break;
    default:
      break;
    }
    this.setState({ data: [], drillDownWidgetParameterStatus: errorTexts[errorStatus], drillDownWidgetIcon: icon });
  }

  handleResetViewPort(){
    this.cy.fit();
  }

  onWheelHandling(e){
    e.stopPropagation();
  }

  render() {
    return (
      <div  onWheel={this.onWheelHandling} className="topologyWrapper" style={{height: this.props.style.height, width: this.props.style.width}}>        
        {
          this.state && this.state.showPopup ?
            <TopologyDetailsPopup
              backColor={this.state.color}
              onHide={this.closePopUp}
              title={this.state.popupTitle}
              show={this.state.showPopup}
              data={this.state.data}
              columns={this.getTableLinkColumns()}
              rowKey='Link.value'
              drillDownWidgetParameterStatus={this.state.drillDownWidgetParameterStatus}
              icon={this.state.drillDownWidgetIcon}
            />
          : null
        }
        {
          this.state && this.state.showNodePopup ?        
            <TopologyDetailsPopup
              backColor={this.state.color}
              onHide={this.closePopUp}
              title={this.state.nodePopupTitle}
              show={this.state.showNodePopup}
              data={this.state.data}
              columns={this.getTableNodeColumns()}
              rowKey='Node.value'
              drillDownWidgetParameterStatus={this.state.drillDownWidgetParameterStatus}
              icon={this.state.drillDownWidgetIcon}
            />
            : null
        }
        <div className='topology-node-container'
          ref={ref => this.nodesContainer = ref} />
        <CytoscapeComponent
          {...this.props}
          layout={this.props.layout}
          ref={ ref => {
            this.cyComponent = ref; 
            this.props.setWidgetRef?.(ref);
          }}
          cy={(cy) => { 
            this.cy = cy;
          }} 
        />
      </div>
    ); 
  }
}
