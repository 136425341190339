/* Copyright 2019 Greyskies. All Rights Reserved. */

const USER_RIGHT = {
  OWNER: 'OWNER',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
};

const NETWORK_ELEMENT = 'NETWORK_ELEMENT';
const DESIGN_SPACE = 'DESIGN_SPACE';
const FULL_TREE_PATH = 'NETWORK_ELEMENT.NETWORK_ELEMENT_TREE_PATH';
const ORGANIZATION_GROUP = 'ORGANIZATION_GROUP';

const RECORDS = 'RECORDS';
const RECORDS_FOR_ATTRIBUTES = 'RECORDS_FOR_ATTRIBUTES';
const ATTRIBUTES = 'ATTRIBUTES';
const ATTRIBUTE_NAME = 'ATTRIBUTE';
const ATTRIBUTE_TYPE = 'ATTRIBUTE_TYPE';
const MAX_SHOWN_ITEMS = 3;

const DRP_DEFAULT_RELATIVE_DATE_REFRESH_OFF = 'Today';
const DRP_DEFAULT_RELATIVE_DATE_REFRESH_ON = 'Last Hour';

const ACTIONS = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  DELETE: 'DELETE',
};

const refFilterOperation = 'in';
const dateFilterOperation = 'inTimeRange';
const SELECT_NE = 'Select Network Element';
const SELECT_DATE = 'Select Date';
const DELETE_FIELD = 'Delete Aggregation';
const DELETE_FILTER = 'Delete Filter';
const FIELD = 'Aggregate';
const FILTER = 'Filter';
const SELECT_ATTR = 'Select Attribute';
const AGGREGATES = 'Aggregates';
const SELECT_AGGREGATE = 'Select Aggregate';
const SELECT_WEIGHT_ATTR = 'Select Weight Attribute';
const SELECT_AGGREGATION = 'Select Aggregation';
const SELECT_OPERATION = 'Select Operation';
const ADD_Field = 'Add Aggregation';
const ADD_FILTER = 'Add Filter';
const ADD_GROUP_BY = 'Add Group By';
const FILTER_VALUE = 'Filter Value';
const ADVANCED_FILTER = 'Advanced Filter';
const DATA_STRUCTURE_ID = 'dataStructureId';
const FOR = 'For';
const QUICK_FILTER = 'Quick Filter';
const NEW_FILTER = 'New Filter';
const APPLIED_FILTERS = 'Applied Filters';
const COMPONENT_NODE_TYPE = 'NODE'; 
const START_TIME = 'Start Time';
const FROM = 'From';
const END_TIME = 'End Time';
const TO = 'To';
const TIME_RANGE = 'Time range';
const START_TIME_DEFAULT = '00:00:00';
const END_TIME_DEFAULT =  '23:59:00';
const RESOLUTIONS = {DAILY: 'Daily', WEEKLY: 'Weekly', MONTHLY: 'Monthly'};
const RESOLUTIONS_KEYS = Object.keys(RESOLUTIONS);
const DAILY = Object.keys(RESOLUTIONS)[0];
const WEEKLY = Object.keys(RESOLUTIONS)[1];
const MONTHLY = Object.keys(RESOLUTIONS)[2];
const SELECT_SOURCE_TYPE = "source type";
const SELECT_OBJECT_KEY = 'Select Object Key';

export {
  USER_RIGHT,
  NETWORK_ELEMENT,
  DESIGN_SPACE,
  FULL_TREE_PATH,
  ORGANIZATION_GROUP,
  RECORDS,
  RECORDS_FOR_ATTRIBUTES,
  ATTRIBUTES,
  ATTRIBUTE_NAME,
  ATTRIBUTE_TYPE,
  MAX_SHOWN_ITEMS,
  DRP_DEFAULT_RELATIVE_DATE_REFRESH_OFF,
  DRP_DEFAULT_RELATIVE_DATE_REFRESH_ON,
  ACTIONS,
  refFilterOperation,
  dateFilterOperation,
  SELECT_NE,
  SELECT_DATE,
  DELETE_FIELD,
  DELETE_FILTER,
  FIELD,
  SELECT_ATTR,
  SELECT_AGGREGATE,
  AGGREGATES,
  SELECT_AGGREGATION,
  ADD_Field,
  ADD_FILTER,
  FILTER,
  SELECT_WEIGHT_ATTR,
  SELECT_OPERATION,
  ADD_GROUP_BY,
  FILTER_VALUE,
  ADVANCED_FILTER,
  DATA_STRUCTURE_ID,
  FOR,
  QUICK_FILTER,
  NEW_FILTER,
  APPLIED_FILTERS,
  COMPONENT_NODE_TYPE,
  START_TIME,
  FROM,
  END_TIME,
  TO,
  TIME_RANGE,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
  RESOLUTIONS,
  RESOLUTIONS_KEYS,
  DAILY,
  WEEKLY,
  MONTHLY,
  SELECT_SOURCE_TYPE,
  SELECT_OBJECT_KEY,
};
