/* Copyright 2019 Greyskies. All Rights Reserved. */

export const CollectionProfile = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionProfiles/CollectionProfiles'),
    ),
    'CollectionProfiles',
  );
};

export const SyntheticCollectionProfile = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionProfiles/CollectionProfiles'),
    ),
    'SyntheticCollectionProfiles',
  );
};

export const ConfigurationCollectionProfile = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionProfiles/CollectionProfiles'),
    ),
    'ConfigurationCollectionProfiles',
  );
};


export const AnalystActivation = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/AnalystActivation'),
    ),
    'AnalystActivation',
  );
};
export const AutomationWorkflowActivation = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AutomationWorkflow/AutomationWorkflowActivation'),
    ),
    'AutomationWorkflowActivation',
  );
};
export const CollectionConfiguration = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionProfiles/CollectionConfiguration'),
    ),
    'CollectionConfiguration',
  );
};

export const EventLogs = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Logs/EventLogs'),
    ),
    'EventLogs',
  );
};
export const UserLogs = (nextState, cb ) => {
	  require.ensure(
	    [],
	    require => cb(
	      null,
	      require('components/pages/Logs/UserLogs'),
	    ),
	    'UserLogs',
	  );
};

export const SystemSettings = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/SystemSettings/SystemSettings'),
    ),
    'SystemSettings',
  );
};
export const UserManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AccessManagement/UserManagement/UserManagement'),
    ),
    'UserManagement',
  );
};

export const SetUser = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AccessManagement/UserManagement/SetUser'),
    ),
    'SetUser',
  );
};

export const AdminDefined = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AdminDefined/AdminDefinedValue'),
    ),
    'AdminDefinedValue',
  );
};

export const NetworkElementManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/NetworkElementManagement/NetworkElementManagement'),
    ),
    'NetworkElementManagement',
  );
};

export const NetworkElementConfiguration = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/NetworkElementManagement/NetworkElementConfiguration'),
    ),
    'NetworkElementConfiguration',
  );
};

export const EventManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/EventManagement'),
    ),
    'EventManagement',
  );
};

export const SetEvent = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/SetEvent'),
    ),
    'SetEvent',
  );
};

export const AddTag = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Tags/AddTag'),
    ),
    'AddTag',
  );
};

export const Tags = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Tags/Tags'),
    ),
    'Tags',
  );
};

export const AutomationRunsInfo = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/AutomationRunsInfo'),
    ),
    'AutomationRunsInfo',
  );
};

export const AutomationRunRecordFilterPopup = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/AutomationRunRecordFilterPopup'),
    ),
    'AutomationRunRecordFilterPopup',
  );
};

export const TriggeredEvents = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Events/TriggeredEvents'),
    ),
    'TriggeredEvents',
  );
};

export const License = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/License/License'),
    ),
    'License',
  );
};

export const Lookup = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Lookup/Lookup'),
    ),
    'Lookup',
  );
};
export const AddLookupTable = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Lookup/AddLookupTable'),
    ),
    'AddLookupTable',
  );
};


export const GroupManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/GroupManagement/ManageGroups'),
    ),
    'GroupManagement',
  );
};

export const SetGroup = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/GroupManagement/SetGroup'),
    ),
    'SetGroup',
  );
};

export const ManageOwnership = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/OwnershipManagement/ManageOwnership'),
    ),
    'ManageOwnership',
  );
};

export const CollectionMethodManagement = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionMehod/CollectionMethodManagement'),
    ),
    'CollectionMethodManagement',
  );
};

export const DataBindingManagement = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataBinding/DataBindingManagement'),
    ),
    'DataBindingManagement',
  );
};

export const ImportDataBinding = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataBinding/ImportDataBinding'),
    ),
    'ImportDataBinding',
  );
};

export const SetCollectionMethod = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionMehod/SetCollectionMethod'),
    ),
    'SetCollectionMethod',
  );
};

export const ImportCollectionMethod = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CollectionMehod/ImportCollectionMethod'),
    ),
    'ImportCollectionMethod',
  );
};

export const SetDataBinding = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataBinding/SetDataBinding'),
    ),
    'SetDataBinding',
  );
};

export const PushBasedEntityMappingManagement = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/PushBasedEntityMapping/PushBasedEntityMappingManagement'),
    ),
    'PushBasedEntityMappingManagement',
  );
};

export const SetPushBasedEntityMapping = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/PushBasedEntityMapping/SetPushBasedEntityMapping'),
    ),
    'SetPushBasedEntityMapping',
  );
};

export const FFAG = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Ffag/FFAGs'),
  ),
  'FGA',
);

export const AddFFAG = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Ffag/AddFFAG'),
  ),
  'AddFga',
);


export const TemplatesPlaceholders = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/TemplatesPlaceholder/TemplatesPlaceholders'),
  ),
  'TemplatePlaceholders',
);

export const TopologySettings = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/TopologySettings/TopologyLayoutSettings'),
  ),
  'TopologySettings',
);


export const SetTemplatesPlaceholder = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/TemplatesPlaceholder/SetTemplatesPlaceholder'),
  ),
  'SetTemplatesPlaceholder',
);

export const EventHealing = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/EventHealing/EventHealing'),
    ),
    'EventHealing',
  );
};

export const AddEventHealing = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/EventHealing/AddEventHealing'),
    ),
    'AddEventHealing',
  );
};

export const NBI = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/NBI/NBIManagement'),
    ),
    'NBIManagement',
  );
};

export const SetNBI = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/NBI/SetNBI'),
    ),
    'SetNBI',
  );
};

export const DesignSpaceOrganizer = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Organizer/DesignSpaceOrganizer'),
  ),
  'DesignSpaceOrganizer',
);

export const RecordsManagement = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Records/RecordsManagement'),
    ),
    'RecordsManagement',
  );

export const SetRecord = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Records/SetRecord'),
    ),
    'SetRecord',
  );

export const AuditingTemplatesManagement = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AuditingTemplates/AuditingTemplatesManagement'),
    ),
    'AuditingTemplatesManagement',
  );

export const SetAuditingTemplate = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AuditingTemplates/SetAuditingTemplate'),
    ),
    'SetAuditingTemplate',
  );

export const RollUpPlansManagement = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RollUpPlans/RollUpPlansManagement'),
    ),
    'RollUpPlansManagement',
  );

export const SetRollUpPlan = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RollUpPlans/SetRollUpPlan'),
    ),
    'SetRollUpPlan',
  );

export const SetLakehouseRetentionPlan = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RollUpPlans/setLakehouseRetentionPlan'),
    ),
    'setLakehouseRetentionPlan',
  );

export const OrganizationGroupManagement = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/OrganizationGroup/OrganizationGroupManagement'),
    ),
    'OrganizationGroupManagement',
  );

export const SetOrganizationGroup = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/OrganizationGroup/SetOrganizationGroup'),
    ),
    'SetOrganizationGroup',
  );


export const RoutingGroups = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RoutingGroup/RoutingGroupsManagement'),
    ),
    'RoutingGroups',
  );

export const SetRoutingGroup = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RoutingGroup/SetRoutingGroup'),
    ),
    'SetRoutingGroup',
  );


export const DataFlows = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RoutingGroup/DataFlowsManagement'),
    ),
    'DataFlows',
  );

export const SetDataFlow = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/RoutingGroup/SetDataFlow'),
    ),
    'SetDataFlow',
  );


export const DashboardLayout = (nextState, cb) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Dashboard/DashboardLayout')),
  'DashboardLayout',
);
export const DashboardConfig = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Dashboard/DashboardConfig'),
  ),
  'DashboardConfig',
);

export const ImportDashboards = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Dashboard/ImportDashboards'),
  ),
  'ImportDashboards',
);

export const ManageDashboards = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Dashboard/ManageDashboards'),
  ),
  'ManageDashboards',
);

export const AssociateWidget = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Dashboard/AssociateWidget'),
  ),
  'AssociateWidget',
);

export const ReportDesignerLayout = ( nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require(
        'components/pages/Reports/ReportDesignerLayout'),
    ),
    'ReportDesignerLayout',
  );

export const ReportConfig = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Reports/ReportConfig'),
  ),
  'ReportConfig',
);

export const ImportReports = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Reports/ImportReports'),
  ),
  'ImportReports',
);

export const ManageReports = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Reports/ManageReports'),
  ),
  'ManageReports',
);

export const DownloadReport = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Reports/DownloadReport'),
  ),
  'DownloadReport',
);

export const ScheduledReports = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Exporting/ScheduledReports/ScheduledReports'),
  ),
  'ScheduledReports',
);

export const AddScheduledReport = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Exporting/ScheduledReports/AddScheduledReport'),
  ),
  'AddScheduledReport',
);

export const HistoricalExports = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Exporting/ScheduledReports/HistoricalExports'),
  ),
  'HistoricalExports',
);

export const Analysis = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Analysis/Analysis'),
  ),
  'Analysis',
);

export const DataModelTree = (nextState, cb ) =>
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataModelTree/DataModelTree'),
    ),
    'DataModelTree',
  );

export const SetDataModelTreeAttribute = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataModelTree/SetAttribute'),
    ),
    'SetAttribute',
  );
};

export const SetDataModelTreeContainer = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataModelTree/SetContainer'),
    ),
    'SetContainer',
  );
};

export const SetDataModelTreeList = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/DataModelTree/SetList'),
    ),
    'SetList',
  );
};


export const UnreachabilityPlanManagement = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/UnreachabilityPlan/UnreachabilityPlansManagement'),
    ),
    'UnreachabilityPlansManagement',
  );
};

export const SetUnreachabilityPlan = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/UnreachabilityPlan/SetUnreachabilityPlan'),
    ),
    'SetUnreachabilityPlan',
  );
};

export const MLJobsManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/MLJobs/MLJobsManagement'),
    ),
    'MLJobsManagement',
  );
};

export const SetMLJob = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/MLJobs/SetMLJob'),
    ),
    'SetMLJob',
  );
};

export const CPBlockListing = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/CPBlockList/CPBlockListing'),
    ),
    'CPBlockListing',
  );
};

export const ConfigurationListing = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/ConfigurationListing/ConfigurationListing'),
    ),
    'ConfigurationListing',
  );
};

export const ConfigurationFileView = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/ConfigurationListing/ConfigurationFileView'),
    ),
    'ConfigurationFileView',
  );
};

export const ConfigurationDifference = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/ConfigurationListing/ConfigurationDifference'),
    ),
    'ConfigurationDifference',
  );
};


export const AutomationWorkflow = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AutomationWorkflow/AutomationWorkflows'),
    ),
    'AutomationWorkflows',
  );
};

export const AddAutomationWorkflow = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AutomationWorkflow/AddAutomationWorkflow'),
    ),
    'AddAutomationWorkflow',
  );
};

export const AttributeTypes = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/AttributeType/AttributeTypesManagement'),
  ),
  'AttributeTypes',
);

export const SetAttributeType = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/AttributeType/SetAttributeType'),
  ),
  'SetAttributeType',
);

export const LDAPConfigManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AccessManagement/LDAPConfigManagement/LDAPConfigManagement'),
    ),
    'LDAPConfigManagement',
  );
};

export const SetLDAPConfig = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/AccessManagement/LDAPConfigManagement/SetLDAPConfig'),
    ),
    'SetLDAPConfig',
  );
};
export const FileExport = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Exporting/ExportFiles/FileExport'),
    ),
    'FileExport',
  );
};
export const SetFileExport = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Exporting/ExportFiles/SetFileExport'),
    ),
    'SetFileExport',
  );
};
export const ScheduledFileExports = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Exporting/ExportFiles/ScheduledFileExports'),
  ),
  'ScheduledFileExports',
);

export const AddScheduledFileExport = ( nextState, cb ) => require.ensure(
  [],
  require => cb(
    null,
    require('components/pages/Exporting/ExportFiles/AddScheduledFileExport'),
  ),
  'AddScheduledFileExport',
);

export const CustomAnalyticsListing = (nextState, cb) =>
  require.ensure(
    [],
    (require) =>
      cb(
        null,
        require('components/pages/CustomAnalytics/CustomAnalyticsManagement'),
      ),
    'CustomAnalyticsListing',
  );

export const AddCustomAnalytics = (nextState, cb) =>
  require.ensure(
    [],
    (require) =>
      cb(
        null,
        require('components/pages/CustomAnalytics/AddCustomAnalytics'),
      ),
    'AddCustomAnalyticsProject',
  );

export const AnalyticsRunsInfo = (nextState, cb) =>
  require.ensure(
    [],
    (require) =>
      cb(
        null,
        require('components/pages/CustomAnalytics/AnalyticsRunsInfo'),
      ),
    'AnalyticsRunsInfo',
  );

export const Otel = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Otel/Traces'),
    ),
    'Otel',
  );
};

export const TraceDetails = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Otel/TraceDetails'),
    ),
    'TraceDetails',
  );
};

export const IncidentManagement = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Incidents/IncidentManagement'),
    ),
    'IncidentManagement',
  );
};

export const SetIncident = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Incidents/SetIncident'),
    ),
    'SetIncident',
  );
};

export const IncidentExplorer = (nextState, cb ) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Incidents/Explorer/IncidentExplorer'),
    ),
    'IncidentExplorer',
  );
};

export const ImportIncident = (nextState, cb) => {
  require.ensure(
    [],
    require => cb(
      null,
      require('components/pages/Incidents/ImportIncident'),
    ),
    'ImportIncident',
  );
};