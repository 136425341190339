/* Copyright 2019 Greyskies. All Rights Reserved. */

import $ from 'jquery';
import { NotificationManager } from 'react-notifications';
import defaults from 'config/defaults';
import { RequestUtils } from 'js-utils';
const getCookie = function (name) {
  const cookie = (document.cookie || '')
    .split('; ')
    .find(row => row.startsWith(`${name}=`));

  if (cookie) {
    return cookie.split('=')[1];
  }
};

export default function (
  url,
  { method = 'GET', responseType = 'json', contentType = 'json', data, getFileName = false } =
  { method: 'GET', responseType: 'json', getFileName: false },
) {
  const fetchOptions = {
    credentials: 'same-origin',
    headers: new Headers(),
    method,
  };

  fetchOptions.headers.set('X-XSRF-TOKEN', getCookie('XSRF-TOKEN') || '');

  if (data) {
    switch (method.toUpperCase()) {
    case 'GET':
    case 'DELETE':
      url = `${url}?${$.param(data)}`;
      break;
    case 'POST':
    case 'PUT':
      switch (contentType.toLowerCase()) {
      case 'url':
        fetchOptions.body = $.param(data);
        fetchOptions.headers.set(
          'Content-Type', 'application/x-www-form-urlencoded',
        );
        break;
      case 'json':
        fetchOptions.body = JSON.stringify(data);
        fetchOptions.headers.set('Content-Type', 'application/json');
        break;
      case 'multipart/form-data':
        fetchOptions.body = data;
      }
      break;
    }
  }

  return fetch(url, fetchOptions).then(
    response => {
      if (response.ok) {
        if (response.status === defaults.TOO_MANY_SELECTIONS_STATUS_CODE) {
          throw {
            codeStatus: response.status,
          };
        }

        if (getFileName) {
          return {
            data: response[responseType.toLowerCase()](),
            fileName: response.headers.get('Content-Disposition').split('filename=')[1],
          };
        }

        return response[responseType.toLowerCase()]();
      }
      if (response.status === defaults.UNPROCESSABLE_ENTITY_CODE) {
        return response.text().then(
          text => {
            throw {
              codeStatus: response.status,
              text,
            };
          },
        );
      } else if (response.status === RequestUtils.ERR_CODES.UNAUTHORIZED) {
        window.location.reload();
      } else if (response.status === RequestUtils.ERR_CODES.LOGOUT) {
        window.location.pathname = `${window.location.pathname}logout`;
      } else if (response.status === RequestUtils.ERR_CODES.CONTACT_SUPPORT) {
        NotificationManager.error(
          'Something wrong happened in the server. Please contact the Support Team.',
          'Internal Server Error', 10000);

        return response[responseType.toLowerCase()]();
      } else if (response.status === RequestUtils.ERR_CODES.SYSTEM_OVERLOAD) {
        NotificationManager.error(
          'System is overloaded, please try again in few minutes.'
          + ' If the problem persists, please contact Eventum Technical Support',
          'System Overloaded'
          , 10000);

        return response[responseType.toLowerCase()]();
      } else if (response.status === RequestUtils.ERR_CODES.FORBIDDEN
        || response.status === RequestUtils.ERR_CODES.LOCKED) {
        return response.text().then(
          text => {
            throw {
              text,
              errorStatus: response.status,
            };
          },
        );
      } else if (response.status === RequestUtils.ERR_CODES.LOGIC_FAILURE) {
        return response.text().then(
          text => {
            throw text;
          },
        );
      } else if (response.status === RequestUtils.ERR_CODES.CONCURENT_UPDATE) {
        NotificationManager.error('This page is being updated by another user'
          , 'Something wrong happened in the server', 10000);

        return;
      } else if (response.status === RequestUtils.ERR_CODES.FAILED_SCHEDULING) {
        NotificationManager.warning('Event is saved successfully but failed to schedule. Please save it again later.',
          'Something went wrong', 10000);

        return;
      }

      return response.text().then(
        text => {
          if (response.status === RequestUtils.ERR_CODES.INTERNAL_SERVER_ERROR) {
            NotificationManager.warning('Please refresh the page and try again',
              'Something went wrong', 10000);
          }
          throw text;
        },
      );
    },
  );
}
