/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as ThemeColors from './ThemeColors';

export function getLightTheme(){
  return ThemeColors.LIGHT;
}

export function getDarkTheme(){
  return ThemeColors.DARK;
}

export function getLayoutThemeValues(){
  return ThemeColors.layoutThemeValues;
}

export function getLabelColorForLight(layoutTheme){
  return ThemeColors.labelColorForLight[layoutTheme];
}

export const setTheme = (theme, updateTheme, setPreferedTheme, layoutTheme) => {
  document.getElementsByTagName('html')[0].setAttribute('data-theme', theme);
  document.getElementsByTagName('html')[0].setAttribute('data-layout-theme', layoutTheme);

  sessionStorage.setItem('theme', theme);
  sessionStorage.setItem('LayoutTheme', layoutTheme);
  
  updateTheme(theme);
  setPreferedTheme(theme);
  
};

export const getSqlEditorTheme = (preferedTheme, layoutTheme) => {
  return ThemeColors.editorsThemes.sql[layoutTheme][preferedTheme];
};
export const getTextEditorTheme = (preferedTheme, layoutTheme) => {
  return ThemeColors.editorsThemes.text[layoutTheme][preferedTheme];
};
export const getPanelColor = (preferedTheme, layoutTheme) => {
  return ThemeColors.defaultBackgroundColors[layoutTheme][preferedTheme];
};
export const getTopologyNodeBgColor = (preferedTheme, layoutTheme) => {
  return ThemeColors.defaultBackgroundColors[layoutTheme][preferedTheme];
};
export const getTopologyEdgeColor = (preferedTheme) => {
  return ThemeColors.toplogyEdgeColors[preferedTheme];
};
export const getTopologyLabelbgColor = (preferedTheme, layoutTheme) => {
  return ThemeColors.defaultBackgroundColors[layoutTheme][preferedTheme];
};
export const getTopologyLabelColor = (preferedTheme, layoutTheme) => {
  return ThemeColors.toplogyLabelColors[layoutTheme][preferedTheme];
};