/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';

export default class LoginAPI {
  static submitLogin = data => request('login', {
    method: 'POST',
    contentType: 'url',
    responseType: 'text',
    data,
  }).then( () => ({
    loginStatus: true,
  }))
    .catch( (errorObject) => ({
      loginStatus: false,
      errorStatusCode: errorObject.errorStatus,
    }));

  static getLoginTemplateData = () =>  request('getLoginTemplateData', {
    method : 'GET',
    responseType : 'json',
    contentType : 'json',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  static validateURLToken = (token, tokenType) =>  request('account/validateToken', {
    method : 'GET',
    responseType : 'json',
    data:{
      token,
      tokenType,
    },
  });

  static savePassword = (data) =>  request('account/savePassword', {
    method : 'POST',
    responseType : 'json',
    data,
  });

  static resetPassword = (email) =>  request('account/resetPassword', {
    method : 'GET',
    responseType : 'json',
    data:{
      email,
    },
  });

  static checkUserExists = (username, token) => request('account/checkUsernameExists', {
    data:{
      username,
      token
    }
  });

  static completeRegistration = (data) => request('account/completeRegistration', {
    method: 'POST',
    responseType: 'json',
    data,
  });
}
