/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';

export function getVariableSchema(name, max){

  return Joi.string()
    .required()
    .min(3)
    .max(max || ValidationUtils.VARCHAR_LENGTH)
    .regex(/^([a-zA-Z_])[a-zA-Z0-9_]*$/)
    .message(CommonWorkflowDefaults.ERR_MSGS.VARIABLE_INVALID_CHAR(name))
    .label(name)
    .error(ValidationUtils.getErrMsgString);
};


const SCHEMA = {
  variableName: getVariableSchema(CommonWorkflowDefaults.LABELS.VARIABLE_NAME),
};

export const validationFunction = ValidationUtils.validateSchema(SCHEMA);