/* Copyright 2019 Greyskies. All Rights Reserved. */

import { Typeahead, Token} from 'react-bootstrap-typeahead';
import React, { useEffect, createRef, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'styles/dataVisualization/tagsViewer.less';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const TagsViewer = (props) => {
  const ref = createRef();
  const [shownElements, setShownElements] = useState(props.values.length);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const parentContainerWidth = $($(ref.current).find('.rbt .rbt-input')?.[0]).width() - 40;

    const elementCount = $(ref.current)
      .find('.rbt .rbt-input .rbt-input-wrapper .rbt-token')
      .filter(function() {
        return $(this).position().left +  $(this).width() <= parentContainerWidth;
      }).length;

    setShownElements(elementCount);

  }, []);

  useEffect(() => {
    let placeholder = '';

    if(!expanded && props.values.length - shownElements > 0){
      placeholder = `+${props.values.length - shownElements}`;  
    } 
    $(ref.current).find('.rbt-input-main')
      .attr('placeholder', placeholder);
  },[expanded, shownElements]);

  const results = expanded ? props.values : props.values.slice(0, shownElements);

  return( 
    <div 
      ref={ref} 
      className={`ac-Wrapper tags-viewer  ${props.className || ''} ${expanded ? 'wrapped' : ''}`} 
      onClick={props.expandable ? () => setExpanded(!expanded) : undefined}
    >
      <Typeahead
        id={props.id}
        multiple 
        selected={results}
        options={results}
        disabled
        renderToken={(option, tokenProps, index) => (
          <OverlayTrigger
            rootClose placement="top" trigger={['hover', 'focus']}
            overlay={<Tooltip placement="top" className="in">{option}</Tooltip>}
          >
            <div className='tokenWrapper'>
              <Token {...tokenProps} > 
                {option}
              </Token>
            </div>
          </OverlayTrigger>
    
        )}
      />
    </div>
  );
};
