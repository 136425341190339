/* Copyright 2019 Greyskies. All Rights Reserved. */

const EVENTUM = 'eventum';
const GREYSKIES = 'greyskies';
const MOBILY = 'mobily';

export const DARK = 'dark';
export const LIGHT = 'light';

const WHITE = '#FFFFFF';
const BLACK = '#000000';
const eventumDarkBgColor = '#212227';
const greyskiesDarkBgColor = '#1B2838';
const mobilyDarkBgColor = '#232A2D';
const eventumLabelColorForDark = '#d4d4d4';
const greyskiesLabelColorForDark = '#F7F7FA';
const mobilyLabelColorForDark = '#F7F7FA';
const eventumLabelColorForLight = '#333';
const greyskiesLabelColorForLight = '#5A5466';
const mobilyLabelColorForLight = '#51565B';

const editorLightTheme = 'textmate';
const eventumEditorDarkTheme = 'tomorrow_night';
const greyskiesEditorDarkTheme = 'solarized_dark';
const mobilyEditorDarkTheme = 'gruvbox';

export const layoutThemeValues = { 
  eventum: EVENTUM,
  greyskies: GREYSKIES,
  mobily: MOBILY,
};

export const labelColorForLight = {
  [layoutThemeValues.eventum]: eventumLabelColorForLight,
  [layoutThemeValues.greyskies]: greyskiesLabelColorForLight,
};

export const defaultBackgroundColors = {
  [layoutThemeValues.eventum]: {
    [LIGHT]: WHITE,
    [DARK]: eventumDarkBgColor,
  },
  [layoutThemeValues.greyskies]: {
    [LIGHT]: WHITE,
    [DARK]: greyskiesDarkBgColor,
  },
  [layoutThemeValues.mobily]: {
    [LIGHT]: WHITE,
    [DARK]: mobilyDarkBgColor,
  },
};

export const toplogyEdgeColors = {
  [LIGHT]: BLACK,
  [DARK]: WHITE,
};

export const toplogyLabelColors = {
  [layoutThemeValues.eventum]: {
    [LIGHT]: eventumLabelColorForLight,
    [DARK]: eventumLabelColorForDark,
  },
  [layoutThemeValues.greyskies]: {
    [LIGHT]: greyskiesLabelColorForLight,
    [DARK]: greyskiesLabelColorForDark,
  },
  [layoutThemeValues.mobily]: {
    [LIGHT]: mobilyLabelColorForLight,
    [DARK]: mobilyLabelColorForDark,
  },
};

export const editorsThemes = {
  text: {
    [layoutThemeValues.eventum]: {
      [LIGHT]: editorLightTheme,
      [DARK]: eventumEditorDarkTheme,
    },
    [layoutThemeValues.greyskies]: {
      [LIGHT]: editorLightTheme,
      [DARK]: greyskiesEditorDarkTheme,
    },
    [layoutThemeValues.mobily]: {
      [LIGHT]: editorLightTheme,
      [DARK]: mobilyEditorDarkTheme,
    },
  },
  sql: {
    [layoutThemeValues.eventum]: {
      [LIGHT]: editorLightTheme,
      [DARK]: eventumEditorDarkTheme,
    },
    [layoutThemeValues.greyskies]: {
      [LIGHT]: editorLightTheme,
      [DARK]: greyskiesEditorDarkTheme,
    },
    [layoutThemeValues.mobily]: {
      [LIGHT]: editorLightTheme,
      [DARK]: mobilyEditorDarkTheme,
    },
  },  
};