/* Copyright 2019 Greyskies. All Rights Reserved. */

import BaseStore from './BaseStore';
import AppConstants from 'constants/AppConstants';
import {ThemeUtils} from 'react-template';
import UserManagementActions from 'actions/UserManagementActions';
import {DEFAULT_THEME} from 'config/defaults';
import utils from 'utils/Utils';
import { ImageConversionUtils } from 'js-utils';

class AppStore extends BaseStore{

  constructor(){
    super();
    this.checkingLogin = true;
    this.addActionHandler(AppConstants.GET_USER_DATA,
      this.onGetUserData.bind(this));
    this.addActionHandler(AppConstants.GET_APP_CONFIG,
      this.onGetAppConfigs.bind(this));
    this.addActionHandler(AppConstants.GET_BUILD_PROPS,
      this.onGetFooterProps.bind(this));
    this.registerWithDispatcher();
    this.setPreferedTheme = ::this.setPreferedTheme;
  }

  onGetUserData(action){
    if(action.data.expired){
      this.expired = true;
      this.userData = undefined;
      this.loggedIn = false;
    }else if(action.data.workflowPermissions){
      this.userData = action.data;
      ThemeUtils.setTheme(this.userData.preferedTheme, UserManagementActions.updateTheme, this.setPreferedTheme, this.userData.layoutTheme );
      this.loggedIn = true;
    }else{
      this.userData = undefined;
      this.loggedIn = false;
    }
    this.checkingLogin = false;
    if(this.getUserData()){
      utils.addFavicon(ImageConversionUtils.pngToBase64URL(this.getUserData().headerLogo));
    }
    this.emitChange();
  }

  onGetAppConfigs(action){
    this.appConfig = action.data;
    this.emitChange();
  }

  onGetFooterProps(action){
    this.footerProps = action.data;
    this.emitChange();
  }

  getFooterProps(){
    return this.footerProps;
  }

  getColorPaletteType(){
    return this.appConfig && this.appConfig.colorPalette;
  }

  getEsQueryMaxTerms(){
    return this.appConfig.esQueryMaxTerms;
  }

  getSessionTimeoutMilliSeconds(){
    return this.appConfig.sessionTimeoutInSeconds * 1000;
  }

  getMaxPlaybackWidgetsPerDashboard(){
    return this.appConfig.maxPlaybackWidgetsPerDashboard;
  }

  getMapZoomLevelForRecenteringOnePoint(){
    return this.appConfig.mapZoomLevelForRecenteringOnePoint;
  }

  getMaxTracesLimitSize(){
    return this.appConfig.maxTracesLimitSize;
  }

  getCustomAnalyticsMaxFileSize(){
    return this.appConfig.customAnalyticsMaxFileSize;
  }

  getWorkflowMaxFileSize(){
    return this.appConfig.workflowMaxFileSize;
  }

  getCheckingLogin(){
    return this.checkingLogin;
  }

  getLoggedIn(){
    return this.loggedIn;
  }

  getUserData(){
    return this.userData;
  }

  getExpired(){
    return this.expired;
  }

  isSuperAdmin(){
    return this.userData.isSuperAdmin;
  }

  getPreferedTheme(){
    return this.userData.preferedTheme ? this.userData.preferedTheme : DEFAULT_THEME;
  }

  setPreferedTheme(theme){

    this.userData.preferedTheme = theme;
    this.emitChange();
  }

  getServerTimeZone(){
    return this.userData.serverTimeZone;
  }
}

export default new AppStore();
