export default [
    "#008CFF",   
    "#E57921",   
    "#12165F",   
    "#D7B702",   
    "#2C373F",   
    "#019BDE",   
    "#E41A1C",   
    "#4DAF4A",   
    "#984EA3",   
    "#A65628",   
    "#F781BF",
    "#8073AC",
    "#FF6361",
    "#FFA319",
    "#CD418E",
    // 
    "#e7873b",
    "#322c6f",
    "#dbbe37",
    "#4696cc",
    "#424c54",
    "#3ea6e2",
    "#5b9db5",
    "#5DB557",
    "#A35DAD",
    "#B26438",
    "#F88AC3",
    "#9080B6",
    "#FF736C",
    "#FEAF30",
    "#D4589C",
    //  
    "#cc6c1f",
    "#171657",
    "#F1D629",
    "#167eb5",
    "#27343d",
    "#0f86c7",
    "#38839c",
    "#419A42",
    "#894495",
    "#994A25",
    "#DF6FA7",
    "#75689F",
    "#E95756",
    "#E68E1A",
    "#BB3880",
    // 
    "#e99451",
    "#4c427f",
    "#dec555",
    "#63a0d0",
    "#596269",
    "#5bb1e6",
    "#71a9bd",
    "#6CBB64",
    "#AE6CB6",
    "#BD7248",
    "#FA92C7",
    "#A08DC0",
    "#FF8378",
    "#FCBA44",
    "#DA6DAA",
    // 
    "#b3601d",
    "#1a1650",
    "#DDAF27",
    "#136fa1",
    "#23323c",
    "#1473af",
    "#2f748c",
    "#378639",
    "#7A3987",
    "#8C3E21",
    "#C75E90",
    "#6A5E93",
    "#D44B4A",
    "#CC7B1A",
    "#A93073",
    // 
    "#eaa168",
    "#655a90",
    "#e2cc6f",
    "#7aabd3",
    "#707980",
    "#73bcea",
    "#86b4c6",
    "#7AC171",
    "#B97BC0",
    "#C98058",
    "#F9B9CB",
    "#AF9BCA",
    "#FF9185",
    "#FCC556",
    "#E180B7",
    // 
    "#9b541b",
    "#1b1649",
    "#C38B27",
    "#11628d",
    "#1e2f3a",
    "#155f98",
    "#27667b",
    "#2C7231",
    "#6C2F79",
    "#7F331D",
    "#AF4D7A",
    "#5F5487",
    "#BE3F40",
    "#B36819",
    "#982766",
    // 
    "#eaad7e",
    "#7d73a0",
    "#e5d388",
    "#8fb5d6",
    "#899197",
    "#8ac7ed",
    "#9bc0ce",
    "#87C77E",
    "#C48ACA",
    "#D48E69",
    "#FCA3CF",
    "#BFA9D4",
    "#FF9F92",
    "#FBCF69",
    "#E793C4",
    //  
     "#844819",
    "#1c1741",
    "#A56A26",
    "#0f547a",
    "#192c39",
    "#134d81",
    "#1e586b",
    "#235E28",
    "#5E256B",
    "#712819",
    "#973D65",
    "#544A7B",
    "#AA3335",
    "#995617",
    "#861F59",
    // 
    "#eaba95",
    "#958db1",
    "#e8daa1",
    "#a4c0d9",
    "#a2aaaf",
    "#9fd2f2",
    "#afcbd7",
    "#94CD8B",
    "#CF9AD4",
    "#DF9D7A",
    "#FDACD4",
    "#CEBBDE",
    "#FFAD9F",
    "#FBD97B",
    "#EDA5D1",
    // 
    "#6d3c17",
    "#1d173a",
    "#834D23",
    "#0c4768",
    "#383838",
    "#0f3b6b",
    "#164a5c",
    "#1A4B20",
    "#501B5D",
    "#641E15",
    "#802D51",
    "#49406F",
    "#95272B",
    "#804514",
    "#76174D",
    // 
    "#e9c6ac",
    "#aea8c1",
    "#eae1b9",
    "#b7cadd",
    "#bdc3c7",
    "#b4ddf6",
    "#c3d7df",
    "#A1D299",
    "#D9A9DE",
    "#EAAB8B",
    "#FEB4D8",
    "#D0C7E9",
    "#FFBAAD",
    "#FCE28E",
    "#F3B7DD",
    // 
    "#573114",
    "#1d1733",
    "#5F331D",
    "#009a56",
    "#0e2736",
    "#082b55",
    "#0d3d4d",
    "#123918",
    "#421250",
    "#561311",
    "#691E3D",
    "#3F3663",
    "#811B21",
    "#673510",
    "#650E41",
    // 
    "#e6d3c4",
    "#7c3cd2",
    "#ede7d2",
    "#cad5e0",
    "#d8dde0",
    "#c8e8fa",
    "#d6e3e8",
    "#ADD8A6",
    "#E4B8E8",
    "#F5BA9C",
    "#FEBCDC",
    "#EBD6F4",
    "#FFC7BC",
    "#FDEBA1",
    "#F9C9E9",
    // 
    "#422611",
    "#1c172d",
    "#3A1D14",
    "#072e45",
    "#062534",
    "#031b40",
    "#05303e",
    "#0B280F",
    "#350743",
    "#49080A",
    "#530E2C",
    "#352D58",
    "#6E0F17",
    "#4E260A",
    "#550635",
    // 
    "#e3dfdc",
    "#e0dfe3",
    "#e1dfdc",
    "#dde0e3",
    "#edf1f4",
    "#ddf3ff",
    "#eaeff1",
    "#BADEB3",
    "#EFC8F2",
    "#FFC9AE",
    "#FFC4E0",
    "#F9E5FF",
    "#FFD4CB",
    "#FFF4B4",
    "#FFDBF4",
    // 
    "#2e1b0c",
    "#1b1726",
    "#190500",
    "#052234",
    "#002233",
    "#00082c",
    "#002430",
    "#001900",
    "#290037",
    "#3C0000",
    "#3E001B",
    "#2B244D",
    "#5B020D",
    "#371800",
    "#46002A",

];

