import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'styles/inputs/simple/tagsInput.less'

const TagsInput = props => {
  return(
    <div className="ac-Wrapper tagsInputWrapper">
      <Typeahead
        multiple
        onChange={props.onChange}
        options={props.options}
        placeholder={props.placeholder}
        selected={props.selected}
        paginate={props.paginate}
        disabled={props.disabled}
        minLength={props.minLength || 0}
        id={props.id} 
        labelKey={props.labelKey || 'label'}
      />
    </div>

  );
};

export default TagsInput;