
import React from 'react';
import TooltippedButton from 'buttons/TooltippedButton';
import ButtonWrapper from 'containers/ButtonWrapper';
import Icons from 'templates/Icons';
import {MenuItem, Button} from 'react-bootstrap';
import * as UIConstructionUtils from './UIConstructionUtils';

const pullButtonRight = (button, pullRight) => {
  if(pullRight){
    return (
      <div className='pull-right'>
        {button}
      </div>
    );
  }

  return button;
};

export function getTooltippedButton(tooltip, iconName, className, disabled, onClick, placement){
  return (
    <TooltippedButton
      className={className}
      tooltip={tooltip}
      onClick={onClick}
      disabled={disabled}
      placement={placement}>
        <i className={iconName}></i>
    </TooltippedButton>
  );
}

export function getTooltippedButtonWithTitle(tooltip, iconName, className, disabled, onClick, title){
  return (
    <TooltippedButton
      className={className}
      tooltip={tooltip}
      onClick={onClick}
      disabled={disabled}>
         <span>
          {UIConstructionUtils.getIcon(iconName, null,{ marginLeft: '5px' })}
          <span>{title}</span>
        </span>
    </TooltippedButton>
  );
}

export function getButtonWrapper(type, tooltip, iconName, className, onClick, item, disabled){
  return (
    <ButtonWrapper
      type={type}
      tooltip={tooltip}
      icon={iconName}
      className={className}
      onClick={onClick}
      item={item}
      disabled={disabled}/>
  );
}

 export function renderTooltippedButtons(buttons){
    if(buttons && buttons.length > 0){
      return buttons.map(button => {
        const renderedButton = <TooltippedButton className={`col-md-12 float-none btn-action ${button.className}`}
            tooltip={button.tooltip}
            disabled={button.disabled}
            onClick={(e) => {
              e.stopPropagation();
              button.onClick();
            }}
          >
            <i className={Icons[button.icon]}></i>
          </TooltippedButton>;

        return(
          pullButtonRight(renderedButton, button.pullRight)
        );
      });
    }

    return null;
  }

export function getButton(icon, expandOnClick, onClick, tooltip, pullRight, disabled, className){
  return {
    icon,
    expandOnClick,
    onClick,
    tooltip,
    pullRight,
    disabled,
    className,
  };
}

export function add(onClick, tooltip, disabled, pullRight, className){
  return getButton(
    'add',
    true,
    onClick,
    tooltip,
    pullRight,
    disabled,
    className,
  );
}

export function remove(onClick, tooltip, disabled, pullRight){
  return getButton(
    'delete',
    false,
    onClick,
    tooltip,
    pullRight,
    disabled
  );
}

export function edit(onClick, tooltip, disabled, pullRight){
  return getButton(
    'edit',
    false,
    onClick,
    tooltip,
    pullRight,
    disabled
  );
}

export function orderUp(onClick, tooltip, disabled, pullRight){
  return getButton(
    'caretUp',
    false,
    onClick,
    tooltip,
    pullRight,
    disabled,
    'ordering padd0-5'
  );
}

export function orderDown(onClick, tooltip, disabled, pullRight){
  return getButton(
    'caretDown',
    false,
    onClick,
    tooltip,
    pullRight,
    disabled,
    'ordering padd0-5'
  );
}

export function menuItem(title, iconName, onClick){
  return (
    <MenuItem onClick={onClick}>
      <i className={iconName}></i>
      {title}
    </MenuItem>
  );
}

export function dividerMenuItem(){
  return <MenuItem divider/>;
}

export function getButtonComponent(buttonParams, title){
  return (
    <Button {...buttonParams}>
      {title}
    </Button>
  );
}
