/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import {Row, Col, OverlayTrigger, Tooltip, FormGroup,
  InputGroup, DropdownButton, MenuItem} from 'react-bootstrap';
import CollapsableComponent from 'containers/CollapsableComponent';
import ValidationOutput from 'containers/ValidationOutput';
import * as defaults from 'utils/defaults';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import RefFilterRow from './RefFilterRow';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import * as FilterUtils from 'utils/filterUtils';
import FileUploader from 'inputs/simple/FileUploader';
import DateFilterRow from './DateFilterRow';
import {getFileValidationSchema} from 'utils/CommonValidationSchema';
import * as Icons from 'templates/Icons';
import Modal from 'containers/Modal';
import Reactable from 'dataVisualization/Table/Reactable';
import {MsgDefaults, ValidationUtils, FieldTypes, 
  CompareUtils, FileTransformer, ArrayUtils, AggregationUtils} from 'js-utils';
import AutoComplete from 'inputs/simple/AutoComplete';
import AddonLabeledComponent from 'containers/AddonLabeledComponent';
import 'styles/dataSelection/records/filterInputRow.less';
import DataInput from 'inputs/simple/DataInput';
import FgaAttributeSelection from './FgaAttributeSelection';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';

export default class FilterInputRow extends React.Component {
  static getDerivedStateFromProps(props, state){
    if (state.ownUpdate) {
      return {
        compareValue: state.compareValue,
        variableName: state.variableName,
        ownUpdate: false,
      };
    } else if(props.compareValue !== state.compareValue 
      || props.variable?.name !== state.variableName) {
      return {
        compareValue: props.compareValue,
        variableName: props.variableName,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.renderListFilterModal = this.renderListFilterModal.bind(this);
    this.getDefaultFilterArguComponent = this.getDefaultFilterArguComponent.bind(this);
    this.downloadListFilterValues = this.downloadListFilterValues.bind(this);
    this.hideListFilterModal = this.hideListFilterModal.bind(this);
    this.showListFilterModal = this.showListFilterModal.bind(this);    
    this.getFilterRow = this.getFilterRow.bind(this);
    this.renderQuickPostAggregationFilters = this.renderQuickPostAggregationFilters.bind(this);
    this.renderMultipleSelectedAttribute = this.renderMultipleSelectedAttribute.bind(this);
    this.renderFileUploader = this.renderFileUploader.bind(this);
    this.onChangePlaceholder = this.onChangePlaceholder.bind(this);
    this.getFilterVariable = this.getFilterVariable.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.onChangeFilterValueType = this.onChangeFilterValueType.bind(this);
    this.onChangeCompareValue = this.onChangeCompareValue.bind(this);
    this.onBlurCompareValue = this.onBlurCompareValue.bind(this);
    this.onChangeVariableName = this.onChangeVariableName.bind(this);
    this.onBlurVariableName = this.onBlurVariableName.bind(this);
    this.onAttributeSelection = this.onAttributeSelection.bind(this);

    this.fileTransformParams = this.getFileTransformParams();
    this.state = {
      placeholderOptions: [],
      compareValue: props.compareValue,
      variableName: props.variable?.name,
    };
  }

  componentDidMount() {
    this.getPlaceholderOptions();
  }

  componentDidUpdate(prevProps){
    if(!_.isEqual(this.props.attribute, prevProps.attribute)){
      this.getPlaceholderOptions();
    }
  }

  getPlaceholderOptions(){
    const {
      isTemplate,
      isDateFilter,
      isRefFilter,
      listPlaceholdersByTypeAction,
      attribute,
      isPostAggregationFilter
    } = this.props;
    let shoudSendRequest = false;
    let type;
    let isUniqueCount = false;

    if(attribute && isTemplate && listPlaceholdersByTypeAction){
      const selectedField = attribute.selectedField;

      if(isPostAggregationFilter && selectedField){
        shoudSendRequest = true;
        isUniqueCount = AggregationUtils.isUniqueCount(attribute.aggregationType);
        type = isUniqueCount ? AggregationUtils.COUNT : selectedField.attributeType.id;
      }else if(attribute.attributeType && !isDateFilter && !isRefFilter){
        shoudSendRequest = true;
        type = attribute.attributeType.id;
      }
    }
    if(shoudSendRequest){
      listPlaceholdersByTypeAction(type, isUniqueCount).then((placeholders) => {
        this.setState({placeholderOptions: placeholders});
      });
    }else if(ArrayUtils.hasItems(this.state.placeholderOptions)){
      this.setState({placeholderOptions: []});
    }
  }
  
  getFileTransformParams(){
    return {
      fileType: FileTransformer.FILE_TYPES.TEXT,
      trimWhiteSpaces: true,
      filterEmptyLines: true,
      rowFormatter: element => ({inValue: element}),
    };
  }

  onChangeCompareValue(e){
    this.setState({compareValue: e.target.value, ownUpdate: true});
  }

  onBlurCompareValue(e){
    this.props.onChangeValue(e.target.value, this.props.index);
  }

  getFilterValueFormInput(){
    const isNonNumeric = FieldTypes.isNonNumericField(this.props.attribute || {});
    const isAutoComplete = !this.props.disableAutoComplete && isNonNumeric
    && !this.props.isQuickFilter && !this.props.isPostAggregationFilter
    && !this.props.selectionAdvancedFilters;
    const filterKey = this.props.attribute?.elasticsearchName;
    const commonClasses = 'margBtm0 inputFilterOverRide'; //inputFilterOverRide class for the automation
    const compareValuePropsCommonProps = {
      key: filterKey,
      value: this.state.compareValue,
      disabled: this.props.disableComponent,
      onChange: this.onChangeCompareValue,
      onBlur: this.onBlurCompareValue,
      extraParams: {
        elasticsearchNames: [filterKey],
      },
      placeholder: this.props.placeholder,
    };

    return isAutoComplete ?   
      <AutoComplete 
        getAutoCompleteSuggestions={this.props.getAutoCompleteSuggestions}
        className={`${commonClasses} filterValueAutoComplete`}
        {...compareValuePropsCommonProps}
      /> : <DataInput
        pureInput
        className={commonClasses}
        {...compareValuePropsCommonProps}
      />;
  }

  onChangePlaceholder({ target: { value: placeholderId } }){
    const templatesPlaceholder = this.state.placeholderOptions.find(placeholder => placeholder.id == placeholderId);
    this.props.onChangePlaceholder(this.props.index, templatesPlaceholder);
  }

  getFilterPlaceHolder(){
    let placeholders = this.state.placeholderOptions;
    
    placeholders = placeholders && this.props.operation !== CompareUtils.REGEX ? placeholders.filter((placeholder) => {
      return !placeholder.multipleSelection;
    }) : placeholders;
    const placeholderOptions = UIConstructionUtils.wrapArrayInToSelectOptions(placeholders, 'id', 'id', 'name');
    
    return(
      <React.Fragment>
        <div className="inputFilterChoose">
          <MultipleSelect
            value={this.props.templatePlaceholder && this.props.templatePlaceholder.id}
            className='NEPlaceholder-menu'
            placeholder={FilterUtils.CHOOSE_PLACEHOLDER}
            options={placeholderOptions}
            onChange={this.onChangePlaceholder}
          />
        </div>
      </React.Fragment>
    );
  }

  getMenuItems(elements, eventKey, name){
    return (
      elements.map((element) => {
        return <MenuItem key={element[eventKey]} eventKey={element[eventKey]}>{element[name]}</MenuItem>;
      })
    );
  }

  onChangeFilterValueType(eventKey, event){
    this.props.onChangeFilterValueType(this.props.index, eventKey);
  }

  getTemplateFilterValueComponent(){
    let renderedInput;
    const title = FilterUtils.FILTER_TYPE_OPTIONS[this.props.filterValueType].name;

    if(FilterUtils.isPlaceholderFilter(this.props.filterValueType)){
      renderedInput = this.getFilterPlaceHolder();
    }else if(FilterUtils.isVariableFilter(this.props.filterValueType)){
      renderedInput = this.getFilterVariable();
    }else{
      renderedInput = this.getFilterValueFormInput();
    }

    return (
      <AddonLabeledComponent
        dropdownLabel
        label={title}
        disabled={this.props.disableComponent}
        onLabelSelect={this.onChangeFilterValueType}
        dropdownOptions={FilterUtils.getFilterInputOptions(this.props.isTemplate, this.props.hasVariables)}
        dropdownValueField={'value'}
        dropdownTextField={'name'}
      >
        {renderedInput}
      </AddonLabeledComponent>
    );
  }

  getFilterValueComponent(){
    if(this.props.isTemplate || this.props.hasVariables){
      return this.getTemplateFilterValueComponent();
    }
    
    return this.getFilterValueFormInput();
  }

  onChangeVariableName(e){
    this.setState({variableName: e.target.value, ownUpdate: true});
  }

  onBlurVariableName(e){
    this.props.onChangeVariableName(e.target.value, this.props.index);
  }

  getFilterVariable(){
    return (
      <DataInput
        key='filterWithVariables'
        value={this.state.variableName}
        className='margBtm0 filterWithVariables evc-transparent-input'
        disabled={this.props.disableComponent}
        onChange={this.onChangeVariableName}
        onBlur={this.onBlurVariableName}
        size={12}
        inputSize={12}
      />
    );
  }

  updateSelection(selection, index){
    if(selection.selectionType === FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value){
      this.props.onChangeVariableName(selection.variableName, index, false)
    }else{
      this.props.updateSelection(selection, index)
    }
  }

  getRefFilterArguComponent(){
    return (
      <Col xs={12}>
        <RefFilterRow
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          updateSelection={this.updateSelection}
          index={this.props.index}
          networkElementDataStructure={this.props.networkElementDataStructure}
          selection={this.props.selection}
          disableComponent={this.props.disableComponent}
          isTemplate={this.props.isTemplate}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          hasVariables={this.props.hasVariables}
          onChangeVariableName={this.props.onChangeVariableName}
          filterValueType={this.props.filterValueType}
          variable={this.props.variable}
        />
      </Col>
    );
  }

  getDateFilterArguComponent(){
    const filter = {
      resolution: this.props.resolution || defaults.DAILY,
      selectedDays: this.props.selectedDays,
      dayOfMonth: this.props.dayOfMonth,
      timeRanges: ((this.props || {}).timeRanges),
      validation: {},
    };
    
    return (
      <DateFilterRow
        filter={filter}
        disableComponent={this.props.disableComponent}
        filterIndex={this.props.index}
        handleDateFilterChange={this.props.handleDateFilterChange}
        serverTimeZone={this.props.serverTimeZone} />
    );
  }

  getInFilterComponent(){
    if(this.props.hasVariables){
      return this.getInFilterBulkComponent();
    }

    return this.getInFilterValueComponent();
  }

  getInFilterValueComponent(){
    return (
      <Row>
        <Col xs={12}>
          {this.renderFileUploader()}
          {this.renderListFilterModal()}
        </Col>
      </Row>
    );
  }

  getInFilterBulkComponent(){
    const title = FilterUtils.FILTER_TYPE_OPTIONS[this.props.filterValueType].name;
    
    let renderedInput;

    if(FilterUtils.isVariableFilter(this.props.filterValueType)){
      renderedInput = this.getFilterVariable();
    }else{
      renderedInput = this.getInFilterValueComponent();
    }
    return (
      <AddonLabeledComponent
      dropdownLabel
      label={title}
      disabled={this.props.disableComponent}
      onLabelSelect={this.onChangeFilterValueType}
      dropdownOptions={FilterUtils.getFilterInputOptions(this.props.isTemplate, this.props.hasVariables)}
      dropdownValueField={'value'}
      dropdownTextField={'name'}
      >
        {renderedInput}
      </AddonLabeledComponent>
    );
  }

  renderFileUploader(){
    const fileUploaderExtraParams = {
      index: this.props.index,
      operation: this.props.operation,
      transformParams: this.fileTransformParams,
    };

    return (
      <FileUploader
        selectedFile={this.props.inOperationFilter}
        extraParams={fileUploaderExtraParams}
        onChange={this.props.handleListFilter}
        disabled={this.props.disableComponent}
        validationSchema={getFileValidationSchema(1E6 * 100, 'MB', ['txt'])}
        validFile={ValidationUtils.isValid(this.props.validationOutcome)}
        uploadInfo="Each line in the file (.txt) represents a filter entry"
        extraRightButtons={[
          {
            icon: Icons.view,
            tooltip: MsgDefaults.getViewMsg('List Values'),
            onClick: this.showListFilterModal,
            disabled: (this.props.inOperationFilter || []).length < 1,
            disabledIfInvalid: true,
          },
          {
            icon: Icons.download,
            tooltip: MsgDefaults.getDownloadMsg('List Values'),
            onClick: this.downloadListFilterValues,
            disabled: (this.props.inOperationFilter || []).length < 1,
            disabledIfInvalid: true,
          },
        ]}
      />
    );
  }

  getDefaultFilterArguComponent(){
    const comparators = this.props.comparators ? this.props.comparators : 
      this.props.isQuickFilter || this.props.selectionAdvancedFilters ? CompareUtils.COMPARATORS : CompareUtils.COMPARATORS_LIST; 
    let type = (this.props.attribute || {}).type;
    if(!type && this.props.attribute && this.props.attribute.selectedField){
      type = this.props.attribute.selectedField.type;
    }

    const operations = UIConstructionUtils.getFieldDataTypeComparators(type, comparators);

    return (
      <Fragment>
        <OverlayTrigger shouldUpdatePosition
          rootClose placement='bottom' trigger={this.props.child ? [] : ['hover', 'focus']}
          overlay={
            <Tooltip placement='bottom' className='in' >
                {defaults.SELECT_OPERATION}
            </Tooltip>
          }>
          <Col  className={this.props.selectionAdvancedFilters && !this.props.multipleRows ? '' : 'margBtm5'}
                sm={this.props.selectionAdvancedFilters && !this.props.multipleRows ? 4 : 12} 
                xs={12}>
            <MultipleSelect
              placeholder={defaults.SELECT_OPERATION}
              className='filterOperation-menu'
              options={operations}
              value={this.props.operation}
              disabled={this.props.disableComponent}
              onChange={({ target: { value: operation } }) => {
                this.props.onChangeOperation(operation, this.props.index);
              }} />
          </Col>
        </OverlayTrigger>
        {CompareUtils.LIST_OPERATORS.includes(this.props.operation) ? 
          <Col xs={12} className='inFilterValueUpload'>
            {this.getInFilterComponent()}
          </Col>
        : <Col  className={this.props.selectionAdvancedFilters && !this.props.multipleRows ? '' : 'margBtm5'}
                sm={this.props.selectionAdvancedFilters && !this.props.multipleRows ? 4 : 12} 
                xs={12}>
            {this.getFilterValueComponent()}
          </Col>
        }
      </Fragment>
    );
  }
  hideListFilterModal(){
    this.setState({showListFilterPopup: false});
  }

  showListFilterModal(){
    this.setState({showListFilterPopup: true});
  }

  renderListFilterModal(){
    return(
      <Modal 
        bsSize="large"
        onHide={this.hideListFilterModal}
        title='List Filter Values'
        show={(this.state || {}).showListFilterPopup}
        footerButtons={[
          ButtonsConstructor.getButtonComponent({
            bsStyle:'primary', 
            onClick:this.downloadListFilterValues}, 'Download'),
        ]}
      >
        <Reactable
          idx='listFilterValues'
          columns={[{
            header: {
              label: 'Value',
              props:{
                style:{
                  width: '100%',
                },
              },
            },
            property:'inValue',
            sortable:true,
            highlighted:true,
          }]}
          tableData={this.props.inOperationFilter} 
          fixedHeaderHeight='250px'
          rowKey='id'
          searchable paginated fixedHeader
        />
      </Modal>
    );
  }

  downloadListFilterValues(){
    const transformParams = {
      fileName: 'List Value Data',
      rowFormatter: (object) => object.inValue,
    };
    
    FileTransformer.writeFile(this.props.inOperationFilter,
      FileTransformer.FILE_TYPES.TEXT, transformParams);
  }

  getFilterRow(){
    let filterArguComponent;

    if(this.props.isDateFilter){
      filterArguComponent = this.getDateFilterArguComponent();
    } else if(this.props.isRefFilter){
      filterArguComponent = this.getRefFilterArguComponent(); 
    } else{
      filterArguComponent = this.getDefaultFilterArguComponent(); 
    }

    const fieldName = this.props.selectionAdvancedFilters ? 'name' : 'fieldName' ;
    const constructName = this.props.isPostAggregationFilter ? DataSelectionCommonUtils.getCompoundAggregateFieldName : null;
    const menuTitle = this.props.selectedAttributePlaceholder ? MsgDefaults.addSIfPlural(this.props.selectedAttributePlaceholder, true)
      : this.props.isPostAggregationFilter ? defaults.AGGREGATES : null;
    
    const categorizedAttributes = UIConstructionUtils.getCategorizedAttributesOptions(
        this.props.fieldsList, null, null, fieldName, null, null, constructName, UIConstructionUtils.getKeyFromId, menuTitle);  
      
    const filterRow = (
        <Row className="filterInput">
          <Col className={this.props.selectionAdvancedFilters && !this.props.multipleRows ? '' : 'margBtm5'} 
               sm={this.props.selectionAdvancedFilters && !this.props.multipleRows ? 4 : 12} xs={12}> 
            <OverlayTrigger shouldUpdatePosition
              rootClose placement='bottom' trigger={this.props.child ? [] : ['hover']}
              overlay={
                <Tooltip placement='bottom' className='in' >
                  {this.props.isPostAggregationFilter ? defaults.SELECT_AGGREGATE : defaults.SELECT_ATTR}
                </Tooltip>
              }
              >
              
                {
                  this.props.showPostAggregationFilter ?
                    this.renderQuickPostAggregationFilters(categorizedAttributes)
                    : this.renderMultipleSelectedAttribute(categorizedAttributes)
                }
            </OverlayTrigger>
          </Col>
          {filterArguComponent}
        </Row> 
    );

    return filterRow;
  }

  renderQuickPostAggregationFilters(categorizedAttributes){
    return (
      <FormGroup className="margBtm0OverRide">
        <InputGroup>
            <DropdownButton 
              className="inputFilterBtn"
              componentClass={InputGroup.Button}
              id='input-dropdown-addon'
              title={this.props.quickFilterType}
              onSelect={this.props.onLabelSelect}
            >
              {this.getMenuItems(this.props.dropdownOptions, this.props.dropdownValueField, this.props.dropdownTextField)}
            </DropdownButton>
            <div className="inputFilterChoose">
              {this.renderMultipleSelectedAttribute(categorizedAttributes)}
            </div>
        </InputGroup>
      </FormGroup>
    );
  }

  renderMultipleSelectedAttribute(categorizedAttributes){  
    if(!this.props.hideAttributeSelection){
      const placeholder = this.props.selectedAttributePlaceholder ? MsgDefaults.getSelectMsg(this.props.selectedAttributePlaceholder) 
      : this.props.isPostAggregationFilter ? defaults.SELECT_AGGREGATE : defaults.SELECT_ATTR;
      const subFields = DataSelectionCommonUtils.getSubFields(this.props.attribute,
        this.props.fieldsList, []);

      return (
        <FgaAttributeSelection
          placeholder={placeholder}
          className='filter'
          disabled={this.props.disableComponent || (!this.props.disableComponent && this.props.disableChange)}
          options={categorizedAttributes}
          attribute={this.props.attribute}
          grouped
          fieldsList={this.props.fieldsList}
          subFields={subFields}
          onChange={this.onAttributeSelection}
          tooltip={defaults.SELECT_ATTR}
        />
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  onAttributeSelection(selectedField){
    this.props.onChangeAttribute(selectedField, this.props.index);
  }

  render() {
    const collapsedContent = this.getFilterRow();
    const shownIndex = this.props.index + 1;

    const filterTitle = this.props.componentTitle || (defaults.FILTER + ' ' + shownIndex);
    const buttonTooltip = this.props.buttonTooltip || defaults.DELETE_FILTER;
    if(this.props.builtInField){
      return this.getFilterValueComponent();
    }else{
      return (
        this.props.isQuickFilter || this.props.notCollapsable ? 
          <Fragment>
            <Row>
              <Col xs={12}>
                <ValidationOutput validation={this.props.validationOutcome} />
              </Col>
            </Row>
            {collapsedContent} 
          </Fragment>
          : <Col xs={12}>
              <CollapsableComponent
                title={filterTitle}
                collapsableComponentIndex={this.props.index}
                validation={this.props.validationOutcome}
                buttons={this.props.hideDeleteButton ? [] : [
                  ButtonsConstructor.remove(
                    this.props.deleteFilter, buttonTooltip,
                    this.props.disableComponent || (!this.props.disableComponent  && this.props.disableDelete)
                  ),
                ]}
                >
                <div className='evc-container-sys-bg'>
                  {collapsedContent}
                </div>
              </CollapsableComponent>
          </Col>
      );
    }
    

  }
}
