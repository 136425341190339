/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils, Utils, UnitConversionUtils, DataModelTypeUtils} from 'js-utils';
import * as EmailDefaults from 'utils/EmailDefaults';

const VARCHAR_LENGTH = 255;
const VALUE_LABEL = 'Value';
const MIN_FONT_SIZE = 8;
const MAX_FONT_SIZE = 100;

export const mail = Joi.string()
  .optional()
  .allow(null, '')
  .trim()
  .regex(EmailDefaults.Email_VALIDATION_REGEX)
  .message(EmailDefaults.ERR_MSGS.EMAIL_FORMAT)
  .label("Email")
	.error(ValidationUtils.getErrMsgString);

export function isValidDay(day){
  return (day > 0 && day < 32 && Number.isInteger(Number(day)));
}

export function getStringValidationSchema(label, min, max){
  return Joi.string()
    .label(label || 'Name')
    .min(min || 3)
    .max(max || VARCHAR_LENGTH)
    .trim()
    .required()
    .error(ValidationUtils.getErrMsgString);
}
export function getTextAreaValidationSchema(label){
  return Joi.string().allow('')
    .label(label || 'Description')
    .trim()
    .max(1000)
    .error(ValidationUtils.getErrMsgString)
    .optional();
}

export function getBooleanValidationSchema(required){
  const booleanSchema = Joi.bool().allow('0', '1')
    .error(ValidationUtils.getErrMsgBoolean);

  return required ? booleanSchema : booleanSchema.optional().allow('');  
}

const SCHEMA = {
  text: getStringValidationSchema(),
};


function validator(fieldType, value){
  return ValidationUtils.validateSchema(SCHEMA)(fieldType, value);
}

export function getFileValidationSchema(maxSize, sizeDisplayUnit, extensions, fileInfo = undefined) {
  let sizeSchema = Joi.number()
    .max(maxSize)
    .unit(sizeDisplayUnit);

  if (fileInfo?.minSize) {
    sizeSchema = sizeSchema.min(fileInfo.minSize);
  }

  let joiObj = Joi.object().keys({
    size: sizeSchema,
  })
    .unknown()
    .error(ValidationUtils.getErrMsgFile);

  if (fileInfo?.maxNameLength || fileInfo?.invalidNames) {
    let nameSchema = Joi.string();
    
    if (fileInfo.maxNameLength) {
      nameSchema = nameSchema.max(fileInfo.maxNameLength);
    }
    if (fileInfo.invalidNames) {
      nameSchema = nameSchema.invalid(...fileInfo.invalidNames);
    }
    joiObj = joiObj.append({
      name: nameSchema,
    });
  }

  if(Utils.isEmpty(extensions)){
    return joiObj;
  }

  return joiObj.append({
    extension: Joi.string().valid(...extensions),
  });
}

export function handleNamePromise(isNameChanged, nameValidation, actionParamters, nameLabel, nameExistAction){
  if(isNameChanged && ValidationUtils.isValid(nameValidation)){
    const params = Array.isArray(actionParamters) ? actionParamters : [actionParamters];

    return nameExistAction(...params).then(value => {
      if(value){
        return ValidationUtils.errorToValidationObject(ValidationUtils.ERR_MSGS.exist(nameLabel));
      }
    });
  }

  return new Promise((resolve) => resolve(nameValidation));
}

export function checkIfNameExists(name, nameValidationKeyword, isCaseSensitive, originalName, 
  nameExistAction, validationFunction, nameLabel, actionParams){
  const trimmedName = name.trim();
  const isNameChanged = Utils.isBlank(originalName) || originalName.trim().toUpperCase() != trimmedName.toUpperCase();
  let nameValidation = validator('text', trimmedName);

  if(validationFunction){
    nameValidation = validationFunction(nameValidationKeyword, trimmedName);
  }

  return handleNamePromise(isNameChanged, nameValidation, actionParams || trimmedName, nameLabel || nameValidationKeyword, nameExistAction);
}

export function getNumericInputValidationSchema(params){
  let numberValidate = Joi.number()
  .min(params.min || (params.acceptNegativeNumbers ? Number.MIN_SAFE_INTEGER : 0))
  .max(params.max || Number.MAX_SAFE_INTEGER)
  .unsafe()
  .label(params.label ? params.label : VALUE_LABEL)
  .error(ValidationUtils.getErrMsgNumber);
 
  const precision = params.precision ? params.precision : 0;
  
  numberValidate = Math.pow(10, -1 * precision) < 1 ? numberValidate : numberValidate.integer();
  
  numberValidate = params.required  && params.required == true ? numberValidate.required() : numberValidate.optional().allow(null, '');

  return numberValidate;
}

export function getPrecisionValidationSchema(){
  return getNumericInputValidationSchema({
    min: UnitConversionUtils.MIN_PRECISION,
    max: UnitConversionUtils.MAX_PRECISION,
    label: 'Precision',
    required: true,
  });
}

export function getFontSizeValidationSchema(){
  return getNumericInputValidationSchema({
    min: MIN_FONT_SIZE,
    max: MAX_FONT_SIZE,
    label: 'Font Size',
    required: true,
  });
}

export function validateValueUponItsDataType(label, isRequired = true){
  return Joi.object().keys({
    value: Joi.any().label(label).when(
      'typeKey', [{
        is: DataModelTypeUtils.INTEGER_DATA_TYPE,
        then: getNumericInputValidationSchema({ acceptNegativeNumbers: true, required: isRequired }),
      }, {
        is: DataModelTypeUtils.DOUBLE_DATA_TYPE,
        then: getNumericInputValidationSchema({ acceptNegativeNumbers: true, precision: UnitConversionUtils.MAX_PRECISION, required: isRequired }),
      }, {
        is: DataModelTypeUtils.STRING_DATA_TYPE,
        then: isRequired ? getStringValidationSchema(label, 1) : getStringValidationSchema(label, 1).allow('',null),
      }, {
        is: DataModelTypeUtils.BOOLEAN_DATA_TYPE,
        then: getBooleanValidationSchema(isRequired),
      }]
    ),
  }).unknown();
}

export function validateUniquenessInArray(label, value){
  const schema = {
    uniqueArray: Joi.array()
      .label(label)
      .unique()
      .error(ValidationUtils.ERR_MSGS.exist(label)),
  };

  return ValidationUtils.validateSchema(schema)('uniqueArray', value);
}

export function getCustomJoiForStringifiedObjects(){
  return Joi.extend({
        type: 'array',
        base: Joi.array(),
        coerce: {
            from: 'string',
            method(value, helpers) {

                if (value[0] !== '[' &&
                    !/^\s*\{/.test(value)) {

                    return;
                }

                try {
                    return { value: JSON.parse(value) };
                }
                catch (ignoreErr) { }
            }
        }
    }).extend({
      type: 'object',
      base: Joi.object(),
      coerce: {
          from: 'string',
          method(value, helpers) {

              if (value[0] !== '{' &&
                  !/^\s*\{/.test(value)) {

                  return;
              }

              try {
                  return { value: JSON.parse(value) };
              }
              catch (ignoreErr) { }
          }
      }
  });
}
