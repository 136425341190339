/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import {Row, Col, InputGroup, OverlayTrigger, 
  Tooltip, FormControl} from 'react-bootstrap';
import CommonTreeWrapper from 'dataSelection/trees/CommonTree/CommonTreeWrapper';
import Modal from 'containers/Modal';
import Icons from 'templates/Icons';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import ValidationOutput from 'containers/ValidationOutput';
import TreeItemView from 'dataSelection/records/TreeItemView';

export default class CommonTreeInputWithModel extends React.Component{
  constructor(props){
    super(props);

    this.saveSelection = ::this.saveSelection;
    this.showSelectionsView = ::this.showSelectionsView;
  }

  saveSelection(){
    this.commonTreeWrapper.saveSelection();
  }

  getTreeModel(selection){
    return(
      <Modal 
        bsSize="large"
        onHide={this.props.toggleTreeModal(false)}
        footerButtons={[
          ButtonsConstructor.getButtonComponent({
            className: 'model_tree_select',
            onClick: this.saveSelection,
            bsStyle: 'primary'}, 'Select'),
        ]}
        show={this.props.showTreeModal}
        title={this.props.title}>
        <CommonTreeWrapper
          ref={(ref => this.commonTreeWrapper = ref)}
          entityOptions={this.props.entityOptions}
          getTreeNodesAction={this.props.getTreeNodes}
          updateSelection={this.props.updateSelection}
          getNodePath={this.props.getNodePath}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          canClick={this.props.canClickNode}
          networkElementTypes={this.props.networkElementTypes}
          selection={selection}
          />
      </Modal>
    );
  }
  
  showSelectionsView(selection){
    return (
      <TreeItemView
        selection={selection}
        openSelection={this.props.toggleTreeModal(true)}
        componentPlaceholder={this.props.componentPlaceholder}
        disableComponent={this.props.disableComponent}
        maxShownSelections={this.props.maxShownSelections}/>
    );
  }

  render(){
    const selection = this.props.selection || {treePath: this.props.treeNodePath, id: this.props.treeNodeId};
    return (
    <Fragment>
      <Row>
        <Col xs={12}>
        {!this.props.selection? 
          <InputGroup>
            <OverlayTrigger shouldUpdatePosition
              rootClose placement="bottom" trigger={this.props.treeNodePath ? ['hover', 'focus'] : []}
              overlay={
                <Tooltip placement="bottom" className='in'>
                {this.props.treeNodePath}
                </Tooltip>
              }
            >
              <FormControl type="text" readOnly
                            value={this.props.treeNodePath} />
            </OverlayTrigger>
            <InputGroup.Addon className='addonReset'>
                {ButtonsConstructor.getTooltippedButton( 
                  'Choose Network Element', Icons.add, 
                  'selectAttrPath-btn btn-primary', this.props.disabled, 
                  this.props.toggleTreeModal(true) )}
              </InputGroup.Addon>
          </InputGroup>
          :this.showSelectionsView(selection)
          }
          {this.props.validation? 
          <ValidationOutput validation={this.props.validation}/> : null}
        </Col>
      </Row>
      {this.getTreeModel(selection)}
      
    </Fragment>
    );
  }
}
