/* Copyright 2019 Greyskies. All Rights Reserved. */

import moment from 'moment';
import { DateTimeUtils, WidgetTypes, UnitConversionUtils, FieldTypes,
  RequestUtils, AggregationUtils} from 'js-utils';
import mapWidgetDefaults from './mapWidgetDefaults';
import {chartWidgetDefaults}  from 'config/widgetDefaults.js';

const ADMIN_DRP_DEFAULT_FROM = parseInt(moment().subtract(6, 'days')
  .startOf('day') / 1000 );
const ADMIN_DRP_DEFAULT_TO = parseInt(moment().endOf('day') / 1000);


const MAX_32_POSITIVE = 4294967295;
const NETWORK_ELEMENT = 'NETWORK_ELEMENT';
const ORGANIZATION_GROUP = 'ORGANIZATION_GROUP';
const ATTRIBUTE = 'ATTRIBUTE';
const BOOLEAN = 'BOOLEAN';
const STRING = 'STRING';
const COUNTER64 = 'COUNTER64';
const GAUGE64 = 'Gauge64';
const AVAILABLE = "Available";
const REQUIRED_NON_NEGATIVE_INTEGER_FIELDS = {
  port: 'Port Number',
  portNumber : 'Port Number',
  snmpVersion: 'SNMP Version',
};
const OPTIONAL_NON_NEGATIVE_INTEGER_FIELDS = {
  retries: 'Retries Count',
  timeout : 'Timeout',
};
const REQUIRED_STRING_FIELDS = {
  hostname: 'Hostname',
  communityString: 'Community String',
  url: 'URL',
  driverClass: 'Driver Class',
  loginMethod: 'Login Method',
  filePath: 'File Path',
  cliType: 'Type',
};
const DEFAULT_SNMP_VERSION = 2;
const CONFIRMATION_MODAL_LABEL = 'The following network elements will be affected with the current change in access methods';
const CONFIRMATION_MODAL_SUB_LABEL = ' using the changed access method(s)';
const TYPE = '@type'; /* this is the discriminator field that java uses to initiate
object from abstract classes. */
const RANGES = DateTimeUtils.RANGES;

const {
  NO_CONTENT_STATUS_CODE, 
  TOO_MANY_SELECTIONS_STATUS_CODE, 
  UNPROCESSABLE_ENTITY_CODE,
} = RequestUtils.ERR_CODES;

const DASHBOARD_COLS = {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}; //to be removed

const ASSOC_PREVIEW_RATIO = {
  xs: {screenWidth: 768, widthRatio: 1, heightRatio:0.5},
  sm: {screenWidth: 1200, widthRatio: 0.5, heightRatio: 1},
  lg: { widthRatio: 2 / 3, heightRatio: 1},
};

const DRP_DEFAULT_RELATIVE_DATE = 'last24Hours';

const REPORTER_DRP_DEFAULT_FROM = () => parseInt(moment().subtract(1, 'days') / 1000 );

const REPORTER_DRP_DEFAULT_TO = () => parseInt(moment() / 1000);

const RESIZE_REFRESH_RATE = 500;

const DEFAULT_FONT_SIZE_FORMAT = 12;
const DEFAULT_TXT_ORIENTATION = 315;

const DATE_FIELD_NAME = 'DATE';
const DATE_FIELD_LABEL = 'Date';
const AGGREGATE_FIELD_NAME = 'Aggregate';
const UPPER_BOUND = 'UPPER_BOUND';
const LOWER_BOUND = 'LOWER_BOUND';
const SEVERITY = 'SEVERITY';
const FULL_TREE_PATH = 'NETWORK_ELEMENT.NETWORK_ELEMENT_TREE_PATH';
const VALUE_FIELD = 'VALUE';
const ID_POSTFIX = '_ID';
const ELASTIC_SEARCH_PREFIX = 'Elastic_Search_';
const WIDGET_TITLE = 'TITLE';
const DEFAULT_RECORDS_FIELDS = {
  DATE :  FieldTypes.DATE_FIELD_ES_NAME,
  Aggregate: AGGREGATE_FIELD_NAME,
};
const RECORDS_FOR_ATTRIBUTES = 'RECORDS_FOR_ATTRIBUTES';
const RECORDS = 'RECORDS';
const ATTRIBUTES = 'ATTRIBUTES';
const DEFAULT_RIGHT_PANEL_FIELDS = [DATE_FIELD_NAME];
const DEFAULT_N_VALUE = 10;
const DEFAULT_N_DIRECTION = 'DESC';
const NTH_PERCENTILE = 'NTH_PERCENTILE';
const DEFAULT_PERCENTILE_VALUE = '95';

const CHART_FORMAT = {
  xAxis: {
    dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
    txtOrientation : DEFAULT_TXT_ORIENTATION,
  },
  yAxis: {
    precision: UnitConversionUtils.DEFAULT_PRECISION,
  },
  y2Axis: {
    precision: UnitConversionUtils.DEFAULT_PRECISION,
  },
  showLegend: true,
  horizontalBar: false,
  groupBar: false,
  dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
  barWidth: null,
  fontSize: DEFAULT_FONT_SIZE_FORMAT,
  showGridlines: true,
  showDataOnBar: false,
  legendPosition: chartWidgetDefaults.legendDirectionDefaults[chartWidgetDefaults.VERTICAL],
  direction: chartWidgetDefaults.VERTICAL,
  labelWidth: chartWidgetDefaults.legendLabelWidthDefaults[chartWidgetDefaults.VERTICAL],
};

const NODE_TOOLTP_DEFAULT_SETTINGS = {
  aggregation : null,
  aggregationType : null,
  percentileValue : DEFAULT_PERCENTILE_VALUE,
  attributes : [],
};

const USER_RIGHT = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
};

const DOWNLOAD_REPORT_DEFAULTS = {
  FORMAT: 'A4',
  ORIENTATION: 'PORTRAIT',
  DOWNLOADTYPE: 'PDF',
};

const DESIGN_SPACE = {
  DASHBOARD: 'Dashboard',
  REPORT: 'Report',
};


const FIELD_TYPES = {
  NUMERIC: 'NUMERIC',
  NONNUMERIC: 'NONNUMERIC',
  MIXED: 'MIXED',
  DATE: 'DATE',
  NETWORK_ELEMENT: 'NETWORK_ELEMENT',
  REF: 'REF',
};

const DATE_FIELDS = [
  DATE_FIELD_NAME,
  FieldTypes.DATE_FIELD_ES_NAME,
];

const CHART_TYPES_WITHOUT_QUICK_FILTERS = [WidgetTypes.WIDGET_TYPES.TOPOLOGY,
  WidgetTypes.WIDGET_TYPES.IFRAME,
  WidgetTypes.WIDGET_TYPES.MARKDOWN,
  WidgetTypes.WIDGET_TYPES.MLANOMALY,
];

const NETWORK_ELEMENT_TREE_PATH = 'NETWORK_ELEMENT_TREE_PATH';
const NETWORK_ELEMENT_ID = 'NETWORK_ELEMENT_ID';

const FORECASTING_EXPORT_COLUMNS = [
  DATE_FIELD_NAME,
  VALUE_FIELD,
  UPPER_BOUND,
  LOWER_BOUND,
];
const NOT_NETWORK_ELEMENT_FIELDS = [
  DATE_FIELD_NAME,
  ATTRIBUTE,
  VALUE_FIELD,
];
const COUNT_MEMBERS_EXPORT_COLUMNS = [
  DATE_FIELD_NAME,
  VALUE_FIELD,
];


const ABSOLUTE_VALUE = 'Absolute';
const PERCENTAGE_VALUE = 'Percentage';

const MAP_SETTINGS = {
  aggregation: AggregationUtils.DEFAULT_NUMERIC_AGGREGATION,
  seriesFields: [
  ],
  boundsCenter: {lat: 8.5, lng: 0 },
  zoomLevel: 3,
};

const MAP_FORMAT = {
  dateFormat: DateTimeUtils.DEFAULT_DATE_FORMAT,
  fontSize: DEFAULT_FONT_SIZE_FORMAT,
  layers : JSON.stringify([mapWidgetDefaults.DEFAULT_LAYER]),
  ranges: '[]',
};

const ANALYSIS_TYPE = {
  FORECASTING: 'FORECASTING',
};

const STEPS_FACTOR = [
  {
    name: 'Hour',
    value: 'HOUR',
  },
  {
    name: 'Day',
    value: 'DAY',
  },
  {
    name: 'Week',
    value: 'WEEK',
  },
  {
    name: 'Month',
    value: 'MONTH',
  },
];


const WHOLE_INTEGERS_REGEX = new RegExp(/^\d+$/);

const AXISOPTIONS = [
  {
    displayName:'Y1 Axis',
    value:'y',
  },
  {
    displayName:'Y2 Axis',
    value:'y2',
  },
];


const NO_DATA_MESSAGE = 'No data in time range to be shown';
const TOO_MANY_SELECTIONS_MESSAGE = 'Too many selections. Data can\'t be shown';
const MAX_NUMBER_OF_SELECTIONS = 10;
const NO_COLUMNS_MESSAGE = 'No columns chosen to be shown';
const CANNOT_RETRIEVE_DATA = 'Can\'t retrieve data';

const RESET_ZOOM_TOOLTIP = 'Reset Zoom';
const RESET_ZOOM_PAN_TOOLTIP = 'Reset Zoom/Pan';
const ADMIN_SETTINGS_TOOL_TIP = 'Admin Settings';
const REPORTER_TOOLS_TOOL_TIP = 'Reporter Tools';

const ZOOM_RANGE = {min: undefined, max: undefined};

const DEFAULT_THEME = 'light';

const REF = 'REF';
const TEMPLATE_PREFIX = 'Template ';
const WIDGET_OPTIONS = 'chartOptions';
const WIDGET_FORMAT = 'chartFormat';

const TABLE_FETCHED_COUNT = 10;
const MAX_NUMBER_OF_SHOWN_FIELDS_BY_DEFAULT = 10;

const defaults = {
  MAX_NUMBER_OF_SHOWN_FIELDS_BY_DEFAULT,
  ADMIN_DRP_DEFAULT_FROM,
  ADMIN_DRP_DEFAULT_TO,
  MAX_32_POSITIVE,
  ORGANIZATION_GROUP,
  BOOLEAN,
  STRING,
  COUNTER64,
  GAUGE64,
  REQUIRED_NON_NEGATIVE_INTEGER_FIELDS,
  OPTIONAL_NON_NEGATIVE_INTEGER_FIELDS,
  REQUIRED_STRING_FIELDS,
  DEFAULT_SNMP_VERSION,
  CONFIRMATION_MODAL_SUB_LABEL,
  CONFIRMATION_MODAL_LABEL,
  TYPE,
  ATTRIBUTE,
  DASHBOARD_COLS,
  ASSOC_PREVIEW_RATIO,
  REPORTER_DRP_DEFAULT_FROM,
  REPORTER_DRP_DEFAULT_TO,
  DRP_DEFAULT_RELATIVE_DATE,
  RESIZE_REFRESH_RATE,
  CHART_FORMAT,
  MAP_SETTINGS,
  MAP_FORMAT,
  NTH_PERCENTILE,
  DEFAULT_PERCENTILE_VALUE,
  DEFAULT_TXT_ORIENTATION,
  USER_RIGHT,
  RANGES,
  DESIGN_SPACE,
  DOWNLOAD_REPORT_DEFAULTS,
  ZOOM_RANGE,
  FIELD_TYPES,
  DATE_FIELDS,
  DEFAULT_RIGHT_PANEL_FIELDS,
  DATE_FIELD_NAME,
  DEFAULT_RECORDS_FIELDS,
  FORECASTING_EXPORT_COLUMNS,
  ID_POSTFIX,
  ELASTIC_SEARCH_PREFIX,
  NETWORK_ELEMENT_TREE_PATH,
  NOT_NETWORK_ELEMENT_FIELDS,
  WIDGET_TITLE,
  VALUE_FIELD,
  NETWORK_ELEMENT,
  FULL_TREE_PATH,
  ANALYSIS_TYPE,
  STEPS_FACTOR,
  WHOLE_INTEGERS_REGEX,
  AXISOPTIONS,
  ABSOLUTE_VALUE,
  PERCENTAGE_VALUE,
  NO_DATA_MESSAGE,
  TOO_MANY_SELECTIONS_MESSAGE,
  NO_CONTENT_STATUS_CODE,
  TOO_MANY_SELECTIONS_STATUS_CODE,
  UNPROCESSABLE_ENTITY_CODE,
  MAX_NUMBER_OF_SELECTIONS,
  NODE_TOOLTP_DEFAULT_SETTINGS,
  COUNT_MEMBERS_EXPORT_COLUMNS,
  AGGREGATE_FIELD_NAME,
  RECORDS_FOR_ATTRIBUTES,
  RECORDS,
  ATTRIBUTES,
  RESET_ZOOM_TOOLTIP,
  RESET_ZOOM_PAN_TOOLTIP,
  ADMIN_SETTINGS_TOOL_TIP,
  REPORTER_TOOLS_TOOL_TIP,
  CHART_TYPES_WITHOUT_QUICK_FILTERS,
  DEFAULT_THEME,
  NO_COLUMNS_MESSAGE,
  CANNOT_RETRIEVE_DATA,
  DEFAULT_N_VALUE,
  DEFAULT_N_DIRECTION,
  NETWORK_ELEMENT_ID,
  REF,
  TEMPLATE_PREFIX,
  DATE_FIELD_LABEL,
  WIDGET_OPTIONS,
  WIDGET_FORMAT,
  AVAILABLE,
  TABLE_FETCHED_COUNT,
};

export default defaults;
